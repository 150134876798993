import React, { FC, useState } from 'react'
import styled from 'styled-components'

const Input = styled.input.attrs({ type: 'text' })`
  background: rgba(236, 241, 244, 0.7);
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  color: #35414C;
  border: none;
  height: 38px;
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;

  &::placeholder {
    color: #8B949B;
  }
`

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 6px;
  max-height: 300px;
  overflow: scroll;
  margin-bottom: 10px;
`

const ListItem = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #35414C;
`

type FilterSelect = FC<{ addressList: string[], value: string, onChange: (val: string) => void }>

const FilterSelect: FilterSelect = ({
                                      value,
                                      addressList,
                                      onChange,
                                    }) => {
  const [selectVisible, setSelectVisible] = useState(false)
  const renderSelectContainer = () => {
    if (!selectVisible) return null
    return (
      <SelectContainer>
        {
          addressList
            .filter((el) => (
              el.toLowerCase().includes(value.toLowerCase())
            ))
            ?.map(el => (
              <ListItem key={el} onClick={() => {
                onChange(el)
              }}>
                {el}
              </ListItem>
            ))
        }
      </SelectContainer>
    )
  }
  return (
    <div>

      <Input
        placeholder={'Улица, дом'}
        value={value}
        onFocus={() => setSelectVisible(true)}
        onBlur={() => {
          setTimeout(() => {
            setSelectVisible(false)
          }, 200)
        }}
        onChange={(e) => (onChange(e.target.value))}
      />
      <div style={{ position: 'relative' }}>
        {renderSelectContainer()}
      </div>
    </div>
  )
}

export default FilterSelect