import React from 'react'

export const useMediaQuery = (query: string): boolean => {
  const [state, setState] = React.useState(
    () => window.matchMedia(query).matches,
  )
  React.useEffect(() => {
    const media = window.matchMedia(query)
    const handler = (event: MediaQueryListEvent) => setState(event.matches)

    media.addListener(handler)
    return () => media.removeListener(handler)
  }, [query])

  return state
}
