import fetch from 'node-fetch'
import qs from 'qs'


export const fetchGetOrders = (sortKey: string = '', page: number = 1, filters: object = {}) => {
  const query = {
    sortKey,
    page,
    ...filters,
    countOnPage: 10,
    sortDirection: 'asc',
  }
  const queryString = qs.stringify(query, { arrayFormat: 'brackets' })
  return fetch(`/api/v1/admin/order?${queryString}`)
    .then((res) => res.json())
}

export const fetchGetOrdersFilters = () =>
  fetch('/api/v1/admin/filters/order')
    .then((res) => res.json())