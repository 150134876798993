import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Category } from '../../../../modules/category'
import { TreeCategory } from './TreeCategory'
import { CurrentCatalogueItem } from '../../../../context/CategoryContext'

const Container = styled.div`

  cursor: pointer !important;
  margin-top: 30px;
  
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  width: 315px;
  height: auto;
  position: adaptive;
  top: 1.5em;
  gap: 10px;

  margin-bottom: 162px;
  @media screen and (max-width: 1139px) {
    display: none;
  }
`

type TreeTabT = React.FC<{
  subcategories: Category[]
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  setActiveCategoryId: Dispatch<SetStateAction<number>>
  currentCatalogue: CurrentCatalogueItem[]
  setCurrentCatalogue: Dispatch<SetStateAction<CurrentCatalogueItem[]>>
}>
export const TreeTab: TreeTabT = ({
  subcategories,
  setSelectedSubcategoriesIds,  
  setActiveCategoryId,
  currentCatalogue,
  setCurrentCatalogue
}) => {
  
  return (
    <Container>
      {
        subcategories?.map((item) => (
          <TreeCategory 
            key={item.id}
            subcategories={item.subcategories}
            setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
            setActiveCategoryId={setActiveCategoryId}
            title={item.title}
            categoryId={item.id}
            currentCatalogue={currentCatalogue}
            setCurrentCatalogue={setCurrentCatalogue}/>
      ))  }   
    </Container>
  )
}
