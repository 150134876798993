import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Arrow as ArrowBase, Direction } from '../images'
import { useOnClickOutside } from './useOnClickOutside'

interface LinkProps {
  isActive: boolean
}
type Prices = {
  max?: number
  min?: number
}

const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 410px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const SortByContainer = styled.div<LinkProps>`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  height: 39px;
  width: 88%;
  position: relative;
  margin-left: 20px;
  padding-left: 15px;
  max-width: 382px;
  background-color: white;
  margin-top: 8px;
  box-shadow: ${({ isActive }) =>
    isActive
      ? '0px 0px 5px rgba(10, 21, 128, 0.1)'
      : '0px 2px 5px rgba(10, 21, 128, 0.1)'};
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  z-index: 20;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
  @media screen and (min-width: 1440px) {
    margin-left: 30px;
    width: 81%;
  }
  @media screen and (min-width: 410px) and (max-width: 767px) {
    margin-left: 0;
    max-width: 370px;
  }
`

const DropDownContainer = styled.div<LinkProps>`
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  background-color: white;
  width: 100%;

  max-width: 382px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: 15px;
  padding-bottom: 10px;
  top: 100%;
  left: 0;
  z-index: 30;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);

  @media screen and (min-width: 410px) and (max-width: 767px) {
    max-width: 370px;
  }
`
const DropDownStart = styled.div`
  padding-top: 9px;

  width: 100%;
`

const Line = styled.div`
  width: 104%;
  margin-top: 10px;
  height: 1px;
  background-color: #f0f4f6;
`

const DropDownItem = styled.div`
  padding-top: 15px;
  z-index: 1;

  :first-child {
    padding-top: 0;
  }
`

const DropDownItemText = styled.a<LinkProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#8B949B' : '#35414C')};
  text-decoration: none;
  :last-child {
  }
`

const DropDownDefault = styled.div``

const Arrow = styled(ArrowBase)<LinkProps>`
  height: 12px;
  margin-right: 14px;
  width: 15px;
  height: 15px;
  margin-top: ${({ isActive }) => (isActive ? '11px' : '0')};
  transform: ${({ isActive }) => (isActive ? 'rotate(0)' : '')};
`

type Sorting = {
  sortKey: string
  sortDirection: string
}

type FilterDropDown = React.FC<{
  prices: Prices
  setSelectedSortingTypes: Dispatch<SetStateAction<Sorting>>
  selectedSortingTypes: Sorting
}>

const FilterDropDown: FilterDropDown = ({
  setSelectedSortingTypes,
  selectedSortingTypes,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [items] = React.useState([
    {
      key: 'default',
      title: 'По ревалентности',
      prefix: '',
      sortKey: '',
      sortDirection: '',
    },
    {
      key: 'priceAsc',
      title: 'По цене',
      prefix: 'возрастанию',
      sortKey: 'price',
      sortDirection: 'asc',
    },
    {
      key: 'priceDesc',
      title: 'По цене',
      prefix: 'убыванию',
      sortKey: 'price',
      sortDirection: 'desc',
    },
    {
      key: 'nameAsc',
      title: 'По имени',
      prefix: 'А-Я',
      sortKey: 'title',
      sortDirection: 'asc',
    },
    {
      key: 'nameDesc',
      title: 'По имени',
      prefix: 'Я-А',
      sortKey: 'title',
      sortDirection: 'desc',
    },
  ])

  const root = useOnClickOutside(() => setIsOpen(false))

  const selectItem = React.useCallback(
    (item) => { // ts-ignore
      setSelectedSortingTypes({
        sortDirection: item.sortDirection,
        sortKey: item.sortKey,
      })
    },
    [setSelectedSortingTypes],
  )

  const activeSorting = React.useMemo(() => {
    return (
      items.find(
        (x) =>
          x.sortKey === selectedSortingTypes.sortKey &&
          x.sortDirection === selectedSortingTypes.sortDirection,
      )?.title ?? ''
    )
  }, [selectedSortingTypes, items])

  const activePrefix = React.useMemo(() => {
    return (
      items.find(
        (x) =>
          x.sortKey === selectedSortingTypes.sortKey &&
          x.sortDirection === selectedSortingTypes.sortDirection,
      )?.prefix ?? ''
    )
  }, [selectedSortingTypes, items])

  const setIsActive = React.useCallback(
    (item) =>
      selectedSortingTypes.sortKey === item.sortKey &&
      item.sortDirection === selectedSortingTypes.sortDirection,
    [selectedSortingTypes],
  )

  return (
    <Container onClick={root}>
      <SortByContainer onClick={() => setIsOpen(!isOpen)} isActive={isOpen}>
        {isOpen ? (
          <DropDownStart>
            {activeSorting} {''} {activePrefix}
            <Line />
            <DropDownContainer isActive={isOpen}>
              {items?.map((item, i) => (
                <DropDownItem key={i}>
                  <DropDownItemText
                    isActive={setIsActive(item)}
                    onClick={() => selectItem(item)}
                  >
                    {item.title} {''}
                    {item.prefix}
                  </DropDownItemText>
                </DropDownItem>
              ))}
            </DropDownContainer>
          </DropDownStart>
        ) : (
          <DropDownDefault>
            {activeSorting} {''} {activePrefix}
          </DropDownDefault>
        )}
        <Arrow isActive={!!isOpen} direct={Direction.Right} />
      </SortByContainer>
    </Container>
  )
}

export default FilterDropDown
