export const formatCount = (price: number): string => {
  const normalizePrice = String(price)
  let i = normalizePrice.length,
    str = ''
  const step = 3
  while (i > 0) {
    const newStep = i - step
    str = normalizePrice.slice(newStep > 0 ? newStep : 0, i) + ' ' + str
    i = newStep
  }
  return str.slice(0, -1)
}
