import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router'
import { useAction, useAtom } from '@reatom/react'
import { BasketsPopUp } from '../../commons/BasketsPopUp'
import {
  deleteDeliveries as deleteDeliveriesBase,
  deliveries,
  patchDeliveries as patchDeliveriesBase,
  RequestsBasket,
  reorder,
} from '../../../modules/basket'
import { InputText, Button, Checkbox as CheckboxBase } from '../../commons'
import {
  btTablet,
  formatCount,
  ltDesktop,
  ltTablet,
  normalizePhone,
} from '../../../utils'
import { Item } from './Item'
import { RemoveItems as RemoveItemsBase } from './RemoveItems'
import { arrowLong, emptyProducts } from '../../images'
import { isAuthorization } from '../../../modules/authorization'
import { usePrepayment } from './Prepayment'
import { setPopUp, contract } from '../../../modules/basket/store'
import { ModalPopUp } from '../../commons/ModalPopUp'
import { useCityContext } from '../../../context/CityContext'
type Items = {
  items: Array<{}>
}

const Container = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  padding-bottom: 104px;
  display: flex;
  align-items: flex-start;
  ${ltDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
  ${ltTablet} {
    flex-direction: column;
    padding: 0;
  }
`

const Header = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  ${ltDesktop} {
    padding: 0 30px;
  }
  ${ltTablet} {
    padding: 0 15px;
  }
`

const HeaderWrapper = styled.div`
  padding: 15px 0;
  margin-bottom: 31px;
  ${ltTablet} {
    padding: 11px 0;
    margin-bottom: 10px;
  }

  background: #ffffff;
`

const Title = styled.div`
  margin-bottom: 35px;
  ${ltTablet} {
    margin-bottom: 9px;
    font-size: 16px;
  }

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 25px;
  color: #35414c;
`

const WrapperButtonsHeader = styled.div<Items>`
  display: ${({ items }) => (!items.length ? 'none' : 'flex')};
`

const TotalPrice = styled.div`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  color: #35414c;
`

const Checkbox = styled(CheckboxBase)`
  margin-right: 41px;
  height: 22px;
  ${btTablet} {
    margin-left: 16px;
  }
  ${ltTablet} {
    margin-right: 31px;
  }

  flex-shrink: 0;

  color: #35414c;
`

const RemoveItems = styled(RemoveItemsBase)`
  flex-shrink: 0;
`

const Wrapper = styled.div`
  width: 100%;
  ${btTablet} {
    flex: 2;
  }

  display: grid;
  grid-row-gap: 10px;
`

const WrapperTotal = styled.div<Items>`
  width: 100%;
  min-width: 283px;
  display: ${({ items }) => (!items.length ? 'none' : 'inline')};

  ${btTablet} {
    margin-left: 20px;
    flex: 1;
    border-radius: 6px;
    padding: 20px;
  }
  ${ltTablet} {
    margin-top: 10px;
    padding: 15px;
  }

  background: #ffffff;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
`

const RightWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 7px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const ButtonPay = styled(Button)`
  width: 100%;
  height: 36px;

  justify-content: space-between;
`

const Sum = styled.div`
  font-weight: 600;
  margin-right: 10px;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #495560;
  text-align: right;
`

const PrePaymentWarning = styled(Sum)`
  margin-right: 0;
`

const InputComment = styled(InputText)`
  width: 100%;
  margin-bottom: 16px;
`

const IconArrow = styled.img`
  height: 12px;
`

// const BasketModalContainer = styled.div`
//   position: relative;
// `

const EmptyProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  margin-left: 250px;
  width: 100%;
  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 911px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
  }
`
const EmptyProductsText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #495560;
  font-size: 20px;
  line-height: 110%;
  padding-top: 22px;
`

const EmptyProductsImage = styled.img.attrs({ src: emptyProducts })`
  height: 78px;
  width: 80px;
`
const Separator = styled.div`
  background: #f0f4f6;
  height: 1px;
  width: 100%;
  margin-bottom: 7px;
`

type Baskets = React.FC<RouteComponentProps>
export const Baskets: Baskets = () => {
  const setStatus = useAction(setPopUp)
  const status = useAtom(contract)
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const items = useAtom(deliveries)
  const reorderState = useAtom(reorder)
  const [showPopUp, setShowPopUp] = React.useState(false)

  const patchDeliveries = useAction(patchDeliveriesBase)
  const idsOnDelete = React.useMemo(
    () => items.filter(({ isSelect }) => isSelect)?.map(({ id }) => id),
    [items],
  )
  const deleteDeliveries = useAction(() => deleteDeliveriesBase(idsOnDelete), [
    idsOnDelete,
  ])
  const { prePayment, prePaymentFetching } = usePrepayment()

  const totalPrice = React.useMemo(
    () =>
      items
        .filter(({ isSelect }) => isSelect)
        .reduce(
          (totalPrice, { count, product: { price } }) =>
            totalPrice + price * count,
          0,
        ),
    [items],
  )

  // const popUpOnClick = () => {
  //   if (!status) {
  //     // @ts-ignore
  //     setStatus(true)
  //   }
  // }

  // @ts-ignore

  // React.useEffect(() => {
  //   items
  // })

  const totalPriceString = formatCount(totalPrice)

  const prePaymentWarning = !isAuthorization() && 'Будет рассчитана после входа'
  const prePaymentPrice =
    isAuthorization() &&
    // @ts-ignore
    `${formatCount(totalPrice * (prePayment || 0) * 0.01)}₽`

  const [comment, setComment] = React.useState('')
  const {selectedCity} = useCityContext()

  return (
    <>
      <HeaderWrapper>
        <Header>
          <Title>Корзина</Title>
          <WrapperButtonsHeader items={items}>
            <Checkbox
              checked={items.every(({ isSelect }) => isSelect)}
              onChange={(checked) => patchDeliveries({ isSelect: checked })}
            >
              Выбрать все ({items.length})
            </Checkbox>
            {idsOnDelete.length > 0 && (
              <RemoveItems ids={idsOnDelete}>Удалить выбранные</RemoveItems>
            )}
          </WrapperButtonsHeader>
        </Header>
      </HeaderWrapper>
      <Container>
        {showPopUp && (
          <BasketsPopUp setShowPopUp={setShowPopUp} showPopUp={showPopUp} />
        )}
        {!items.length && (
          <EmptyProducts>
            <EmptyProductsImage />
            <EmptyProductsText>Ваша корзина пуста</EmptyProductsText>
          </EmptyProducts>
        )}
        <Wrapper>
          {items?.map((item, index) => (
            // @ts-ignore
            <Item key={item.id} {...item} index={index} />
          ))}
        </Wrapper>
        <WrapperTotal items={items}>
          <RightWrapper>
            <Sum>Итого:</Sum>
            <TotalPrice>{totalPriceString}₽</TotalPrice>
          </RightWrapper>
          <Separator />
          <RightWrapper>
            <Sum>Предоплата{prePayment ? ` ${prePayment}%` : ''}:</Sum>
            {prePaymentWarning && (
              <PrePaymentWarning>{prePaymentWarning}</PrePaymentWarning>
            )}
            {!prePaymentWarning && (
              <TotalPrice>
                {prePaymentFetching ? 'Загрузка' : prePaymentPrice}
              </TotalPrice>
            )}
          </RightWrapper>
          <ButtonPay
            type="submit"
            disabled={items.every(
              ({ isSelect }) => isSelect === false || isSelect === undefined,
            )}
            onClick={() => {
              setIsOpen(true)
              // popUpOnClick()
              if (!isAuthorization()) {
                navigate?.(`${location?.pathname}/login`)
                return
              }

              // const a = { '8459': { newField: 'test', again: true } }

              RequestsBasket.fetchPostOrder({
                city:selectedCity,
                comment,
                orders: items
                  .filter(({ isSelect }) => isSelect)
                  ?.map(
                    ({
                      comment,
                      address,
                      count,
                      product: { id: productId },
                      date, // @ts-ignore
                      rangeParams,
                      recipientPhone,
                      recipientFIO,
                      orderType,
                    }) => ({
                      comment,
                      address,
                      count,
                      productId,
                      date,
                      rangeParams,
                      orderType,
                      recipientPhone: normalizePhone(recipientPhone),
                      recipientFIO,
                    }),
                  ),
              })
                .then(() => deleteDeliveries())
                .then(() => setShowPopUp(true))
            }}
          >
            Оформить
            <IconArrow src={arrowLong} />
          </ButtonPay>
        </WrapperTotal>
      </Container>
    </>
  )
}
