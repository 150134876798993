//@ts-nocheck
import { declareAction, declareAtom, combine } from '@reatom/core'
import { Dispatch, SetStateAction } from 'react'
import { Category as CategoryModel, Category } from '../category/store'

export interface Product {
  readonly id: string
  orderDescription: string
  title: string
  price: number
  image: string
  description: string
  measurement: string
  minCount: number
  count: number
  discount: number
  multiplicity?: number
  categoryTop: boolean
  subcategoryTop: boolean
  withVAT?: boolean
  standard?: string
  standardFile?: string
  isHitSale: boolean
}

export type Prices = {
  max?: number
  min?: number
}
export type CurrentCatalogueItem = {
  id: number
  title: string
  subIds: number[]
}

export type ProductsLogicTypesCut = {
  // products: Product[]
  cities: string[]
  selectedCity: string
  setSelectedCity: Dispatch<SetStateAction<string>>
  activeCategoryTitle: string
  subcategories: Category[]
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  selectedSubcategoriesIds: number[] | []
  subcategoriesIds: number[]
  isLoading: boolean
  categories: CategoryModel[]
  activeCategoryId: number
  setActiveCategoryId: Dispatch<SetStateAction<number>>
  currentCatalogue: CurrentCatalogueItem[]
  setCurrentCatalogue: Dispatch<SetStateAction<CurrentCatalogueItem[]>>
  loadingCounter: React.MutableRefObject<number>
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export type ProductsLogicTypes = {
  products: Product[]
  cities: string[]
  selectedCity: string
  setSelectedCity: Dispatch<SetStateAction<string>>
  activeCategoryTitle: string
  subcategories: Category[]
  prices: Prices
  offset: number
  limit: number
  total: number
  setOffset: Dispatch<SetStateAction<number>>
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  selectedSubcategoriesIds: number[] | []
  priceInputValue: Prices
  setMaxFilterPrice: Dispatch<SetStateAction<string>>
  setMinFilterPrice: Dispatch<SetStateAction<string>>
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  setSelectedSortingTypes: Dispatch<SetStateAction<Test>>
  isTableView: boolean
  setRowViewCallback: () => void
  setTableViewCallback: () => void
  setLimit: Dispatch<SetStateAction<number>>
  selectedSortingTypes: Test
  subcategoriesIds: number[]
  isLoading: boolean
  categories: CategoryModel[]
  activeCategoryId: number
  setActiveCategoryId: Dispatch<SetStateAction<number>>
  currentCatalogue: CurrentCatalogueItem[]
  setCurrentCatalogue: Dispatch<SetStateAction<CurrentCatalogueItem[]>>
  loadingCounter: React.MutableRefObject<number>
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export type RangeInputTypes = {
  priceInputValue: Prices
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  prices: Prices
  localInputValueMin: number
  localInputValueMax: number
  setMaxFilterPrice: Dispatch<SetStateAction<string>>
  setMinFilterPrice: Dispatch<SetStateAction<string>>
  setLocalInputValueMin: Dispatch<SetStateAction<number>>
  setLocalInputValueMax: Dispatch<SetStateAction<number>>
}

export type DropFiltersButtonTypes = {
  setLocalInputValueMin: Dispatch<SetStateAction<number>>
  setLocalInputValueMax: Dispatch<SetStateAction<number>>
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  setSelectedSortingTypes: Dispatch<SetStateAction<Test>>
  setLimit: Dispatch<SetStateAction<number>>
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  prices: Prices
}

export const setIsLoadingSuccess = declareAction('setIsLoadingSuccess')
export const setIsLoadingRequest = declareAction('setIsLoadingRequest')
export const setIsLoadingFailure = declareAction('setIsLoadingFailure')

export const isLoadingAtom = declareAtom('isLoading', false, (on) => [
  on(setIsLoadingSuccess, () => false),
  on(setIsLoadingRequest, () => true),
  on(setIsLoadingFailure, () => false),
])

export const product = combine({ isLoading: isLoadingAtom })
