import React from 'react'
import { Catalogue } from './Catalogue'
import { Product as ProductInterface,
  ProductRequestes,
  Prices, 
  ProductsLogicTypesCut} from '../../../modules/product'
import { getProducts } from '../../../modules/ssd'

type ProductsT = React.FC<ProductsLogicTypesCut>

export const Products: ProductsT = (props) => {
  const {subcategories, activeCategoryId, selectedSubcategoriesIds,    
    cities,
    selectedCity,
    setSelectedCity, 
    setCurrentCatalogue,
    loadingCounter,
    isLoading,
    setIsLoading,
  } = props;
  
  const [products, setProducts] = React.useState<ProductInterface[]>(
    getProducts()?.products ?? [],
  )
  
  const [offset, setOffset] = React.useState(0)
  const [limit, setLimit] = React.useState(32)
  const [total, setTotal] = React.useState(getProducts()?.total ?? 0)

  const [prices, setPrices] = React.useState<Prices>({ min: 1, max: 1000000, })
  const [priceInputValue, setPriceInputValue] = React.useState<Prices>({
    max: 1000000,
    min: 1,
  })
  const [selectedSortingTypes, setSelectedSortingTypes] = React.useState({
    sortKey: '',
    sortDirection: '',
  })

  const [isTableView, setTableView] = React.useState<boolean>(true)

  const setTableViewCallback = React.useCallback(() => {
    setTableView(true)
  }, [])

  const setRowViewCallback = React.useCallback(() => {
    setTableView(false)
  }, [])

  const setMaxFilterPrice = React.useCallback(
    (value: any) => {
      if (value !== '') {
        setPriceInputValue({ max: value, min: priceInputValue.min })
      }
    },

    [priceInputValue],
  )

  const setMinFilterPrice = React.useCallback(
    (value: any) => {
      if (value !== '') {
        setPriceInputValue({ min: value, max: priceInputValue.max })
      }
    },

    [priceInputValue],
  )
  React.useEffect(() => {
    if(subcategories.length) {
      return;
    }

    if (priceInputValue.max) {
      loadingCounter.current++
      if(!isLoading) setIsLoading(true)
      ProductRequestes.fetchGetProducts(
        {
          offset,
          limit,
          // @ts-ignore
          prices: priceInputValue,
          city: selectedCity,
          categoryIds: [activeCategoryId],
          sortKey: selectedSortingTypes.sortKey,
          sortDirection: selectedSortingTypes.sortDirection,
        },
      ) // @ts-ignore

        .then(({ total, products }) => {
          
          setLoadingCounter(loadingCounter.current-1)
          setTotal(total)
          setProducts(products)
        }) // @ts-ignore
        .catch((e) => {
          setLoadingCounter(loadingCounter.current-1)
        })
    }
  }, [
    offset,
    priceInputValue,
    limit,
    activeCategoryId,
    selectedSortingTypes,
    selectedCity
  ])
  
  const setLoadingCounter = React.useCallback(
    (current: number) => {
      loadingCounter.current=current;
      if(loadingCounter.current <= 0) { setIsLoading(false) }
    },
    [loadingCounter],
  )
  
  React.useEffect(() => { 
    if(activeCategoryId && !subcategories.length) {  
      loadingCounter.current++     
      if(!isLoading) setIsLoading(true)
      ProductRequestes.fetchGetPriceRange([activeCategoryId]).then((data) => {
        setLoadingCounter(loadingCounter.current-1)
        const min = Number(data.min);
        let max = Number(data.max);
        if (min === max) max = min+1;
        if (min != priceInputValue.min || max != priceInputValue.max) {
          setPriceInputValue({ max, min})
          setPrices({ max, min })
        }
      }).catch(()=>{
        setLoadingCounter(loadingCounter.current-1)
      })
    }
  }, [activeCategoryId])

  React.useEffect(() => {
    setOffset(0)
  }, [limit, activeCategoryId, selectedSubcategoriesIds])
  const uberProps = {...props, 
    cities,
    selectedCity,
    setSelectedCity,
    prices,
    priceInputValue,
    setMaxFilterPrice,
    setMinFilterPrice,
    setPriceInputValue,
    setSelectedSortingTypes,
    selectedSortingTypes,
    isTableView,
    setTableViewCallback,
    setRowViewCallback,
    setLimit,
    limit,
    setOffset,
    offset,
    total,
    products,
    setCurrentCatalogue
  }
  return (
    <Catalogue {...uberProps} />
  )
}
