import React from 'react'
import styled from 'styled-components'

import { tick } from '../images'
import { ltTablet } from '../../utils'

const Icon = styled.img`
  width: 10px;
  height: 10px;
  // TODO: fix in svg
  margin-top: 1px;

  visibility: hidden;

  user-select: none;
`

const CheckboxStyle = styled.div<{ indent: boolean }>`
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  background-color: white;
  border: 1.5px solid #f0640c;
  border-radius: 3px;

  ${({ indent }) => (indent ? 'margin-right: 10px;' : '')}
`

const CheckboxInput = styled.input`
  display: none;
  &:checked + ${CheckboxStyle} ${Icon} {
    visibility: initial;
  }
`

const CheckboxLabel = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  font-family: 'Open Sans';
  font-size: 14px;
  color: #495560;
  ${ltTablet} {
    font-size: 12px;
  }
`

type Checkbox = React.FC<{
  checked: boolean
  onChange: (checked: boolean) => void
  className?: string
  children?: any
}>
export const Checkbox: Checkbox = ({
  checked,
  onChange,
  className,
  children,
}) => {
  return (
    <CheckboxLabel className={className}>
      <CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
      />
      <CheckboxStyle indent={Boolean(children)}>
        <Icon src={tick} alt="tick" />
      </CheckboxStyle>
      {children}
    </CheckboxLabel>
  )
}
