import React from 'react'
import styled from 'styled-components'
import { arrowLeft } from '../../../images'
import { CurrentCatalogueItem } from '../../../../modules/product'

const ArrowLeft = styled.img.attrs({ src: arrowLeft })`
  width: 5px;
  height: 10px;
  margin: 0px 14px 0px 14px;
`
const Title = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8B949B;
  cursor: pointer !important;
  white-space: nowrap;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`


export const HeaderCategoriesItem: React.FC<{
  item: CurrentCatalogueItem
  selectCategoryHandler: (id: number) => void
}> = ({ item, selectCategoryHandler, ...props }) => {
  const id = item.id
  const title = item.title
  const renderCategories = () => {
    return (
      <Container>
        <Wrapper>
          {item.id != 0 && <ArrowLeft />}
          <Title onClick={() => selectCategoryHandler(id)}>{title}</Title>
        </Wrapper>
      </Container>

    )
  }

  return renderCategories()
}
