import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 15px;
  @media screen and (min-width: 400px) {
    margin-left: 20px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MeasurmentContainer = styled.div`
  position: relative;
`

const InputMax = styled.input`
  padding: 9px 14px;
  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }
  &:focus {
    border-color: #f49355;
  }
  transition: 225ms;
  min-width: 152px;
  outline: none;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 16px;
  color: #8b949b;

  border: 1px solid transparent;
  background: #ecf1f4;
  border-radius: 6px;
  @media screen and (min-width: 400px) {
    min-width: 225px;
  }
`

const AddCount = styled.div`
  min-width: 30px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  min-width: 59px;

  @media screen and (min-width: 400px) {
    width: 59px;
    height: 38px;
    margin-right: 10px;
  }
`

const AddCountValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  /* identical to box height, or 19px */
  color: rgba(240, 100, 12, 0.7);
  text-align: center;
  padding-top: 3px;
`

const InputLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  padding-bottom: 10px;
  color: #495560;
`

const Measurement = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  color: #8b949b;

  transform: translateY(-50%);
`

export const InputMaxQuanity = ({
  product,
  setMaxPerDay,
  commonCount,
  minPerDay,
}) => {
  const [maxInputValue, setMaxInputValue] = React.useState(
    Number(product?.multiplicity),
  )
  const [counter, setCounter] = React.useState(1)

  const addCounter = () => {
    setCounter(counter + 1)
  }

  React.useEffect(() => {
    setMaxPerDay(maxInputValue)
  }, [maxInputValue])

  //@ts-ignore
  const addValue = (value) => {
    if (value < commonCount) {
      setMaxInputValue(Number(value) + Number(product?.multiplicity))
    }
  }
  //@ts-ignore
  const minusValue = (value) => {
    if (value > product?.multiplicity && value > minPerDay) {
      setMaxInputValue(Number(value) - Number(product?.multiplicity))
    }
    if (value == product?.multiplicity) {
      setMaxInputValue(Number(product?.multiplicity))
    }
  }

  React.useEffect(() => {
    if (maxInputValue % Number(product?.multiplicity) !== 0) {
      if (maxInputValue > Number(product?.multiplicity)) {
        const mult = parseInt(maxInputValue / Number(product?.multiplicity))
        const left = maxInputValue % Number(product?.multiplicity)

        if (left < Number(product?.multiplicity) / 2) {
          return setMaxInputValue(Number(product?.multiplicity) * mult)
        } else if (left >= Number(product?.multiplicity) / 2) {
          return setMaxInputValue(Number(product?.multiplicity) * (mult + 1))
        }
      }
      if (maxInputValue < Number(product?.multiplicity)) {
        return setMaxInputValue(0 + Number(product?.multiplicity))
      }
    } else if (maxInputValue == 0) {
      return setMaxInputValue(0 + Number(product?.multiplicity))
    }
  }, [counter])

  React.useEffect(() => {
    if (commonCount) {
      if (
        Number(maxInputValue) > Number(commonCount) &&
        Number(commonCount) > product?.multiplicity
      ) {
        setMaxInputValue(Number(commonCount) - product?.multiplicity)
      }
    }
  }, [maxInputValue, commonCount])

  return (
    <Container>
      <InputLabel>Максимальный объем в день</InputLabel>

      <InputContainer>
        <MeasurmentContainer>
          <InputMax
            value={maxInputValue}
            onChange={(event) => setMaxInputValue(event.target.value)}
            onBlur={() => addCounter()}
          />
          <Measurement>{product?.measurement}</Measurement>
        </MeasurmentContainer>

        <AddCount onClick={() => minusValue(maxInputValue)}>
          <AddCountValue>-{Number(product?.multiplicity)}</AddCountValue>
        </AddCount>
        <AddCount onClick={() => addValue(maxInputValue)}>
          <AddCountValue>+{Number(product?.multiplicity)}</AddCountValue>
        </AddCount>
      </InputContainer>
    </Container>
  )
}
