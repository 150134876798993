import React from 'react'

export const withFullHeight = () => (BaseComponent: React.FC) =>
  React.forwardRef((props, topRef) => {
    const ref = React.useRef<HTMLElement>()
    React.useLayoutEffect(() => {
      const handlerResize = () => {
        ref.current?.style.setProperty('height', `${window.innerHeight}px`)
      }
      handlerResize()
      window.addEventListener('resize', handlerResize)
      return () => window.removeEventListener('resize', handlerResize)
    }, [])

    return (
      <BaseComponent
        //@ts-ignore
        ref={(refX) => {
          ref.current = refX
          if (topRef) {
            //@ts-ignore
            topRef.current = refX
          }
        }}
        {...props}
      />
    )
  })
