import styled from 'styled-components'

type Item = { width?: number; active?: boolean }
export const Item = styled.div<Item>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ active }) => (active ? '36px' : '30px')};
  height: ${({ active }) => (active ? '36px' : '30px')};
  margin-right: 8px;
  border-radius: 6px;
  box-shadow: ${({ active }) =>
    active ? '0px 6px 14px rgba(10, 21, 128, 0.1)' : 'none'};
  font-size: 14px;
  line-height: 19px;
  color: ${({ active }) => (active ? '#F0640C' : '#35414C')};
  background-color: ${({ active }) => (active ? 'white' : 'none')};

  &:last-child {
    margin-right: 0;
  }
  svg {
    fill: black;
  }
  :hover {
    transition: 225ms;
    color: #f0640c;
    background-color: white;
    /* border: 1px solid rgb(149, 52, 245); */
    svg {
      fill: rgb(149, 52, 245);
    }
  }

  cursor: pointer;
  user-select: none;
`

/* border: 1px solid
    ${({ active }) =>
      active ? 'rgb(149, 52, 245)' : 'rgb(187, 192, 210);'}; */
