import React from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../commons'
import { Link } from '@reach/router'
import { logOut, useIsAuthorization } from '../../modules/authorization'
import { useAction } from '@reatom/react'
import { deleteAllDeliveries } from '../../modules/basket/store'

import {
  headerDrawer,
  callImage,
  androidDrawer,
  appleDrawer,
  closeMenu,
} from '../images'

const DrawerContainer = styled.div<IsOpen>`
  margin-left: 20px;
  cursor: pointer;
  display: ${({ isOpen }) => (isOpen ? 'none' : 'inline')};
  @media screen and (min-width: 620px) {
    display: none;
  }
`

type IsOpen = {
  isOpen: boolean
}

const DropDownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  

  background-color: white;
  left: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  top: 44px;
  z-index: 10000;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 2px;
`

const DropDownLinkToCatalogue = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`

const DropDownTitle = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #35414c;
  text-decoration: none;
`

const InfoTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
`

const DropDownTitleExit = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  padding-left: 20px;
  padding-top: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #35414c;
`

const ImageNumberContainer = styled.div`
  flex-direction: row;
  display: flex;
  max-width: 200px;
`

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MobileNumber = styled.a`
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  font-weight: 600;
  color: black;
  width: 150px;
`
const Time = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 15px;
  margin-top: auto;
  gap: 4px;
`

const DarkTheme = styled.div<IsOpen>`
  background-color: #35414c;
  z-index: 5;
  position: fixed;
  size: fill;
  height: 100%;
  width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  opacity: 0.7;
  margin-left: -20px;
  top: 45px;
  @media screen and (min-width: 620px) {
    display: none;
  }
`

const AppContainer = styled.div``

const AppleLogoLink = styled.a``

const PlaymarketLogoLink = styled.a``

const CallImage = styled.img.attrs({ src: callImage })`
  width: 20px;
  height: 18px;
  margin-right: 10px;
  margin-top: 3px;
`

const HeaderDrawerImage = styled.img.attrs({ src: headerDrawer })``

const PlaymarketLogo = styled.img.attrs({ src: androidDrawer })`
  width: 24px;
  height: 29px;
  margin-right: 20px;
`

const AppleLogo = styled.img.attrs({ src: appleDrawer })`
  width: 25px;
  height: 32px;
`
const CloseMenu = styled.img.attrs({ src: closeMenu })`
  display: flex;
  height: 100%;
  width: 14px;
  &:after {
    content: '';
    width: 14px;
    height: 2px;
    margin: auto;

    display: block;

    border-radius: 2px;
    background-color: #35414c;
    box-shadow: 0 6px 0 0 black, 0 -6px 0 0 #35414c;
  }
`

type Zaebalo = React.FC<{ mobileInput: boolean }>

const HeaderDrawer: Zaebalo = ({ mobileInput }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const root = useOnClickOutside(() => setIsOpen(false))
  const isAuth = useIsAuthorization()
  const deleteDeliveries = useAction(deleteAllDeliveries)

  const logoutUser = React.useCallback(() => {
    setIsOpen(false)
    logOut()
    deleteDeliveries()
  }, [])

  React.useEffect(() => {
    if (mobileInput) {
      setIsOpen(false)
    }
  }, [])

  return (
    <DrawerContainer onClick={root} isOpen={mobileInput}>
      {isOpen ? (
        <CloseMenu onClick={() => setIsOpen(false)} />
      ) : (
        <HeaderDrawerImage onClick={() => setIsOpen(true)} />
      )}

      {isOpen && (
        <DropDownContainer>
          {isAuth && (
            <div>
              <DropDownTitle to={`/profile`} onClick={() => setIsOpen(false)}>
                Профиль
              </DropDownTitle>
              <DropDownTitleExit onClick={logoutUser}>Выйти</DropDownTitleExit>
            </div>
          )}
          <LinkContainer>
            <InfoTitle>Горячая линия</InfoTitle>
            <ImageNumberContainer>
              <CallImage />
              <NumberContainer>
                <MobileNumber href="tel:8-918-070-38-36">
                  +7 (918) 070-38-36
                </MobileNumber>
                <Time>8:00-22:00 MSK</Time>
              </NumberContainer>
            </ImageNumberContainer>
            <InfoTitle>Отдел ЖБИ</InfoTitle>
            <ImageNumberContainer>
              <CallImage />
              <NumberContainer>
                <MobileNumber href="tel:8-962-877-77-20">
                  +7 (962) 877-77-20
                </MobileNumber>
                <Time>8:00-22:00 MSK</Time>
              </NumberContainer>
            </ImageNumberContainer>
            <AppContainer>
              <PlaymarketLogoLink href="https://play.google.com/store/apps/details?id=com.codemonx.prostore&hl=ru">
                <PlaymarketLogo />
              </PlaymarketLogoLink>
              <AppleLogoLink href="https://apps.apple.com/ru/app/prostore/id1465747466">
                <AppleLogo />
              </AppleLogoLink>
            </AppContainer>
          </LinkContainer>
        </DropDownContainer>
      )}
      <DarkTheme isOpen={isOpen} onClick={() => setIsOpen(false)}></DarkTheme>
    </DrawerContainer>
  )
}

export default HeaderDrawer
