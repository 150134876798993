//@ts-nocheck

import React from 'react'
import styled from 'styled-components'
import { checkboxIconRound } from '../../../images'

interface LinkProps {
  isActive: boolean
}

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Heading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  padding-top: 30px;
  color: #35414c;
`

const DeliveryTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1439px) {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
`

const DeliveryTypeBlock = styled.div<LinkProps>`
  width: 290px;

  height: 85px;
  border: ${({ isActive }) =>
    isActive ? '1px solid rgba(240, 100, 12, 0.7)' : '1.5px solid #CED3D6'};
  border-radius: 6px;
  margin-top: 15px;
  cursor: pointer;
  @media screen and (min-width: 400px) {
    width: 375px;
  }
  @media screen and (min-width: 1439px) {
    margin-top: 0;
    width: 388px;
  }
`

const DeliveryTypeBlockRange = styled.div<LinkProps>`
  width: 290px;
  height: 85px;
  border: ${({ isActive }) =>
    isActive ? '1px solid rgba(240, 100, 12, 0.7)' : '1.5px solid #CED3D6'};
  border-radius: 6px;
  margin-top: 15px;
  cursor: pointer;
  @media screen and (min-width: 400px) {
    width: 375px;
  }
  @media screen and (min-width: 1439px) {
    margin-top: 0;

    margin-left: 20px;
    width: 388px;
  }
`

const DeliveryTypeHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`

const DeliveryTypeHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  color: #35414c;
  padding-left: 10px;
`

const StyledCheckbox = styled.div<LinkProps>`
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: ${({ isActive }) =>
    isActive ? '1.5px solid #f0640c' : '1.5px solid #8B949B'};
  background: white;
  background-image: ${({ isActive }) =>
    isActive ? `url(${checkboxIconRound})` : 'none'};
  background-position: 35% 35%;
  background-size: 80% 80%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
`
const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 10px;
  margin: -1px;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 10px;
  cursor: pointer;
`

const DeliveryTypeText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #495560;
  padding-top: 5px;
  padding-left: 40px;
`

type DeliveryTypeT = React.FC<{
  activeDeliveryOnce: boolean,
  setActiveDeliveryOnce: React.Dispatch<React.SetStateAction<boolean>>,
  setActiveDeliveryRange: React.Dispatch<React.SetStateAction<boolean>>,
  activeDeliveryRange: boolean,
}>
export const DeliveryType: DeliveryTypeT = ({
  activeDeliveryOnce,
  setActiveDeliveryOnce,
  setActiveDeliveryRange,
  activeDeliveryRange,
}) => {
  return (
      <ColumnContainer>
        <Heading>Выберите тип доставки</Heading>
        <DeliveryTypeContainer>
          <DeliveryTypeBlock
            isActive={activeDeliveryOnce}
            onClick={() => {
              setActiveDeliveryOnce(true)
              setActiveDeliveryRange(false)
            }}
          >
            <DeliveryTypeHeadingContainer>
              <StyledCheckbox isActive={activeDeliveryOnce}>
                <CheckBox />
              </StyledCheckbox>
              <DeliveryTypeHeading>Единоразовая доставка</DeliveryTypeHeading>
            </DeliveryTypeHeadingContainer>
            <DeliveryTypeText>
              Доставка товара в указанный день
            </DeliveryTypeText>
          </DeliveryTypeBlock>
          <DeliveryTypeBlockRange
            isActive={activeDeliveryRange}
            onClick={() => {
              setActiveDeliveryRange(true)
              setActiveDeliveryOnce(false)
            }}
          >
            <DeliveryTypeHeadingContainer>
              <StyledCheckbox isActive={activeDeliveryRange}>
                <CheckBox />
              </StyledCheckbox>
              <DeliveryTypeHeading>Доставка в диапазоне</DeliveryTypeHeading>
            </DeliveryTypeHeadingContainer>
            <DeliveryTypeText>
              Ежедневная доставка товара в указанный промежуток времени
            </DeliveryTypeText>
          </DeliveryTypeBlockRange>
        </DeliveryTypeContainer>
      </ColumnContainer>
  )
}