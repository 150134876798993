//@ts-nocheck
import { declareAction, declareAtom, combine, map } from '@reatom/core'

import { Product } from '../product'
import { Address } from '../cartography'

export interface Delivery {
  id: string
  count: number
  comment?: string
  date: Date
  recipientPhone: string
  recipientFIO?: string
  address: Address
  isSelect?: boolean
  index?: number
  orderType?: string
}

export interface Deliveries extends Delivery {
  product: Product
}

export interface Basket {
  deliveries: Delivery[]
  product: Product
}

// export interface GlobalCategory {
//   id: number
// }

export interface MainPageSubcategory {
  id: number
}

export const postBasket = declareAction<Basket>('postBasket')
export const putBasketByProductId = declareAction<Basket>(
  'putBasketByProductId',
)
export const patchDelivery = declareAction<Partial<Delivery>>('patchDelivery')
export const patchDeliveries = declareAction<Partial<Delivery>>(
  'patchDeliveries',
)
export const deleteDeliveries = declareAction<Array<Delivery['id']>>(
  'deleteDeliveries',
)
export const deleteAllDeliveries = declareAction('deleteAllDeliveries')

export const selectReorder = declareAction<Partial<Basket>>('selectReorder')

export const removeReorder = declareAction('removeReorder')

// export const selectGlobalCategory = declareAction<GlobalCategory['id']>(
//   'selectGlobalCategory',
// )

export const selectMainPageSubcategory = declareAction<
  MainPageSubcategory['id']
>('selectMainPageSubcategory')

export const setSignedContract = declareAction('setSignedContract')

export const setPopUp = declareAction('setPopUp')

export const items = declareAtom<Basket[]>('basket', [], (on) => [
  on(postBasket, (items, newItem) => [...items, newItem]),
  on(putBasketByProductId, (items, newItem) =>
    items.map((item) =>
      item.product.id === newItem.product.id ? newItem : item,
    ),
  ),
  on(deleteDeliveries, (items, ids) =>
    items
      .map((item) => ({
        ...item,
        deliveries: item.deliveries.filter(({ id }) => !ids.includes(id)),
      }))
      .filter(({ deliveries }) => deliveries.length !== 0),
  ),
  on(deleteAllDeliveries, () => []),
  on(patchDelivery, (items, newDelivery) =>
    items.map(({ deliveries, ...item }) => ({
      deliveries: deliveries.map((delivery) =>
        delivery.id === newDelivery.id
          ? { ...delivery, ...newDelivery }
          : delivery,
      ),
      ...item,
    })),
  ),
  on(patchDeliveries, (items, newDelivery) =>
    items.map(({ deliveries, ...item }) => ({
      deliveries: deliveries.map((delivery) => ({
        ...delivery,
        ...newDelivery,
      })),
      ...item,
    })),
  ),
])

export const deliveries = map<Deliveries[], Basket[]>(
  'deliveries',
  items,
  (items) =>
    items.flatMap(({ product, deliveries }) =>
      deliveries.map((delivery) => ({ ...delivery, product })),
    ),
)

export const getBasketByProductId = (id: Product['id']) => (
  items: Basket[],
): Basket | undefined => items.find(({ product }) => product.id === id)

export const getDeliveriesByIds = (ids: Array<Delivery['id']>) => (
  items: Deliveries[],
): Deliveries[] => items.filter(({ id }) => ids.includes(id))

export type FavoriteAddresses = Address[]

export const addFavoriteAddresses = declareAction<FavoriteAddresses>(
  'addFavoriteAddresses',
)

export const favoriteAddresses = declareAtom<FavoriteAddresses>(
  'favoriteAddresses',
  [],
  (on) => [
    on(addFavoriteAddresses, (items, newItem) => [
      ...newItem,
      ...items.filter(
        ({ lat, lng }) =>
          !newItem.some(({ lat: x, lng: y }) => lat === x && lng === y),
      ),
    ]),
  ],
)

export const reorder = declareAtom('reorder', {}, (on) => [
  on(selectReorder, (_, payload) => payload),
  on(removeReorder, () => ({})),
])

// export const globalCategory = declareAtom<GlobalCategory>(
//   'globalCategory',
//   [],
//   (on) => [on(selectGlobalCategory, (_, payload) => payload)],
// )

export const mainPageSubcategory = declareAtom<MainPageSubcategory>(
  'mainPageSubcategory',
  [],
  (on) => [on(selectMainPageSubcategory, (_, payload) => payload)],
)

export const contract = declareAtom<ContractStatus>('contract', false, (on) => [
  on(setSignedContract, (_, payload) => payload),
])

export const popUp = declareAtom<ContractStatus>('popUp', false, (on) => [
  on(setPopUp, (_, payload) => payload),
])

export const basket = combine({
  items,
  reorder,
  //globalCategory,
  favoriteAddresses,
  contract,
  popUp,
})
