import React from 'react'
import Headroom from 'react-headroom'
import { Categories } from './Categories'
import { HeaderCategories } from './Categories/HeaderCategories'
import { RouteComponentProps } from '@reach/router'
import { CurrentCatalogueItem, } from '../../../modules/product'
import { useAtom } from '@reatom/react'
import { Category } from '../../../modules/category'
import { Products } from './Products'
import { useCityContext } from '../../../context/CityContext'
import { useCategoryContext } from '../../../context/CategoryContext'

type CatalogueT = React.FC<RouteComponentProps>

export const Catalogue: CatalogueT = () => {
  
  const { categories, setCategories, activeCategoryId, setActiveCategoryId, currentCatalogue, setCurrentCatalogue } = useCategoryContext()
  
  const [
    selectedSubcategoriesIds,
    setSelectedSubcategoriesIds,
  ] = React.useState<number[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingCounter = React.useRef(0);  
  const {cities, selectedCity, setSelectedCity} = useCityContext()
  
  const categoriesIndex = React.useMemo(
    () => {
      const categoriesIndex: Record<number, Category> = {}
      const addToIndex = (cat: Category) => {        
        categoriesIndex[cat.id] = cat;
        for (const sub of cat.subcategories) {
          categoriesIndex[sub.id] = sub;
          addToIndex(sub);
        }
      }
      for (const cat of categories) {
        addToIndex(cat)
      }
      return categoriesIndex;
    }, [categories]
  )

  const subcategories = React.useMemo(
    () => (activeCategoryId? categoriesIndex[activeCategoryId]?.subcategories ?? [] : categories),
    [categoriesIndex, activeCategoryId],
  )

  const subcategoriesIds = React.useMemo(
    () => subcategories.length ? subcategories.map((v) => v.id) : [activeCategoryId],
    [subcategories],
  )

  const activeCategoryTitle = React.useMemo(
    () => activeCategoryId? categoriesIndex[activeCategoryId]?.title ?? '' : '',
    [categories, activeCategoryId],
  )

  React.useEffect(() => {
    if(loadingCounter.current <= 0 && isLoading) {
      console.log('reset to false');
      setIsLoading(false)
      loadingCounter.current = 0
    }    
    if(loadingCounter.current > 0 && !isLoading) {
      setIsLoading(true)
    }
  }, [loadingCounter, isLoading])

  React.useEffect(() => {    
    if(!activeCategoryId) {
      if(currentCatalogue.length) { setCurrentCatalogue([]); }
      return;
    }
    if(currentCatalogue.length) {      
      const last = currentCatalogue[currentCatalogue.length - 1];
      if (last.id === activeCategoryId)    {
        const newcat = [...currentCatalogue];
        newcat[currentCatalogue.length - 1].subIds = subcategoriesIds;
        newcat[currentCatalogue.length - 1].title = activeCategoryTitle;
        setCurrentCatalogue(newcat);
      }  else {
        if (last.subIds.find((v) => v === activeCategoryId)) {
          const newcat = [...currentCatalogue, {id: activeCategoryId, title: activeCategoryTitle, subIds: subcategoriesIds}];
          setCurrentCatalogue(newcat);
        } else {
          setCurrentCatalogue([{id: activeCategoryId, title: activeCategoryTitle, subIds: subcategoriesIds}])
        }
      }
    }
    else {
      setCurrentCatalogue([{id: activeCategoryId, title: activeCategoryTitle, subIds: subcategoriesIds}])
    }    
  }, [activeCategoryId, subcategoriesIds, activeCategoryTitle])

  const props = {    
    setSelectedSubcategoriesIds,
    activeCategoryId,
    setActiveCategoryId,
    categories,
    selectedSubcategoriesIds,
    activeCategoryTitle,
    subcategories,
    subcategoriesIds,
    isLoading,
    currentCatalogue,
    cities,
    selectedCity,
    setSelectedCity,
    setCurrentCatalogue,
    loadingCounter,
    setIsLoading,
  }
  
   return (
    <div>
      <Headroom>
        <Categories {...props} />
        <HeaderCategories {...props} />
      </Headroom>
      <Products {...props}
      />
    </div>
  )
}
