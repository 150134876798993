import React from 'react'
import styled from 'styled-components'
import { callImage } from '../images'
import { CopyEmailToClipBoard } from '../../utils/copyNumber'


const CallImage = styled.img.attrs({ src: callImage })`
  width: 20px;
  height: 18px;
  margin-top: 3px;
`

const TextContainer = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #35414c;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 2001;
  background: #FFFFFF;
  border-radius: 6px;
  padding:  20px 0;
  width: 205px;
  color: #495560;
  top: 38px;
  right: 0;
`

const InfoTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
  padding: 0 20px;
`

const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  line-height: 20px;
  color: inherit;
  margin-bottom: 4px;
  padding: 0 15px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`

const InfoContainer = styled.div`
  &:first-child {
    margin-bottom: 30px;
  }
`

const ContactsButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const containerRef = React.useRef(null)

  const handleClickOutside = (e: Event) => {
    //@ts-ignore
    if (containerRef && containerRef.current && !containerRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [containerRef])

  return (
    <Container onClick={() => setIsOpen(!isOpen)} ref={containerRef}>
      <CallImage />
      <TextContainer>Контакты</TextContainer>
      {isOpen &&
      <InfoWrapper>
        <InfoContainer>
          <InfoTitle>Горячая линия</InfoTitle>
          <StyledLink href='tel:8-918-070-38-36'>+7 (918) 070-38-36</StyledLink>
          <StyledLink onClick={() => CopyEmailToClipBoard('ProStore123@yandex.ru')}>ProStore123@yandex.ru</StyledLink>
        </InfoContainer>
        <InfoContainer>
          <InfoTitle>Отдел ЖБИ</InfoTitle>
          <StyledLink href='tel:8-962-877-77-20'>+7 (962) 877-77-20</StyledLink>
          <StyledLink onClick={() => CopyEmailToClipBoard('ProStoreGBK@yandex.ru')}>ProStoreGBK@yandex.ru</StyledLink>
        </InfoContainer>
      </InfoWrapper>
      }
    </Container>
  )
}

export default ContactsButton