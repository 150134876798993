import React from 'react'
import { Field, Form as FormFinal } from 'react-final-form'
import styled from 'styled-components'
import { useLocation, useNavigate } from '@reach/router'

import { RequestsAuthorization } from '../../../../../modules/authorization'
import {
  Button,
  InputMaskFinal as InputMaskFinalBase,
} from '../../../../commons'
import { Container, Label, Title } from '../commons'
import { useCloseLogin } from './utils'
import { normalizePhone } from '../../../../../utils'
import { checkPhone } from '../../../../../modules/authorization/Requests'

const ButtonSubmit = styled(Button)`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
`

const ButtonRegistration = styled(Button)`
  width: 100%;

  background: #ff9f63;
`

const InputMaskFinal = styled(InputMaskFinalBase)`
  color: #35414c;
  text-align: center;
  margin-top: 8px;
`

type StepPhoneFC = React.FC<{
  phone: string
  nextStep: (x: { phone: string }) => void
}>
export const StepPhone: StepPhoneFC = ({ phone, nextStep }) => {
  const closeLogin = useCloseLogin()
  const location = useLocation()
  const navigate = useNavigate()

  const onRegister = (phone: string) => {
    checkPhone(phone)
      .then(phoneIsRegistered=> {
        if(phoneIsRegistered){
         return  navigate?.(location.pathname.replace('/login', '/user-exist'), {
            state: {
              phone: phone,
            },
          })
        }
        navigate?.(location.pathname.replace('/login', '/registration'), {
          state: {
            phone: phone,
          },
        })
      })

  }

  return (
    <FormFinal
      onSubmit={({ phone }) =>
        RequestsAuthorization.login({
          phone: normalizePhone(phone),
        }).then(({ status }) => {
          if (status === 400) {
            navigate?.(location.pathname.replace('/login', '/registration'), {
              state: {
                phone: normalizePhone(phone),
                isYetNotRegistration: true,
              },
            })
          }
          return nextStep({ phone: normalizePhone(phone) })
        })
      }
      initialValues={{ phone }}
      render={({
                 handleSubmit,
                 submitting,
                 invalid,
                 form: { getFieldState },
               }) => (
        // @ts-ignore
        <Container onSubmit={handleSubmit} onRequestClose={closeLogin}>
          <Title>Вход или регистрация</Title>
          <Label>
            Номер телефона
            <Field
              validate={(phone: string) =>
                normalizePhone(phone).length < 11 ? 'Обязательное поле' : ''
              }
              name='phone'
              mask='+{7} (000) 000-00-00'
              lazy={false}
              component={InputMaskFinal}
            />
          </Label>
          <ButtonSubmit type='submit' disabled={submitting || invalid}>
            Войти
          </ButtonSubmit>
          <ButtonRegistration
            disabled={submitting || invalid}
            onClick={()=>onRegister(normalizePhone(getFieldState('phone')?.value ?? ''))}
          >
            Зарегистрироваться
          </ButtonRegistration>
        </Container>
      )}
    />
  )
}
