import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { removeReorder } from '../../../modules/basket'
import { Product as ProductModel } from '../../../modules/product'
import { addButton, emptyProduct } from '../../images'
import { useAction } from '@reatom/react'
import { formatCount } from '../../../utils'

type ImageProps = {
  image?: string
}

interface isDiscount {
  isDiscount: boolean
  discountSize: number
}

interface DiscountSize {
  discountSize: number
}

const Container = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    flex-basis: 253px;
    margin-right: 11px;
  }
  @media screen and (min-width: 1321px) and (max-width: 1400px) {
    flex-basis: 253px;
    margin-right: 45px;
  }

  @media screen and (min-width: 1220px) and (max-width: 1320px) {
    flex-basis: 253px;
    margin-right: 35px;
  }

  @media screen and (min-width: 920px) and (max-width: 1139px) {
    flex-basis: 212px;
    margin-right: 8px;
  }
  @media screen and (min-width: 768px) and (max-width: 919px) {
    flex-basis: 212px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    flex-basis: 212px;
    margin-right: 9px;
  }

  @media screen and (min-width: 430px) and (max-width: 619px) {
    flex-basis: 200px;
    min-width: 200px;
  }
  @media screen and (min-width: 380px) and (max-width: 429px) {
    flex-basis: 165px;
    min-width: 165px;
    margin-right: 15px;
  }

  @media screen and (min-width: 320px) and (max-width: 379px) {
    flex-basis: 140px;
    min-width: 140px;
    margin-right: 15px;
  }
`

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 340px;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 6px;
  text-decoration: none;
  @media screen and (min-width: 1440px) {
    width: 250px;
  }

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    max-width: 253px;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    min-width: 212px;
    max-width: 212px;
  }
  @media screen and (min-width: 768px) and (max-width: 919px) {
    min-width: 212px;
    max-width: 212px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    min-width: 212px;
    max-width: 212px;
  }

  @media screen and (max-width: 619px) {
    width: 100%;
    min-width: 140px;
    max-width: 200px;
    height: 215px;
  }
`

const Content = styled.div`
  margin-left: 20px;

  @media screen and (max-width: 767px) {
    margin-left: 13px;
  }
  @media screen and (min-width: 768px) and (max-width: 919px) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  height: 145px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 320px) and (max-width: 619px) {
    height: 92px;
  }
`

const TopProductTriangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 76px;
  height: 76px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  background-color: #52cf26;
  border-bottom-left-radius: 5px;
  transform: rotate(90deg);

  &:after {
    content: 'Хит продаж';
    text-align: center;
    color: white;
    position: absolute;
    bottom: 10px;
    right: 20px;
    transform: rotate(-135deg);
    font-size: 12px;
    text-transform: uppercase;
  }
`

const ImageContainer = styled.img<ImageProps>`
  width: 100%;
  height: 145px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;

  @media screen and (min-width: 620px) and (max-width: 767px) {
    height: 145px;
  }
  @media screen and (min-width: 380px) and (max-width: 619px) {
    height: 92px;
    object-fit: cover;
  }
  @media screen and (min-width: 320px) and (max-width: 379px) {
    height: 92px;
  }
`

const DiscountSizeContainer = styled.div<DiscountSize>`
  width: 64px;
  height: 32px;
  background-color: #f0640c;
  position: absolute;
  top: 10px;
  right: 10px;
  display: ${({ discountSize }) =>
    discountSize === 0 || discountSize == 100 ? 'none' : 'inline'};
`

const DiscountSize = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding-top: 7px;
`

const Title = styled.div`
  padding-top: 17px;
  padding-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #495560;

  @media screen and (min-width: 768px) {
    padding-top: 12px;
    width: 182px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    padding-top: 15px;

    padding-bottom: 5px;
  }
  @media screen and (min-width: 375px) and (max-width: 620px) {
    max-width: 120px;
  }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    max-width: 120px;
  }
`

const Description = styled.div`
  padding-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  max-width: 200px;
  color: #8b949b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-clamp: 2;

  @media screen and (min-width: 620px) and (max-width: 1440px) {
    max-width: 170px;
  }
  @media screen and (max-width: 619px) {
    display: none;
  }
`

const WithVATDescription = styled.div`
  padding-bottom: 3px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  max-width: 200px;
  color: #8b949b;

  line-clamp: 2;

  @media screen and (min-width: 620px) and (max-width: 1440px) {
    max-width: 170px;
  }
  @media screen and (max-width: 619px) {
    display: none;
  }
`

const Price = styled.div`
  display: flex;
  align-items: center;
`

const PriceText = styled.div<isDiscount>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? '12px' : '18px'};
  line-height: 25px;
  color: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? '#8B949B' : '#35414C'};
  padding-bottom: 5px;
  text-decoration: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'line-through' : 'none'};
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 6px;
  }
`

const DiscountPrice = styled.div<isDiscount>`
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'inline' : 'none'};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f0640c;
  padding-left: 5px;
`

const Measurement = styled.div<isDiscount>`
  padding-left: 5px;
  padding-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'none' : 'inline'};
`

const DiscountMeasurement = styled.div<isDiscount>`
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'inline' : 'none'};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  height: 38px;
  text-decoration: none;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: auto;
  margin-left: 5px;

  :hover {
    box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-left: 5px;
  }

  @media screen and (min-width: 0) and (max-width: 767px) {
    :hover {
      box-shadow: none;
    }
    margin-top: 0;
    display: none;
  }
`

const ButtonContainerMobile = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  height: 38px;
  text-decoration: none;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: auto;
  margin-left: 13px;
  max-height: 28px;

  @media screen and (min-width: 320px) and (max-width: 619px) {
    width: 92%;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const OrderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #f0640c;
  padding-left: 10px;
  padding-top: 2px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const AddImage = styled.img.attrs({
  src: addButton,
})`
  height: 19px;
  width: 19px;
  margin-left: 14px;

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`

type ProductFC = React.FC<Partial<ProductModel>>
export const Product: ProductFC = (product) => {
  const {
    title,
    measurement,
    description,
    price,
    image,
    id,
    discount,
    subcategoryTop,
    categoryTop,
    withVAT,
  } = product
  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
    ev.currentTarget.src = emptyProduct
  }
  let withVatLine = withVAT
    ? `Цена за 1 ${measurement} с доставкой (в т.ч с НДС)`
    : `Цена за 1 ${measurement} с доставкой (без НДС)`
  // @ts-ignore
  const discountSize = Math.round((1 - discount / price) * 100)

  const removeProductFromReorder = React.useCallback(() => deleteReorder(), [])
  const deleteReorder = useAction(removeReorder)

  //Images Size add latee






  const priceString = formatCount(price ?? 1)

  return (
    <Container>
      <Item to={`/basket/${id}`} onClick={removeProductFromReorder}>
        <ImageWrapper>
          {(categoryTop || subcategoryTop) && <TopProductTriangle />}
          <DiscountSizeContainer discountSize={discountSize}>
            <DiscountSize>-{discountSize}%</DiscountSize>
          </DiscountSizeContainer>
          <ImageContainer src={image} onError={(e) => addDefaultSrc(e)} />
        </ImageWrapper>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <WithVATDescription>{withVatLine}</WithVATDescription>
          <Price>
            <PriceText isDiscount={!!discount} discountSize={discountSize}>
              {priceString}₽
            </PriceText>
            <Measurement isDiscount={!!discount} discountSize={discountSize}>
              {measurement}
            </Measurement>
            <DiscountPrice isDiscount={!!discount} discountSize={discountSize}>
              {discount}₽
            </DiscountPrice>
            <DiscountMeasurement
              isDiscount={!!discount}
              discountSize={discountSize}
            >
              {measurement}
            </DiscountMeasurement>
          </Price>
        </Content>
        <ButtonContainerMobile>
          <AddImage />
          <OrderText>Заказать</OrderText>
        </ButtonContainerMobile>
        <ButtonContainer>
          <AddImage />
          <OrderText>Заказать</OrderText>
        </ButtonContainer>
      </Item>
    </Container>
  )
}

export default Product
