import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import FilterDropDown from '../../commons/FilterDropDown'
import { Category } from '../../../modules/category'
import { checkboxIcon, ListViewIcon, TableViewIcon } from '../../images'
import RangeSlider from '../../commons/RangeSlider'
import { QuanityOfElementsFilter } from '../../commons/QuanityOfElementsFilter'
import { PriceRangeInputs } from '../../commons/PriceRangeInputs'
import { SubcategoriesList } from '../../commons/SubcategoriesList'
import { DropFiltersButton } from '../../commons/DropFiltersButton'
// в ts поменять id и htmlFor с string на string | number

interface LinkProps {
  isActive: boolean
}

type Prices = {
  max?: number
  min?: number
}

const ListView = styled(ListViewIcon)<LinkProps>`
  height: 14px;
  stroke: ${({ isActive }) => (isActive ? '#F0640C' : '#8B949B')};
`

const TableView = styled(TableViewIcon)<LinkProps>`
  height: 15.5px;
  stroke: ${({ isActive }) => (isActive ? '#F0640C' : '#8B949B')};
`

const Container = styled.div<LinkProps>`
  margin-top: 30px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  width: 305px;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: sticky;
  top: 1.5em;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};  
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};

  margin-bottom: 162px;
  @media screen and (min-width: 1440px) {
    min-width: 305px;
  }

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    min-width: 273px;
    max-width: 273px;
    margin-left: 30px;
  }

  @media screen and (min-width: 620px) and (max-width: 1139px) {
    margin: 0;
  }

  @media screen and (max-width: 719px) {
    display: none;
  }
`
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
  padding-left: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f4f6;
`

const FilterHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

const FilterHeaderRight = styled.div`
  margin-right: 44px;
  display: flex;
  align-items: center;
`

const FilterHeaderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  padding-right: 17px;
  color: #495560;
`

const ChangeView = styled.div<LinkProps>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ isActive }) =>
    isActive ? '0px 2px 5px rgba(10, 21, 128, 0.1)' : 'none'};
  cursor: pointer;
`

const SortByContainer = styled.div`
  border-bottom: 1px solid #f0f4f6;
  padding-bottom: 24px;
  position: relative;
`

const SortByTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  padding-left: 30px;
  padding-top: 15px;
  color: #495560;
  font-size: 14px;
  line-height: 19px;

  @media screen and (min-width: 1440px) {
    padding-left: 30px;
  }
`

const CategoryTitle = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #35414c;
  font-weight: 600;
  padding-left: 30px;

  padding-top: 15px;
  @media screen and (min-width: 1440px) {
    padding-left: 30px;
  }
`

const FiltersContainer = styled.div`
  border-top: 1px solid #f0f4f6;
  margin-top: 15px;
  border-bottom: 1px solid #f0f4f6;
  padding-bottom: 26px;
`

const PriceRangeName = styled.div`
  font-family: 'Open Sans', sans-serif;
  margin-top: 15px;
  padding-left: 30px;

  color: #495560;
  font-size: 14px;
  line-height: 19px;
  @media screen and (min-width: 1440px) {
    padding-left: 30px;
  }
`

const BottomContainer = styled.div`
  padding-left: 0;
`

const RangeContainer = styled.div<LinkProps>`
  display: ${({ isActive }) => (isActive ? 'inline' : 'none')};
`

type Sorting = {
  sortKey: string
  sortDirection: string
}

type Filters = React.FC<{
  activeCategoryTitle: string
  subcategories: Category[]
  prices: Prices
  priceInputValue: Prices
  setMaxFilterPrice: Dispatch<SetStateAction<string>>
  setMinFilterPrice: Dispatch<SetStateAction<string>>
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  selectedSubcategoriesIds: number[]
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  setSelectedSortingTypes: Dispatch<SetStateAction<Sorting>>
  setRowViewCallback: () => void
  setTableViewCallback: () => void
  isTableView: boolean
  setLimit: Dispatch<SetStateAction<number>>
  limit: number
  selectedSortingTypes: Sorting
  isActive: boolean
}>

export const Filters: Filters = ({
  activeCategoryTitle,
  subcategories,
  setSelectedSubcategoriesIds,
  selectedSubcategoriesIds,
  prices,
  priceInputValue,
  setMaxFilterPrice,
  setMinFilterPrice,
  setPriceInputValue,
  setSelectedSortingTypes,
  setRowViewCallback,
  setTableViewCallback,
  isTableView,
  setLimit,
  limit,
  selectedSortingTypes,
  isActive
}) => {
  const [localInputValueMin, setLocalInputValueMin] = React.useState(0)
  const [localInputValueMax, setLocalInputValueMax] = React.useState(0)
  //const [display, setDisplay] = React.useState(true)

  React.useEffect(() => {
    setPriceInputValue({
      min: prices?.min ? prices?.min : 0,
      max: prices?.max ? prices?.max : 0,
    })
    setLocalInputValueMin(prices?.min ? prices?.min : 0)
    setLocalInputValueMax(prices?.max ? prices?.max : 0)
  }, [prices, setPriceInputValue])
  const enable = React.useMemo(()=>prices.max === prices.max, [prices])

  // React.useEffect(() => {
  //   if (priceInputValue.min == priceInputValue.max) {
  //     setDisplay(false)
  //   } else {
  //     setDisplay(true)
  //   }
  // }, [priceInputValue])
  // just test
  // console.log('prices :>> ', prices);
  // console.log('priceInputValue :>> ', priceInputValue);
  return (
    <Container isActive={isActive}>
      <FilterHeader>
        <FilterHeaderLeft>
          <FilterHeaderText>Вид</FilterHeaderText>
          <ChangeView onClick={setRowViewCallback} isActive={!isTableView}>
            <ListView isActive={!isTableView} />
          </ChangeView>
          <ChangeView onClick={setTableViewCallback} isActive={isTableView}>
            <TableView isActive={isTableView} />
          </ChangeView>
        </FilterHeaderLeft>
        <FilterHeaderRight>
          <QuanityOfElementsFilter setLimit={setLimit} limit={limit} />
        </FilterHeaderRight>
      </FilterHeader>
      <SortByContainer>
        <SortByTitle>Сортировка</SortByTitle>
        <FilterDropDown
          setSelectedSortingTypes={setSelectedSortingTypes}
          prices={prices}
          selectedSortingTypes={selectedSortingTypes}
        />
      </SortByContainer>
      <CategoryTitle>{activeCategoryTitle}</CategoryTitle>
      <SubcategoriesList
        subcategories={subcategories}
        selectedSubcategoriesIds={selectedSubcategoriesIds}
        setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
      />
      <FiltersContainer>
        <div style={{paddingLeft: '30px', paddingRight:'30px'}}>
          <PriceRangeName>Цена</PriceRangeName>
          <PriceRangeInputs
            priceInputValue={priceInputValue}
            setPriceInputValue={setPriceInputValue}
            prices={prices}
            localInputValueMin={localInputValueMin}
            localInputValueMax={localInputValueMax}
            setMaxFilterPrice={setMaxFilterPrice}
            setMinFilterPrice={setMinFilterPrice}
            setLocalInputValueMin={setLocalInputValueMin}
            setLocalInputValueMax={setLocalInputValueMax}
          />
        </div>
        <RangeContainer isActive={true}>
          <RangeSlider
            priceInputValue={priceInputValue}
            setPriceInputValue={setPriceInputValue}
            prices={prices}
            localInputValueMin={localInputValueMin}
            localInputValueMax={localInputValueMax}
            setMaxFilterPrice={setMaxFilterPrice}
            setMinFilterPrice={setMinFilterPrice}
            setLocalInputValueMin={setLocalInputValueMin}
            setLocalInputValueMax={setLocalInputValueMax}
          />
        </RangeContainer>
      </FiltersContainer>
      <BottomContainer>
        <DropFiltersButton
          setLocalInputValueMin={setLocalInputValueMin}
          setLocalInputValueMax={setLocalInputValueMax}
          setSelectedSortingTypes={setSelectedSortingTypes}
          setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
          setLimit={setLimit}
          setPriceInputValue={setPriceInputValue}
          prices={prices}
        />
      </BottomContainer>
    </Container>
  )
}
