import styled from 'styled-components'
import { plus } from '../images'
import React from 'react'

export const ButtonBase = styled.button.attrs(({ type = 'button' }) => ({
  type,
}))`
  padding: 0;
  background: initial;
  border: initial;
  outline: none !important;
  cursor: pointer;
`

export const Button = styled(ButtonBase)<{ isError?: boolean }>`
  padding: 8px 16px;

  font-family: 'Open Sans';
  font-size: 15px;
  color: #ffffff;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 4px 10px rgba(247, 137, 68, 0.55);
  }
  &:disabled {
    background-color: #ff9f63;
    cursor: initial;
    box-shadow: initial;
  }
  transition: 225ms;

  background-color: #f0640c;
  border-radius: 6px;
  border: 1px solid transparent;
  ${({ isError }) => (isError ? 'border-color: #F06060;' : '')}
`

export const ButtonWhite = styled(Button)`
  background: #ffffff;
  color: #f0640c;
  box-shadow: 0 2px 5px rgba(2, 9, 75, 0.15);
  &:disabled {
    background-color: white;
    cursor: initial;
    box-shadow: initial;
  }
`

const IconAdd = styled.img`
  width: 9px;

  user-select: none;
`
const IconMinus = styled.div`
  width: 9px;
  height: 1px;

  border-radius: 0.5px;
  background-color: #f0640c;
`
const ButtonCircle = (img: React.ReactElement) =>
  styled(ButtonBase).attrs({ children: img })`
    width: 17px;
    height: 17px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #f0640c;
    border-radius: 50%;
  `

export const ButtonAdd = ButtonCircle(<IconAdd src={plus} />)
export const ButtonMinus = ButtonCircle(<IconMinus />)
