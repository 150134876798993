import React from 'react'
import { Category, CategoryRequests } from '../modules/category'


export type CurrentCatalogueItem = {
  id: number
  title: string
  subIds: number[]
}

type contextValue = {
  categories: Category[],
  setCategories : React.Dispatch<React.SetStateAction<Category[]>>,
  activeCategoryId: number,
  setActiveCategoryId : React.Dispatch<React.SetStateAction<number>>
  currentCatalogue: CurrentCatalogueItem[],
  setCurrentCatalogue : React.Dispatch<React.SetStateAction<CurrentCatalogueItem[]>>
}

const defaultValue: contextValue = {
  categories: [],
  activeCategoryId: 0,
  currentCatalogue: [],
  setCategories: ()=>{},
  setActiveCategoryId: ()=>{},
  setCurrentCatalogue: ()=>{},
}

const CategoryContext = React.createContext(defaultValue)

export const useCategoryContext  = () => React.useContext(CategoryContext)

export const CategoryProvider = ({children}: {children:React.ReactChild}) => {
  
  const [categories, setCategories] = React.useState<Category[]>([])
  const [activeCategoryId, setActiveCategoryId] = React.useState(0)  
  const [currentCatalogue, setCurrentCatalogue] = React.useState<CurrentCatalogueItem[]>([])

  React.useEffect(() => {
    if (!categories.length) {
      CategoryRequests.fetchGetCategories().then(setCategories)
    }
  }, [categories])

  const value = {
    categories,
    setCategories,
    activeCategoryId,
    setActiveCategoryId,
    currentCatalogue, 
    setCurrentCatalogue
  }

  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>

}
