import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { useNavigate } from '@reach/router'
// import { selectGlobalCategory, } from '../../../../modules/basket'
import { useAction } from '@reatom/react'

import { LinkProps } from '../../../../modules/validators'

const Title = styled.div<LinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#F0640C' : '#35414C')};
  padding: 0 15px 0;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #F0640C' : 'none')};
  cursor: pointer;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: 13px;
    line-height: 18px;
  }


  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 19px;
    padding: 5px 25px 5px 25px;
  }
`

const Image = styled.img<LinkProps>`
  margin-right: 7px;
  height: 20px;
  filter: ${({ isActive }) =>
    isActive
      ? 'invert(41%) sepia(95%) saturate(1252%) hue-rotate(354deg) brightness(94%) contrast(101%)'
      : 'invert(23%) sepia(8%) saturate(1310%) hue-rotate(167deg) brightness(97%) contrast(92%)'};
`



export const HeaderCategory: React.FC<{
  activeCategoryId: number
  selectCategoryHandler: Dispatch<SetStateAction<number>>
  id: number
  title: string
  icon?: string
  isMainHeader?: boolean
}> = ({ activeCategoryId, id, selectCategoryHandler, title, isMainHeader, icon}) => {
  const navigate = useNavigate()
  //const addGlobalCategory = useAction(selectGlobalCategory)

  const selectCategory = React.useCallback(() => {
    if (window.location.pathname !== '/products') {
      navigate?.(`/products`)
    }
    selectCategoryHandler(id)
   // addGlobalCategory(id)
    
  }, [id, selectCategoryHandler, isMainHeader])

  const isActive = React.useMemo(
    () => id === activeCategoryId && window.location.pathname === '/products',
    [id, activeCategoryId, window.location.pathname],
  )

  return (
    <Title id={id.toString()} onClick={selectCategory} isActive={isActive}>
      {icon && <Image src={icon} isActive={isActive} />}
      {title}
    </Title>
  )
}
