import { ReactComponent as arrow } from './arrow.svg'
import { ReactComponent as arrowLong } from './arrowLong.svg'
import styled from 'styled-components'

export enum Direction {
  Up,
  Down,
  Left,
  Right,
}

type Rotate = { direct: Direction }
const rotateArrow = ({ direct }: Rotate) => {
  switch (direct) {
    case Direction.Up:
      return '180'
    case Direction.Right:
      return '-90'
    case Direction.Down:
      return '0'
    case Direction.Left:
      return '90'
  }
}

export const Arrow = styled(arrow)<Rotate>`
  transform: rotate(${rotateArrow}deg);
`

const rotateArrowLong = ({ direct }: Rotate) => {
  switch (direct) {
    case Direction.Up:
      return '90'
    case Direction.Right:
      return '0'
    case Direction.Down:
      return '-90'
    case Direction.Left:
      return '180'
  }
}
export const ArrowLong = styled(arrowLong)<Rotate>`
  transform: rotate(${rotateArrowLong}deg);
`
