const Repository = {
  name: '______id',
  set: (id: number): void => localStorage.setItem(Repository.name, String(id)),
  get: (): number => {
    const value = localStorage.getItem(Repository.name)
    if (value === null) {
      return 0
    } else {
      return Number(value)
    }
  },
}

export const Id = {
  num: Repository.get(),
  getCurrentId() {
    return String(Id.num)
  },
  generateId() {
    const id = Id.getCurrentId()
    Id.num++
    Repository.set(Id.num)
    return id
  },
}
