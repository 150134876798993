import React from 'react'
import styled, { css } from 'styled-components'
import getDaysInMonth from 'date-fns/fp/getDaysInMonth'
import startOfMonth from 'date-fns/fp/startOfMonth'
import getDay from 'date-fns/fp/getDay'
import addMonths from 'date-fns/fp/addMonths'
import subMonths from 'date-fns/fp/subMonths'
import setDate from 'date-fns/fp/setDate'
import isSameYear from 'date-fns/fp/isSameYear'
import isSameMonth from 'date-fns/fp/isSameMonth'
import isSameDay from 'date-fns/fp/isSameDay'
import isBefore from 'date-fns/fp/isBefore'
import { fetchGetSchedule } from '../../../modules/schedule/Requestes'
import { ButtonBase } from '../Buttons'
import { format } from '../../../utils'
import { Arrow as ArrowBase, Direction } from '../../images'

const Container = styled.div``

const DateText = styled.div`
  margin: 0 auto;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 17px;
  color: #495560;
`

const Button = styled(ButtonBase)`
  width: 36px;
  height: 36px;

  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 4px;
`

const Header = styled.div`
  margin-bottom: 13px;

  display: flex;
  align-items: center;
`

const Wrapper = styled.div<{responsive?:boolean}>`
  display: grid;
  grid-template:${({ responsive }) =>responsive ?
          css` repeat(5, 38px) / repeat(7, 14%)`
          : 
          css` repeat(5, 38px) / repeat(7, 38px)`};
`

const Day = styled(ButtonBase)<{ active?: boolean }>`
  height: 100%;
  width: 100%;
  margin: auto;

  border-radius: 6px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 15px;
  color: rgb(53, 65, 76, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: color, background-color 225ms;

  ${({ active }) =>
    active
      ? css`
          background-color: rgb(240, 100, 12, 0.7);
          color: white;
          box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
        `
      : css`
          &:hover {
            height: 30px;
            width: 30px;

            box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
          }
        `}
  &:disabled {
    color: rgb(139, 148, 155, 0.5);
    box-shadow: initial;
    cursor: initial;
  }
`

const Arrow = styled(ArrowBase)`
  height: 10px;
`

const addMonth = addMonths(1)
const subMonth = subMonths(1)
const dateFormat = format('yyyy MMMM')
const isEqual = (a: Date, b: Date) =>
  [isSameYear, isSameMonth, isSameDay].every((f) => f(a)(b))

export type CalendarForm = {
  onChange: (value: Date) => void
  value: Date
}

const emptyDays = (date: Date): number[] => {
  let day: number = getDay(startOfMonth(date))
  if (day === 0) {
    day = 7
  }
  const prevMonth = subMonth(date)
  let dayInMonth = getDaysInMonth(prevMonth)
  return new Array(day - 1)
    .fill(undefined)
    ?.map(() => dayInMonth--)
    .reverse()
}

type CalendarFC = React.FC<
  {
    disabled?: (date: Date) => boolean
    className?: string
    responsive?: boolean
  } & CalendarForm
>
export const Calendar: CalendarFC = ({
  onChange,
  value,
  disabled = isBefore(new Date()),
  className,
  responsive
}) => {
  const [cursor, setCursor] = React.useState(value)
  const [schedule, setSchedule] = React.useState({})

  React.useEffect(() => {
    setCursor(value)
  }, [value])

  React.useEffect(() => {
    fetchGetSchedule().then(setSchedule)
  }, [])

  return (
    <Container className={className}>
      <Header>
        <Button onClick={() => setCursor(subMonth)}>
          <Arrow direct={Direction.Left} />
        </Button>
        <DateText>{dateFormat(cursor)}</DateText>
        <Button onClick={() => setCursor(addMonth)}>
          <Arrow direct={Direction.Right} />
        </Button>
      </Header>
      <Wrapper responsive={responsive}>
        {emptyDays(cursor)?.map((day) => (
          <Day key={day} disabled>
            {day}
          </Day>
        ))}
        {Array(getDaysInMonth(cursor))
          .fill(undefined)
          ?.map((_, day) => {
            const showDay = day + 1
            const date = setDate(showDay)(cursor)
            return (
              <Day
                key={showDay}
                active={isEqual(date, value)}
                disabled={disabled(date)}
                onClick={() => onChange(date)}
              >
                {showDay}
              </Day>
            )
          })}
      </Wrapper>
    </Container>
  )
}
