//@ts-nocheck

import React from 'react'
import styled from 'styled-components'
import { Product as ProductModel } from '../../../../modules/product'
import { Image as ImageBase } from '../../../commons'
import { btTablet, formatCount, ltDesktop, ltTablet } from '../../../../utils'

interface LinkProps {
  isActive: boolean
}

const Container = styled.div`
  padding: 20px 30px;
  ${ltDesktop} {
    padding: 20px;
  }
  ${ltTablet} {
    padding: 15px;
  }

  background: #ffffff;
  ${btTablet} {
    border-radius: 6px;
  }
`

const ProductContainer = styled.div`
  display: flex;
  @media screen and (max-width: 520px) {
    flex-direction: column;
  }
  @media screen and (min-width: 521px) {
    flex-direction: top;
  }
`

const Image = styled(ImageBase)`
  min-width: 231px;
  min-height: 231px;
  width: 231px;
  height: 231px;
  margin-right: 14px;
`

const Title = styled.div`
  margin-top: 5px;
  margin-bottom: 6px;
  width: 100%;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
  color: #35414c;
`

const Price = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  color: #f0640c;
`

const Measurement = styled.span`
  margin-left: 6px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 15px;
  color: #8b949b;
`

const StyledA = styled.a`
  padding-top: 10px;
  text-decoration: none;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  /* PrimeryOrange */
  color: #F0640C;

  border-radius: 2px;

  flex: none;
  order: 1;
  flex-grow: 1;
`
const Description = styled.div`
  padding-top: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  /* Gray */
  color: #495560;

  opacity: 0.7;
  border-radius: 2px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  white-space: break-spaces;
`

type Product = React.FC<Partial<ProductModel>>
export const Product: Product = ({
  image,
  title,
  price,
  measurement,
  description,
  standard,
  standardFile
}) => {
  const sf = React.useMemo(()=>{
    const host = (process.env.NODE_ENV === 'production')? 'https://prostore.su' : 'https://dev.prostore.su';
    return `${host}/${standardFile}`;
    // return standardFile?.startsWith('./')?
    //  `${host}${standardFile.slice(1)}`
    //  :
    //  standardFile
  },[standardFile, process.env.NODE_ENV])
  return (
      <ProductContainer>
        <Image src={image} alt="product" />
        <div>
          <Title>{title}</Title>
          <div>
            {price && <Price>{formatCount(price)} ₽</Price>}
            <Measurement>{measurement} </Measurement>
          </div>
          <Description>{description}</Description>
          { 
            standard && standardFile &&
            <Description>{standard} <StyledA href={sf} download target="_blank">скачать</StyledA></Description>
          }
        </div>
      </ProductContainer>
  )
}
