import { Product } from './product'

type Store<InitialStore, Data> = {
  initialStore?: InitialStore
  data?: Data
}

declare global {
  interface Window {
    __serverData__: Store<any, any>
  }
}

export const getServerSideData = <Data>(): Data => {
  return window.__serverData__?.data
}

export const getServerSideInitialStore = <InitialStore>(): InitialStore => {
  return window.__serverData__?.initialStore
}

let firstRender = true

export const theEndFirstRender = () => {
  firstRender = false
}

const onlyFirstRender = <A extends (...args: any) => any>(f: A) => (
  ...args: Parameters<A>
): ReturnType<A> | undefined => {
  if (firstRender) {
    return f(...args)
  }
}

export const getProducts = onlyFirstRender(() =>
  getServerSideData<{ products: Product[]; total: number }>(),
)
