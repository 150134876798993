import { useLocation, useNavigate } from '@reach/router'

export const useCloseRegistration = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return () => {
    if (location) {
      navigate?.(location.pathname.replace('/registration', ''), {
        replace: true,
      })
    }
  }
}
