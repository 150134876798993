import React from 'react'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Form as FormFinal, FormSpy } from 'react-final-form'
import styled from 'styled-components'
import { useAction, useAtom } from '@reatom/react'
import { useNavigate } from '@reach/router'
import { PreLoader } from '../../../commons/PreLoader/PreLoader'
import moment from 'moment'
import {
  fetchGetSchedule,
  fetchGetWorkingSchedule,
} from '../../../../modules/schedule/Requestes'
import {
  Basket as BasketModel,
  Delivery as DeliveryModel,
  postBasket as postBasketBase,
  putBasketByProductId as putBasketByProductIdBase,
  reorder,
} from '../../../../modules/basket'
import { Button, ButtonWhite } from '../../../commons'
import { Id } from '../../../../modules/Id'
import { Product as ProductModel } from '../../../../modules/product'
import { arrowLong, Basket, plus } from '../../../images'

import { Delivery } from './Delivery'
import { Product } from './Product'
import { btTablet, formatCount, ltDesktop, ltTablet } from '../../../../utils'
import { Address } from '../../../../modules/cartography'
import { DeliveryType } from './DeliveryType'

const FormStyle = styled.form`  
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Wrapper = styled.form`
  display: flex;
  align-items: flex-start;
  
  ${ltTablet} {
    flex-direction: column;
  }
`

const IconArrow = styled.img`
  height: 12px;
`

const AddDelivery = styled(ButtonWhite)`
  padding: 14px 0;

  font-weight: 600;

  ${ltTablet} {
    border-radius: initial;
  }
`

const IconAdd = styled.img`
  height: 10px;
  margin-right: 8px;
`

const RightWrapper = styled.div`
  width: 100%;
  min-width: 283px;
  margin-left: 30px;

  ${btTablet} {
    flex: 1;
  }

  ${ltDesktop} {
    margin-left: 20px;
  }

  ${ltTablet} {
    margin: 10px 0 0;
  }
`

const Sum = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  color: #495560;
`

const TotalPrice = styled.div`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  color: #35414c;
`

const TotalWrapper = styled.div`
  padding: 15px 30px;

  background: #ffffff;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);

  ${btTablet} {
    border-radius: 6px;
  }

  ${ltDesktop} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${ltTablet} {
    padding: 15px;
    border-top: 1px solid rgba(10, 21, 128, 0.15);
  }
`

const WrapperSum = styled.div`
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SubmitButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin-bottom: 70px;
  }
`

const BasketButton = styled(SubmitButton)`
  background-color: #FF9F63
`

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  flex: 2;
`

const PreLoaderContainer = styled.div`
  min-width: 1053px;
  max-width: 1053px;
  display: flex;
  justify-content: center;
  padding-top: 250px;
  margin-left: auto;
  margin-right: auto;
`

const BasketIcon = styled(Basket)`
  height: 16px;

  stroke: white;
`

const ErrorContainer = styled.div`
  padding-top: 15px;
`

const ErrorText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #f06060;
  text-align: center;
  padding-top: 7px;
`

const HighLowContainer = styled.div`
border-radius: 6px;
padding: 20px 30px;
background: #ffffff;
@media screen and (min-width: 1139px) {
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1139px) {
  display: none;
}

@media screen and (max-width: 767px) {
  display: block;
}`
const MidContainer = styled.div`
width: 100%;
border-radius: 6px;
margin-bottom: 20px;
background: #ffffff;

@media screen and (min-width: 1140px) {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1139px) {
  max-width: 866px;
  display: block;
}

@media screen and (max-width: 767px) {
  display: none;
}`

interface DefaultDelivery
  extends Omit<DeliveryModel, 'date' | 'address' | 'recipientPhone'> {
  date?: Date
  address?: Address
}

const defaultDelivery = ({ count }: { count: number }): DefaultDelivery => ({
  id: Id.generateId(),
  count,
  comment: '',
})
// @ts-ignore

const reorderDelivery = (deliveryData) => ({
  id: Id.generateId(),
  ...deliveryData,
})

const useSubmit = (
  isEdit: boolean,
  product?: ProductModel,
  isShowError?: boolean,
  navigateTo?: string,
  /// @ts-ignore
): ((form: { deliveries: DeliveryModel[] }) => void) => {
  console.log()
  const postBasket = useAction(postBasketBase)
  const putBasketByProductId = useAction(putBasketByProductIdBase)
  const navigate = useNavigate()
  // console.log(form)

  return React.useCallback(
    ({ deliveries }) => {
      if (!isShowError) {
        if (isEdit) {
          putBasketByProductId({
            // @ts-ignore
            product,
            deliveries: deliveries?.map((x) => ({ ...x, isSelect: true })),
          })
        } else {
          postBasket({
            // @ts-ignore
            product,
            deliveries: deliveries?.map((x) => ({ ...x, isSelect: true })),
          })
        }
        navigate?.(`/${navigateTo}`)
      }
    },

    [isEdit, navigate, putBasketByProductId, product, postBasket, navigateTo],
  )
}

type Form = React.FC<{
  isEdit: boolean
  basket: Partial<BasketModel>
}>
export const Form: Form = ({
                             isEdit,
                             basket: { deliveries, product },
                             basket,
                           }) => {
  const reorderValues = useAtom(reorder)
  const [calendarStartValue, setCalendarStartValue] = React.useState('')
  const [phoneNumberValue, setPhoneNumberValue] = React.useState('')
  const [recipientFIOValue, setRecipientFIOValue] = React.useState('')
  const [activeDeliveryOnce, setActiveDeliveryOnce] = React.useState(true)
  const [activeDeliveryRange, setActiveDeliveryRange] = React.useState(false)
  const [startRangeDay, setStartRangeDay] = React.useState('')
  const [endRangeDay, setEndRangeDay] = React.useState('')
  const [commonCount, setCommonCount] = React.useState(0)
  const [minPerDay, setMinPerDay] = React.useState(0)
  const [maxPerDay, setMaxPerDay] = React.useState(0)
  const [
    isOrderCountUnacceptable,
    setIsOrderCountUnacceptable,
  ] = React.useState(false)
  const [rangePeriodLength, setRangePeriodLength] = React.useState(0)
  const [schedule, setSchedule] = React.useState([])
  const [timeSchedule, setTimeSchedule] = React.useState({})
  const [weekend, setWeekend] = React.useState([])
  //errors
  const [isShowError, setIsShowError] = React.useState(false)

  const [navigateTo, setNavigateTo] = React.useState('baskets')

  // @ts-ignore
  const onSubmit = useSubmit(isEdit, { ...product }, isShowError, navigateTo)

  const errorHandler = () => {
    if (activeDeliveryRange && (!startRangeDay || !endRangeDay)) {
      setIsShowError(true)
      setTimeout(() => {
        setIsShowError(false)
      }, 3000)
    }
  }

  React.useEffect(() => {
    fetchGetSchedule().then(setSchedule)
    fetchGetWorkingSchedule().then(setTimeSchedule)
  }, [])

  React.useEffect(() => {
    //@ts-ignore
    const d = []
    schedule.forEach((element) => {
      //@ts-ignore
      if (element.time === 'выходной') {
        //@ts-ignore
        const weekendDay = moment(element.date).format('MM/DD/YYYY') //@ts-ignore
        d.push(weekendDay) //@ts-ignore
        setWeekend(d)
      }
    })
  }, [schedule])

  // @ts-ignore
  const { address: addressData } = reorderValues
  const reorderData = !Object.keys(reorderValues).length
    ? undefined
    : reorderDelivery({
      reorderValues,
      address: JSON.parse(addressData),
      // @ts-ignore
      count: Number(reorderValues.count),
      // @ts-ignore
      comment: reorderValues.comment,
      // @ts-ignore
    })

  React.useEffect(() => {
    if (
      activeDeliveryRange &&
      rangePeriodLength > 1 &&
      maxPerDay * rangePeriodLength < commonCount
    ) {
      setIsOrderCountUnacceptable(true)
    }
    if (!activeDeliveryRange || maxPerDay * rangePeriodLength >= commonCount) {
      setIsOrderCountUnacceptable(false)
    }
  }, [commonCount, maxPerDay, rangePeriodLength, activeDeliveryRange])

  const initialValues = React.useMemo(
    () => ({
      deliveries: reorderData
        ? [reorderData]
        : deliveries ?? [
        defaultDelivery({
          count: Number(product?.multiplicity) ?? 0, // @ts-ignore
        }),
      ],
    }),
    [deliveries, product, activeDeliveryRange],
  )

  const firstRender = React.useRef(false)
  if (!product) {
    return (
      <PreLoaderContainer>
        <PreLoader />
      </PreLoaderContainer>
    )
  }
  return (
    <FormFinal
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={onSubmit}
      // @ts-ignore
      initialValues={initialValues}
      render={({
                 handleSubmit,
                 pristine,
                 invalid,
                 form: {
                   mutators: { push },
                 },
               }) => (
                
        <FormStyle onSubmit={handleSubmit}>
          <MidContainer>
            <Product
              {...product} // @ts-ignore
            />
          </MidContainer>
          <Wrapper>
            <FormWrapper>
              <HighLowContainer>
                <Product
                  {...product} // @ts-ignore
                />
                <DeliveryType
                  activeDeliveryOnce={activeDeliveryOnce}
                  setActiveDeliveryOnce={setActiveDeliveryOnce}
                  setActiveDeliveryRange={setActiveDeliveryRange}
                  activeDeliveryRange={activeDeliveryRange}
                />
              </HighLowContainer>
              <FieldArray name='deliveries'>
                {({ fields }) =>
                  fields?.map((name, index) => {
                    Promise.resolve().then(() => {
                      firstRender.current = true
                    })
                    // add orderType
                    Object.assign(fields.value[index], {
                      orderType: activeDeliveryRange ? 'inTheRange' : '',
                    })
                    if (activeDeliveryRange) {
                      Object.assign(fields.value[index], {
                        rangeParams: {
                          startDate: moment(startRangeDay).format('YYYY-MM-DD'),
                          endDate: moment(endRangeDay).format('YYYY-MM-DD'),
                          minPerDay: minPerDay,
                          maxPerDay: maxPerDay,
                          commonCount: commonCount,
                        },
                      })
                    }
                    return (
                      <Delivery
                        key={name}
                        index={index}
                        name={name}
                        initialShow={firstRender.current}
                        disabledRemove={fields.length === 1}
                        removeSelf={() => fields.remove(index)}
                        product={product} // @ts-ignore
                        calendarStartValue={calendarStartValue} // @ts-ignore
                        setCalendarStartValue={setCalendarStartValue} // @ts-ignore
                        phoneNumberValue={phoneNumberValue} // @ts-ignore
                        setPhoneNumberValue={setPhoneNumberValue}
                        activeDeliveryOnce={activeDeliveryOnce}
                        activeDeliveryRange={activeDeliveryRange}
                        startRangeDay={startRangeDay}
                        setStartRangeDay={setStartRangeDay}
                        endRangeDay={endRangeDay}
                        setEndRangeDay={setEndRangeDay}
                        commonCount={commonCount}
                        setCommonCount={setCommonCount}
                        minPerDay={minPerDay}
                        setMinPerDay={setMinPerDay}
                        maxPerDay={maxPerDay}
                        setMaxPerDay={setMaxPerDay}
                        setRangePeriodLength={setRangePeriodLength}
                        setIsOrderCountUnacceptable={setIsOrderCountUnacceptable}
                        isShowError={isShowError}
                        schedule={schedule}
                        timeSchedule={timeSchedule}
                        weekend={weekend}
                        recipientFIOValue={recipientFIOValue}
                        setRecipientFIOValue={setRecipientFIOValue}
                      />
                    )
                  })
                }
              </FieldArray>
              <AddDelivery
                onClick={() =>
                  push(
                    'deliveries',
                    defaultDelivery({
                      count: Number(product?.multiplicity),
                    }),
                  )
                }
              >
                <IconAdd src={plus} />
                Добавить еще один адрес
              </AddDelivery>
            </FormWrapper>
            <RightWrapper>
              <TotalWrapper>
                <WrapperSum>
                  <Sum>Итого:</Sum>
                  <TotalPrice>
                    <FormSpy>
                      {({ values }) =>
                        formatCount(
                          values.deliveries.reduce(
                            (acc: number, { count }: DeliveryModel) =>
                              count * (product?.price ?? 0) + acc,
                            0,
                          ),
                        )
                      }
                    </FormSpy>{' '}
                    ₽
                  </TotalPrice>
                </WrapperSum>
                {!isOrderCountUnacceptable && (
                  <>
                    <SubmitButton
                      type='submit'
                      // disabled={
                      //   !product || pristine || invalid || isOrderCountUnacceptable
                      // }
                      // @ts-ignore
                      onClick={() => {
                        setNavigateTo('baskets')
                        errorHandler()
                      }}
                    >
                      <span>{isEdit ? 'Редактировать' : 'Оформить заказ'}</span>
                      <IconArrow src={arrowLong} />
                    </SubmitButton>
                    <BasketButton
                      type='submit'
                      onClick={() => {
                        setNavigateTo('products')
                        errorHandler()
                      }}
                    >
                      <span>Добавить в корзину</span>
                      <BasketIcon />
                    </BasketButton>
                  </>
                )}
                {isOrderCountUnacceptable && (
                  <ErrorText>
                    Данное количество товара невозможно доставить за заданный
                    период
                  </ErrorText>
                )}
                {/* {isShowError && (
                  <ErrorContainer>
                    <ErrorText>Хуй</ErrorText>
                  </ErrorContainer>
                )} */}
              </TotalWrapper>
            </RightWrapper>
          </Wrapper>
        </FormStyle>
      )}
    />
  )
}
