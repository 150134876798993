import React from 'react'
import styled from 'styled-components'
import { Location, RouteComponentProps } from '@reach/router'
import { Header } from './Header'
import { Footer } from './Footer'
import { Login, Registration} from './authorization'
import { UserExistModal } from './authorization/Registration/UserExistModal'
import { useCityContext } from '../../../context/CityContext'

const Container = styled.div`
  background-color: rgb(234, 238, 243);
  min-height: 100vh;
  position: relative;
  width: 100%;
`

type Main = React.FC<RouteComponentProps & {children?:any}>
export const Main: Main = ({ children, ...props }) => {
  const {cities, selectedCity, setSelectedCity} = useCityContext()

  return (
    <>
      <Header cities={cities} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
      <Container>{children}</Container>
      <Footer />
      <Location>
        {({ location, navigate }) => {
          const paths = location.pathname.split('/')
          const route = paths[paths.length - 1]
          return (
            <>
              {route === 'login' && (
                <Login location={location} navigate={navigate} />
              )}
              {route === 'registration' && (
                <Registration location={location} navigate={navigate} />
              )}
              {route === 'user-exist' && (
                <UserExistModal/>
              )}
            </>
          )
        }}
      </Location>
    </>
  )
}
