import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Category } from '../../../../modules/category'
import { checkboxIcon, ListViewIcon, TableViewIcon, collapse, expand } from '../../../images'
import { CurrentCatalogueItem } from '../../../../context/CategoryContext'
// в ts поменять id и htmlFor с string на string | number

const img = `
width: 24px;
height: 24px;
`
const Expand = styled.img.attrs({ src: expand })`
  ${img}
`

const Collapse = styled.img.attrs({ src: collapse })`
  ${img}
`

const MainCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 15px 15px 15px 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: adaptive;
`
const MainTitle = styled.div`
  cursor: pointer !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TitleText = styled.div`
  cursor: pointer !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* PrimeryGrey */
  color: #35414C;
`


const SubCategory = styled.div`
  cursor: pointer !important;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  /* Gray */

  color: #495560;
`

type Filters = React.FC<{
  subcategories: Category[]
  categoryId: number
  title: string
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  setActiveCategoryId: Dispatch<SetStateAction<number>>
  currentCatalogue: CurrentCatalogueItem[]
  setCurrentCatalogue: Dispatch<SetStateAction<CurrentCatalogueItem[]>>
}>
export const TreeCategory: Filters = ({
                                        subcategories,
                                        categoryId,
                                        title: activeCategoryTitle,
                                        setSelectedSubcategoriesIds,
                                        setActiveCategoryId,
                                        currentCatalogue,
                                        setCurrentCatalogue,
                                      }) => {
  const [collapsed, setCollapsed] = React.useState(false)

  const onClick = (id: number) => {
    const subcategoriesIds = subcategories.map((v) => v.id)
    const sub = subcategories.find((v) => id === v.id)
    const subIds = sub?.subcategories.map((v) => v.id) ?? []
    const newcat = [
      ...currentCatalogue,
      { id: categoryId, title: activeCategoryTitle, subIds: subcategoriesIds },
    ]
    if (id != categoryId) newcat.push({ id: id, title: sub?.title ?? '', subIds: subIds })

    setCurrentCatalogue(newcat)
    setActiveCategoryId(id)
    setSelectedSubcategoriesIds([id])
  }
  return (
    <MainCategory>
      <MainTitle
        onClick={subcategories?.length ? () => setCollapsed(!collapsed) : () => onClick(categoryId)}>
        <TitleText>{activeCategoryTitle}</TitleText>
        {subcategories?.length > 0 && (collapsed ? <Expand /> : <Collapse />)}</MainTitle>
      {
        collapsed && subcategories?.length ?
          <div>
            {subcategories.map((sub) => (
              <SubCategory key={sub.id} onClick={() => onClick(sub.id)}>{sub.title}</SubCategory>))}
          </div> : <div />
      }
    </MainCategory>
  )
}
