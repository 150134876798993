import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { emptyProduct, goto } from '../../../../images'

const ImageContainer = styled.img`
  object-fit: cover;
  align-items: center;
  width: 100%;
  height: 100%;
`

const SaleInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  align-items: center;

  display: flex;
  flex-direction: column;

  width: auto;

  min-width: 50%;
  min-height: 50%;
  max-width: 80%;
  max-height: 80%;
  padding: 15px;

  justify-content: space-between;
`

const SaleTitle = styled.div`
  width: auto;
  height: auto;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;

  color: #162836;

  /* Inside auto layout */
  align-self: stretch;

  @media screen and (max-width: 719px) {
    font-size: 14px;
  }
`

const SaleDescription = styled.div`
  width: auto;
  height: auto;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  /* or 127% */

  color: #162836;

  opacity: 0.5;
  align-self: stretch;

  @media screen and (max-width: 719px) {
    font-size: 13px;
  }
`
const SaleDiscountContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  width: auto;
  height: 100%;
`
const SaleDiscount = styled.div`
  /* 20% */

  width: 52px;
  height: 25px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 100%;
  /* identical to box height, or 25px */

  color: #f0640c;

  @media screen and (max-width: 719px) {
    font-size: 20px;
  }
`

const SaleLink = styled.img.attrs({ src: goto })`
  align-self: center;
  width: 16.58px;
  height: 16.58px;
  padding: 0px 15px 4px 0px;
`

const Container = styled(Link)`
  text-decoration: none;
  align-items: center;
  width: 441px;
  height: 300px;
  display: block;
  position: relative;
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    width: 380px;
    height: 260px;
    padding: 0px 16px 0px 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    width: 380px;
    height: 260px;
    padding: 0px 16px 0px 16px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    width: 380px;
    height: 260px;
    padding: 0px 5px 0px 5px;
  }
  @media screen and (max-width: 619px) {
    width: 228px;
    height: 192px;
    padding: 0px 5px 0px 5px;
  }
`

const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  width: auto;
  height: auto;

  :hover {
    box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
  }
`

export const Promo = (item) => {
  const { discount, product, title, background, description } = item
  const firstWord = title.replace(/ .*/, '')
  const addDefaultSrc = (ev) => {
    ev.currentTarget.src = emptyProduct
  }
  return (
    <Container to={`/basket/${product.id}`}>
      <ImageContainer src={background} onError={(e) => addDefaultSrc(e)} />
      <SaleInfo>
        <SaleTitle>{product.title}</SaleTitle>
        <SaleDescription>{description}</SaleDescription>
        <SaleDiscountContainer>
          <SaleDiscount>{Number(discount)}%</SaleDiscount>
          <span />
          <ButtonContainer to={`/basket/${product.id}`} state={product}>
            <SaleLink />
          </ButtonContainer>
        </SaleDiscountContainer>
      </SaleInfo>
    </Container>
  )
}
