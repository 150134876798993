import React from 'react'
import { Router } from '@reach/router'

import { NotFound } from '../screens/NotFound'

import { Main } from './Main'
import { Baskets } from '../screens/Baskets'
import { Basket } from '../screens/Basket'
import { Catalogue as Products } from '../screens/Products'
import { Profile } from '../screens/Profile'
import { Orders } from '../screens/Orders'
import { Order } from '../screens/Orders/Order'
import { MainPage } from '../screens/MainPage'

export const Navigators = () => {
  return (
    <Router>
      <Main path="/">
        <MainPage path="/main-page/*"/>
        <Baskets path="/baskets/*" />
        <Basket path="/basket/:productId/*" />
        <Products path="/products/*" />
        <Profile path="/profile/*" />
        <Orders path="/orders/*" />
        <Order path="/order/:orderId/*" />
        <NotFound default />
      </Main>
    </Router>
  )
}
