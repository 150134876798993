export const widthDesktopScreen = 1440
export const widthMobileScreen = 768
export const ltDesktop = `@media only screen and (max-width: ${
  widthDesktopScreen - 1
}px)`
export const btDesktop = `@media only screen and (min-width: ${widthDesktopScreen}px)`

export const ltTablet = `@media only screen and (max-width: ${
  widthMobileScreen - 1
}px)`
export const btTablet = `@media only screen and (min-width: ${widthMobileScreen}px)`
