import React from 'react'
import styled from 'styled-components'
import { fetchGetHitProducts } from '../../../../modules/hitProducts/Requestes'
import { ProductsBlock } from '../../../commons/ProductsBlock/ProductsBlock'

const HitProductsSection = styled.div`
  padding-top: 37px;
`

const SectionHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 130%;
  /* identical to box height, or 35px */

  /* PrimeryGrey */
  color: #35414c;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  justify-content: left;

  @media screen and (min-width: 720px) and (max-width: 1139px) {
    font-size: 27px;
    line-height: 37px;
  }
`

export const HitProducts = () => {
  const [hitProducts, setHitProducts] = React.useState([])

  React.useEffect(() => {
    fetchGetHitProducts().then(setHitProducts)
  }, [])

  return (
    <HitProductsSection>
      <SectionHeading>Хиты продаж</SectionHeading>
      <ProductsBlock products={hitProducts ?? []} hit={true}></ProductsBlock>
    </HitProductsSection>
  )
}
