import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { arrowLeft } from '../images'
import { useOnClickOutside } from './useOnClickOutside'

interface LinkProps {
  isActive: boolean
}

const Container = styled.div``

const SortByContainer = styled.div<LinkProps>`
  display: flex;
  align-items: ${({ isActive }) => (isActive ? 'normal' : 'center')};
  justify-content: space-between;
  height: 39px;
  width: 88%;
  position: relative;
  margin-left: 20px;
  padding-left: 15px;
  max-width: 382px;
  background-color: white;
  box-shadow: ${({ isActive }) =>
    isActive
      ? '0px 0px 5px rgba(10, 21, 128, 0.1)'
      : '0px 2px 5px rgba(10, 21, 128, 0.1)'};
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  color: #35414c;
  font-size: 14px;
  line-height: 19px;
  z-index: 10000;
  border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '6px')};
  cursor: pointer;
  @media screen and (min-width: 1440px) {
    margin-left: 30px;
    width: 81%;
  }
  @media screen and (min-width: 410px) and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
    min-width: 66px;
  }
`

const DropDownStart = styled.div`
  padding-top: 9px;

  width: 100%;
`

const DropDownContainer = styled.div<LinkProps>`
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
  height: ${({ isActive }) => (isActive ? 'auto' : '39px')};
  background-color: white;
  width: 100%;

  max-width: 382px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: 15px;
  padding-bottom: 10px;
  top: 100%;
  left: 0;
  z-index: 10000;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);

  @media screen and (min-width: 410px) and (max-width: 767px) {
    max-width: 370px;
  }
`

const Line = styled.div`
  width: 104%;
  margin-top: 10px;
  height: 1px;
  background-color: #f0f4f6;
`

const DropDownItem = styled.div`
  padding-top: 15px;
  z-index: 1;

  :first-child {
    padding-top: 0;
  }
`

const DropDownItemText = styled.a<LinkProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#8B949B' : '#35414C')};
  text-decoration: none;
  :last-child {
  }
`
const DropDownDefault = styled.div``

const Arrow = styled.img<LinkProps>`
  margin-right: 14px;
  /* margin-top: ${({ isActive }) => (isActive ? '4px' : '0')}; */
  transform: ${({ isActive }) => (isActive ? 'rotate(90deg)' : 'rotate(0)')};
`

type QuanityFilter = React.FC<{
  setLimit: Dispatch<SetStateAction<number>>
  limit: number
}>

export const QuanityOfElementsFilter: QuanityFilter = ({ setLimit, limit }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [items] = React.useState([
    { number: 12, key: 1 },
    { number: 24, key: 2 },
    { number: 32, key: 3 },
  ])
  const root = useOnClickOutside(() => setIsOpen(false))

  const isActive = React.useCallback((item) => limit === item.number, [limit])

  const toggleLimit = React.useCallback((item) => setLimit(item.number), [
    setLimit,
    limit,
  ])

  return (
    <Container onClick={root}>
      <SortByContainer onClick={() => setIsOpen(!isOpen)} isActive={!!isOpen}>
        {isOpen ? (
          <DropDownStart>
            {limit}
            <Line></Line>
            <DropDownContainer isActive={!!isOpen}>
              {items?.map((item, i) => (
                <DropDownItem key={i} onClick={() => toggleLimit(item)}>
                  <DropDownItemText isActive={isActive(item)}>
                    {item.number}
                  </DropDownItemText>
                </DropDownItem>
              ))}
            </DropDownContainer>
          </DropDownStart>
        ) : (
          <DropDownDefault>{limit}</DropDownDefault>
        )}
        <Arrow src={arrowLeft} isActive={!!isOpen} />
      </SortByContainer>
    </Container>
  )
}
