import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { fetchGetTopProducts } from '../../../../modules/topProducts/Requestes'
import { ProductsBlock } from '../../../commons/ProductsBlock/ProductsBlock'
import { Link } from '@reach/router'
import { toCatalogArrow } from '../../../images/index'
import { useCategoryContext } from '../../../../context/CategoryContext'
const TopProductsSection = styled.div`
  padding-top: 37px;
`

const SectionHeading = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 130%;
  /* identical to box height, or 35px */

  /* PrimeryGrey */
  color: #35414c;

  justify-content: left;

  @media screen and (min-width: 720px) and (max-width: 1139px) {
    font-size: 27px;
    line-height: 37px;
  }
`

const SectionGroup = styled.div`
  padding-top: 25px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  /* PrimeryGrey */
  color: #35414c;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 720px) and (max-width: 1139px) {
    font-size: 27px;
    line-height: 37px;
  }
  @media screen and (min-width: 320px) and (max-width: 719px) {
    font-size: 21px;
    line-height: 29px;
    max-width: 290px;
  }
`

const ToCatalogue = styled(Link)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  text-align: right;

  /* PrimeryOrange */

  color: #f0640c;
  text-decoration: none;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Arrow = styled.img.attrs({ src: toCatalogArrow })`
  width: 24px;
  height: 20px;
`
export const TopProducts = () => {
  const [top1Products, setTop1Products] = useState([])
  const [top4Products, setTop4Products] = useState([])

  const { setActiveCategoryId } = useCategoryContext()

  useEffect(() => {
    fetchGetTopProducts(1).then(setTop1Products)
  }, [])

  useEffect(() => {
    fetchGetTopProducts(4).then(setTop4Products)
  }, [])

  const selectCategory = React.useCallback((id) => {
    console.log('id :>> ', id)
    setActiveCategoryId(id)
  }, [])

  return (
    <TopProductsSection>
      <SectionHeading>Топ товаров категорий</SectionHeading>
      <SectionGroup>
        <label>Инертные материалы</label>
        <ToCatalogue
          to={`/products`}
          onClick={() => {
            selectCategory(1)
          }}
        >
          Перейти в категорию
          <Arrow></Arrow>
        </ToCatalogue>
      </SectionGroup>
      <ProductsBlock products={top1Products ?? []}></ProductsBlock>
      <SectionGroup>
        <label>ЖБИ</label>
        <ToCatalogue
          to={`/products`}
          onClick={() => {
            selectCategory(4)
          }}
        >
          Перейти в категорию
          <Arrow></Arrow>
        </ToCatalogue>
      </SectionGroup>
      <ProductsBlock products={top4Products ?? []}></ProductsBlock>
    </TopProductsSection>
  )
}
