import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Arrow, Direction } from '../../images'

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding-left: 50px;
  color: #35414C;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  width: 189px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 42px;
    font-size: 12px;
  }

`

const ArrowContainer = styled.div`
  width: 10px;
  margin-left: 5px;
  margin-top: 2px;
`

const OptionsContainer = styled.div`
  position: absolute;
  left: 50px;
  z-index: 999999999999;
  background-color: white;
  top: 100%;
  min-width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    left: 42px;
  }
`

const OptionContainer = styled.div`
  padding: 10px 5px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`


const CitiesDropdown: React.FC<{ cities: [string], selectedCity: string, setSelectedCity: Dispatch<SetStateAction<string>> }> =
  ({ cities, setSelectedCity, selectedCity }) => {

    const [isOpen, setIsOpen] = React.useState(false)

    const renderOptions = () => {
      if (isOpen) return (
        <OptionsContainer>
          {cities
            .filter(city => city !== selectedCity)
            ?.map(city => <OptionContainer key={city} onClick={() => setSelectedCity(city)}>{city}</OptionContainer>)
          }
        </OptionsContainer>
      )
      return null
    }

    return (
      <DropdownWrapper onClick={() => setIsOpen(!isOpen)}>
        {selectedCity}
        {/*@ts-ignore*/}
        <ArrowContainer>
          <Arrow direct={isOpen ? Direction.Up : Direction.Down} />
        </ArrowContainer>
        {renderOptions()}
      </DropdownWrapper>
    )
  }

export default CitiesDropdown