import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'

import { arrowLeft } from '../../../images'
import { Category as CategoryModel } from '../../../../modules/category/store'
import { HeaderCategory } from './HeaderCategory'

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>

const ScrollButton = styled.button`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40px;
  border: none;
  background-color: #fff;
  outline: none;

  &:hover {
    background: #F0F4F6;
  }

  &:last-child {
    left: unset;
    right: 0;
  }

  &:first-child {
    img {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    opacity: 0.3;
  }
`

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <ScrollButton
      onClick={() => {
        scrollPrev()
      }}
      disabled={isFirstItemVisible}>
      <img src={arrowLeft} alt='arrow' />
    </ScrollButton>
  )
}

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    <ScrollButton
      onClick={() => {
        scrollNext()
      }}
      disabled={isLastItemVisible}>
      <img src={arrowLeft} alt='arrow' />
    </ScrollButton>
  )
}


export const CategoriesList: React.FC<{
  categories: CategoryModel[]
  activeCategoryId: number
  selectCategoryHandler: Dispatch<SetStateAction<number>>
}> = ({ categories, activeCategoryId, ...props }) => {

  const scrollApiRef = React.useRef({} as scrollVisibilityApiType)

  const scrollToActiveItem = () => {
    const selectedItem = scrollApiRef.current.getItemById(activeCategoryId.toString())
    scrollApiRef.current.scrollToItem(selectedItem, 'smooth', 'start')
  }

  const renderCategories = () => {
    if (!categories.length) return null
    return (
      <ScrollMenu
        onInit={scrollToActiveItem}
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        options={{
          ratio: 1,
          threshold: 1,
        }}
        wrapperClassName='header-categories-scroll-wrapper'
        scrollContainerClassName='header-categories-scroll-items-container'
        apiRef={scrollApiRef}
      >
        {categories
          ?.map(({ title, id, icon }) => (
            //@ts-ignore
            <HeaderCategory {...props} key={id} id={id} title={title} icon={icon} itemId={id}
                      activeCategoryId={activeCategoryId} />
          ))}
      </ScrollMenu>
    )
  }

  return renderCategories()
}
