import React from 'react'
import { Field, Form as FormFinal } from 'react-final-form'
import { RequestsAuthorization } from '../../../../../modules/authorization'
import {
  Button,
  ButtonBase,
  Error,
  InputTextFinalWithError as InputTextFinalBase,
} from '../../../../commons'
import { Container, Label, Title } from '../commons'

import styled from 'styled-components'
import { FORM_ERROR } from 'final-form'
import { useAtom, useAction } from '@reatom/react'
import { popUp, setPopUp } from '../../../../../modules/basket/store'

const ButtonAgainSendCode = styled(ButtonBase)`
  height: 38px;
  margin-top: 15px;

  background: #ffffff;
  border-radius: 6px;
  transition: 225ms;
  &:hover {
    box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
  }

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #8b949b;
`

const ButtonSubmit = styled(Button)`
  margin-top: 5px;
`

const InputTextFinal = styled(InputTextFinalBase)`
  margin-top: 8px;
  text-align: center;
`

export type SmsConfirmData =
  | {
      isBack: boolean
    }
  | undefined

type StepSmsConfirmFC = React.FC<{
  phone: string
  nextStep: (data?: SmsConfirmData) => void
  onRequestClose: () => void
  smsConfirm: (body: any) => Promise<any>
}>

export const StepSmsConfirm: StepSmsConfirmFC = ({
  phone,
  nextStep,
  onRequestClose,
  smsConfirm,
}) => {
  const prevValue = React.useRef('')
  // const showPopUp = useAction(setPopUp)

  // const test = () => {
  //   // @ts-ignore
  //   showPopUp(true)
  // }

  return (
    <>
      <FormFinal
        onSubmit={(body) =>
          smsConfirm(body).then(({ status }) => {
            if (status === 400) {
              return { [FORM_ERROR]: 'Ошибочный код' }
            }
            nextStep()
          })
        }
        initialValues={{ phone, code: '' }}
        render={({ handleSubmit, submitting, submitError }) => (
          // @ts-ignore
          <Container
            // @ts-ignore
            onSubmit={handleSubmit}
            onBack={() => nextStep({ isBack: true })}
            onRequestClose={onRequestClose}
          >
            <Title>Введите код из SMS</Title>
            <Label>
              Код
              <Field
                name="code"
                placeholder="Код"
                type="password"
                validate={(value: string) =>
                  value.length < 4 ? 'Введите 4 цифры кода из sms' : ''
                }
                // @ts-ignore
                parse={(x: string = '') => {
                  const number = Number(x)
                  if (
                    Number.isNaN(number) ||
                    number < 0 ||
                    parseInt(String(number)) !== number ||
                    x.includes('.') ||
                    x.length > 4
                  ) {
                    return prevValue.current
                  } else {
                    prevValue.current = x
                    return x
                  }
                }}
                component={InputTextFinal}
              />
              <Error>{submitError}</Error>
            </Label>
            <ButtonSubmit
              type="submit"
              disabled={submitting}
              // onClick={() => test()}
            >
              Войти
            </ButtonSubmit>
            <ButtonAgainSendCode
              onClick={() =>
                RequestsAuthorization.sendPin({
                  phone,
                })
              }
            >
              Отправить код повторно
            </ButtonAgainSendCode>
          </Container>
        )}
      />
    </>
  )
}
