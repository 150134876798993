import queryString from 'query-string'
import { Product } from './store'
const normalizeMeasurement = (measurement: string): string => {
  switch (measurement) {
    case 'м2':
      return 'м²'
    case 'м3':
      return 'м³'
    default:
      return measurement
  }
}

const productMapper = ({
  id,
  title,
  price,
  files: [{ uri: image = '' } = {}] = [],
  description,
  measurement,
  minCount,
  count,
  orderDescription,
  discount,
  multiplicity,
  categoryTop,
  subcategoryTop,
  withVAT,
  standard,
  standardFile,
  isHitSale,
}: any): Product => {
  return {
    id: String(id),
    title,
    price: Number(price),
    image,
    description,
    orderDescription,
    measurement: normalizeMeasurement(measurement),
    minCount: Number(minCount),
    count: Number(count),
    discount: Number(discount),
    multiplicity,
    categoryTop,
    subcategoryTop,
    withVAT,
    standard,
    standardFile,
    isHitSale,
    // @ts-ignore
  }
}
export const fetchGetProduct = (
  id: Product['id'],
  city: string,
  baseUrl: string = '',
) =>
  fetch(`${baseUrl}/api/v1/products/${id}/?city=${city}`)
    .then((res) => res.json())
    .then(productMapper)

export type Price = {
  min: number
  max: number
}

type GetProductsQuery = {
  limit?: number
  offset?: number
  prices?: Price
  categoryIds?: number[]
  sortKey?: string
  sortDirection?: string
  city?: string
}

type GetProductsQueryServer = {
  page?: number
  countOnPage?: number
  categories?: number[]
  priceRange?: number[]
  sortKey?: string
  sortDirection?: string
  city?: string
}

type Pagination = { total: number; products: Product[] }

export const fetchGetProducts = (
  query: GetProductsQuery,
  options?: RequestInit,
  baseUrl: string = '',
): Promise<Pagination> => {
  const newQuery: GetProductsQueryServer = {}
  if (typeof query.offset === 'number' && typeof query.limit === 'number') {
    newQuery.page = Math.floor(query.offset / query.limit)
  }
  if (typeof query.limit === 'number') {
    newQuery.countOnPage = query.limit
  }

  newQuery.categories = query.categoryIds
  if (query.prices) {
    newQuery.priceRange = [query.prices.min, query.prices.max]
  }
  newQuery.sortKey = query.sortKey
  newQuery.sortDirection = query.sortDirection

  newQuery.city = query.city || 'Краснодар'

  return fetch(
    `${baseUrl}/api/v1/products/?${queryString.stringify(newQuery, {
      arrayFormat: 'bracket',
    })}`,
    options,
  )
    .then((res) => res.json())

    .then(({ total, results }) => ({
      total,
      products: results?.map(productMapper),
    }))
}

export const fetchGetPriceRange = (categories: number[]) => {
  return fetch(
    `api/v1/products/pricesRange${
      categories
        ? '?' +
          queryString.stringify({ categories }, { arrayFormat: 'bracket' })
        : ''
    }`,
  ).then((res) => res.json())
}

export const fetchGetCities = (baseUrl: string = '') =>
  fetch(`${baseUrl}/api/v1/products/city/list`).then((res) => res.json())
