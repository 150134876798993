import React from 'react'
import styled, { css } from 'styled-components'
import { FieldRenderProps } from 'react-final-form'
import addDays from 'date-fns/fp/addDays'
import parse from 'date-fns/fp/parse'
import isEqual from 'date-fns/fp/isEqual'
import isAfter from 'date-fns/fp/isAfter'
import isBefore from 'date-fns/fp/isBefore'
import isToday from 'date-fns/isToday'
import getDay from 'date-fns/getDay'
import moment from 'moment'
import { NewCalendar } from '../../Basket/Form/NewCalendar'
import { NewCalendarRange } from '../../Basket/Form/NewCalendarRange'
import { Calendar as CalendarBase } from '../../../commons/Inpunts'
import {
  Button,
  ButtonBase,
  ButtonWhite as ButtonWhiteBase,
  ModalDark,
} from '../../../commons'
import { format, ltTablet } from '../../../../utils'
import { calendar } from '../../../images'

type isActive = {
  isActive: boolean
}

const Container = styled.div`
  width: 100%;
  width: 300px;
  height: 100%;
  max-height: 707px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 600px) {
    width: 450px;
    top: 50px;
  }
`

const ContainerRange = styled.div`
  width: 100%;
  width: 300px;
  height: 100%;
  max-height: 707px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    width: 450px;
    top: 50px;
  }
`

const Wrapper = styled.div`
  height: calc(100% - 40px);
  max-height: 550px;
  width: 300px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.12);
  border-radius: 10px;
  @media screen and (min-width: 600px) {
    width: 100%;
    min-width: 450px;
  }
`

const CalendarStyle = styled(CalendarBase)`
  margin-bottom: 18px;
`

const Title = styled.div`
  margin-bottom: 18px;
  text-align: center;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #35414c;
  padding-top: 20px;
`

const ContainerTime = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 140px);
  grid-template-rows: repeat(3, 36px);
`

const Time = styled(ButtonBase)<{ active?: boolean }>`
  height: 36px;
  width: 100%;
  margin: auto;

  border-radius: 6px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 15px;
  color: rgb(53, 65, 76, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) =>
    active
      ? css`
          background-color: rgb(240, 100, 12, 0.7);
          color: white;
        `
      : css`
          &:hover {
            transition: color, background-color 225ms;

            height: 30px;

            box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
          }
        `}
  ${(props) =>
    //@ts-ignore
    props.disabled &&
    css`
      color: #8b949b;
      cursor: initial;
      box-shadow: initial;
    `}
  &:disabled {
    cursor: initial;
    color: rgb(139, 148, 155, 0.5);
    box-shadow: initial;
  }
`

const ButtonAgree = styled(Button)`
  width: 100%;
`

const IconCalendar = styled.img`
  height: 14px;
  position: absolute;
  right: 15px;
  bottom: 12px;
`

const CloseText = styled.div`
  margin: 10px 0;

  cursor: pointer;
  font-family: 'Open Sans';
  font-size: 14px;
  text-align: center;
  color: #ffffff;

  @media screen and (min-width: 600px) {
  }
`

const Body = styled.div`
  padding: 10px 20px 6px;

  flex: 1;

  overflow-y: auto;

  @media screen and (min-width: 600px) {
    padding: 18px 28px 12px;
  }
`

const Footer = styled.div`
  padding: 10px 30px 20px;

  border-top: 1px solid #ecf1f4;
`

const DateShow = styled.div`
  margin-bottom: 11px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #35414c;
`

const CalendarInput = styled.input<isActive>`
  width: 300px;
  height: 38px;
  background: rgba(236, 241, 244, 0.9);
  border-radius: 6px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  padding-left: 15px;
  /* border-color: ${({ isActive }) => (isActive ? '#F06060;' : '')}; */

  @media screen and (min-width: 400px) {
    width: 375px;
  }
`

const InputContainer = styled.div`
  width: 300px;

  position: relative;
  display: flex;
  align-items: center;

  @media screen and (min-width: 400px) {
    width: 375px;
  }
`
const Error = styled.div<isActive>`
  height: 13px;
  margin-top: 3px;
  text-align: left;
  color: #f06060;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 2px;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const createTime = (time: string, date = new Date()) =>
  parse(date)('kk:mm')(time)

const addDay = addDays(1)
const formatTimeBase = format('kk:mm')
const dateFormat = format('dd MMMM')
export const formatDate = (start: Date) => `до ${formatTimeBase(start)} `

type CalendarFC = React.FC<FieldRenderProps<string> & { className?: string }>
export const Calendar: CalendarFC = ({
  input: { value: formValue, onChange, onBlur },
  meta: { error, touched, submitFailed, active, invalid },
  className,
  calendarStartValue,
  setCalendarStartValue,
  activeDeliveryRange,
  startRangeDay,
  setStartRangeDay,
  endRangeDay,
  setEndRangeDay,
  weekend,
  setRangePeriodLength,
  setIsOrderCountUnacceptable,
  timeSchedule,
}) => {
  let value = React.useMemo(() => {
    if (formValue && formValue !== '__.__.____') {
      // @ts-ignore
      return new Date(formValue)
    } else {
      let date = new Date()
      if (
        isAfter(createTime('11:00', date))(date) ||
        +createTime('11:00', date) === +date
      ) {
        date = addDay(date)
        return createTime('11:00', date)
      }
      return createTime('18:00', date)
    }
  }, [formValue])

  const [date, setDate] = React.useState(value)
  const [isShowCalendar, setIsShowCalendar] = React.useState(false)
  const [counter, setCounter] = React.useState(1)
  const [inputValue, setInputValue] = React.useState('')
  const [selectedDayTime, setSelectedDayTime] = React.useState('')
  const [time, setTime] = React.useState([
    { to: '11:00', disabled: false },
    { to: '14:00', disabled: false },
    { to: '18:00', disabled: false },
  ])

  React.useEffect(() => {
    if (weekend.includes(moment(value).format('MM/DD/YYYY'))) {
      //@ts-ignore
      value = new Date(moment(value).add(1, 'days'))
      setDate(value)
    }
  }, [weekend, value])

  React.useEffect(() => {
    //@ts-ignore
    timeSchedule?.workingTime?.map((day) => {
      if (day.date == moment(date).format('YYYY-MM-DD')) {
        setSelectedDayTime(day.to)
      }
    })
  }, [date, timeSchedule])

  React.useEffect(
    () => () => {
      if (isShowCalendar === true) {
        onBlur()
      }
    },
    [isShowCalendar, onBlur],
  )
  const isShowAllTime = !(
    isToday(date) &&
    isAfter(createTime('24:00'))(new Date()) &&
    isBefore(createTime(`11:00`))(new Date())
  )

  //@ts-ignore
  const onChangeForRange = (newDate) => {
    if (counter % 2 !== 0) {
      setStartRangeDay(moment(newDate).format('YYYY/MM/DD'))
      setCounter(counter + 1)
      setEndRangeDay('')
      setDate(newDate)
    } else if (counter % 2 == 0) {
      setEndRangeDay(moment(newDate).format('YYYY/MM/DD'))
      setCounter(counter + 1)
      setDate(newDate)
    }
  }

  React.useEffect(() => {
    if (
      moment(endRangeDay).format('MM/DD/YYYY') ==
        moment(startRangeDay).format('MM/DD/YYYY') &&
      endRangeDay !== ''
    ) {
      setEndRangeDay('')
      setCounter(2)
    }
    if (weekend.includes(moment(startRangeDay).format('MM/DD/YYYY'))) {
      setStartRangeDay('')
      setCounter(1)
    }
  }, [endRangeDay, startRangeDay])

  return (
    <>
      {!activeDeliveryRange && (
        <InputContainer onClick={() => setIsShowCalendar(true)}>
          <CalendarInput
            isActive={error}
            placeholder="__.__.____" //@ts-ignore
            value={inputValue}
          />
          <IconCalendar src={calendar} alt="calendar" />
        </InputContainer>
      )}
      {activeDeliveryRange && (
        <ContainerRange>
          <NewCalendarRange // @ts-ignore
            weekend={weekend}
            value={date}
            disabled={(dateX) => {
              return (
                isBefore(createTime('24:00'))(dateX) ||
                +createTime('24:00') === +dateX ||
                (isToday(dateX) &&
                  (isAfter(createTime('11:00'))(new Date()) ||
                    +createTime('11:00') === +new Date()) &&
                  isBefore(createTime(`23:59`))(new Date()))
              )
            }} //@ts-ignore
            onChange={(newDate) => {
              onChangeForRange(newDate) //@ts-ignore
              // test(newDate)
            }}
            calendarStartValue={calendarStartValue}
            setCalendarStartValue={setCalendarStartValue}
            startRangeDay={startRangeDay}
            setStartRangeDay={setStartRangeDay}
            endRangeDay={endRangeDay}
            setEndRangeDay={setEndRangeDay}
            counter={counter}
            setCounter={setCounter}
            setRangePeriodLength={setRangePeriodLength}
          />
        </ContainerRange>
      )}
      <ModalDark visible={isShowCalendar} onRequestClose={setIsShowCalendar}>
        <Container>
          <Wrapper>
            <Body>
              <NewCalendar // @ts-ignore
                weekend={weekend}
                value={date}
                disabled={(dateX) => {
                  return (
                    isBefore(createTime('24:00'))(dateX) ||
                    +createTime('24:00') === +dateX ||
                    (isToday(dateX) &&
                      (isAfter(createTime('11:00'))(new Date()) ||
                        +createTime('11:00') === +new Date()) &&
                      isBefore(createTime(`23:59`))(new Date()))
                  )
                }} //@ts-ignore
                onChange={(newDate) => {
                  setDate(
                    createTime(isToday(newDate) ? '18:00' : '11:00', newDate),
                  )
                }}
                calendarStartValue={calendarStartValue}
                setCalendarStartValue={setCalendarStartValue}
              />
              <Title>Время доставки</Title>
              <ContainerTime>
                {isShowAllTime && (
                  <Time
                    active={isEqual(createTime('11:00', date))(date)}
                    onClick={() => setDate(createTime('11:00', date))} //@ts-ignore
                  >
                    до 11:00
                  </Time>
                )}
                {isShowAllTime && (
                  <Time
                    active={isEqual(createTime('14:00', date))(date)}
                    onClick={() => setDate(createTime('14:00', date))} //@ts-ignore
                  >
                    до 14:00
                  </Time>
                )}
                <Time
                  active={isEqual(createTime('18:00', date))(date)}
                  onClick={() => setDate(createTime('18:00', date))}
                >
                  до 18:00
                </Time>
              </ContainerTime>
            </Body>
            <Footer>
              {/* <DateShow>{formatDate(date)}</DateShow> */}
              <ButtonAgree
                onClick={() => {
                  onChange(date)
                  setIsShowCalendar(false)
                  setInputValue(moment(date).format('DD.MM.YYYY'))
                }}
              >
                Подтвердить
              </ButtonAgree>
            </Footer>
          </Wrapper>
          <CloseText onClick={() => setIsShowCalendar(false)}>
            Закрыть
          </CloseText>
        </Container>
      </ModalDark>
    </>
  )
}
