import { combine, createStore as createStoreReatom } from '@reatom/core'

import { basket, deliveries } from './basket'
import { product } from './product'

const rootReduce = combine({
  basket,
  deliveries,
  product,
})

const NameLocalStorageReatom = 'prostore'

export const createStore = (initState: any) => {
  const store = createStoreReatom(rootReduce, {
    ...JSON.parse(localStorage.getItem(NameLocalStorageReatom) ?? '{}'),
    ...initState,
  })

  store.subscribe((_, store) => {
    localStorage.setItem(
      NameLocalStorageReatom,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(NameLocalStorageReatom) ?? '{}'),
        ...store,
      }),
    )
  })

  return store
}
