export const isAuthorization = (): boolean =>
  !document?.cookie
    ?.split(';')
    ?.map((str) => str.split('='))
    .every(([key]) => encodeURIComponent(key.trim()) !== 'token')

export const useIsAuthorization = (): boolean => {
  return isAuthorization()
}

export const logOut = () => {
  document.cookie = 'token=; max-age=-1; accessToken=;'
}
