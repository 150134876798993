import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import InputMask from 'react-input-mask'

const StyledTextInput = styled.input`
  padding: 10px 15px 6px 15px;
  background: #ffffff;
  border-width: 4px;
  border-color: transparent;
  border-radius: 2px;
  border: 1px solid #dbdfe5;
  elevation: 2;
  color: #8b949b;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(2, 9, 75, 0.12);
  outline: none;
`

const Container = styled.div`
  margin-left: 25px;
  width: 175px;
  max-height: 38px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(2, 9, 75, 0.12);
  border: 1px solid #dbdfe5;
`

const InputEndDay = ({
  setEndRangeDay,
  endRangeDay,
  checkedRange,
  weekend,
}) => {
  const [showInit, setShowInit] = React.useState(true)

  const [inputValue, setInputValue] = React.useState('')

  const changeInputValue = (value) => {
    setInputValue(value)
  }

  const onBlurValue = (inputValue) => {
    setEndRangeDay(moment(inputValue, 'DD-MM-YYYY').format('YYYY-MM-DD'))
  }

  React.useEffect(() => {
    setInputValue(moment(endRangeDay).format('DD-MM-YYYY'))
  }, [endRangeDay])

  React.useEffect(() => {
    if (weekend.includes(moment(endRangeDay).format('MM/DD/YYYY'))) {
      setEndRangeDay('')
    }
  }, [endRangeDay, weekend])

  return (
    <>
      <Container>
        <InputMask
          value={inputValue}
          onChange={(event) => changeInputValue(event.target.value)}
          mask="99.99.9999"
          maskPlaceholder="__.__.____"
          onBlur={() => onBlurValue(inputValue)}
        >
          <StyledTextInput />
        </InputMask>
      </Container>
    </>
  )
}

export default InputEndDay
