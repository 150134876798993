import * as React from 'react'
import styled from 'styled-components'
import FilterListItem from './FilterListItem'
import { PriceRangeInputs } from '../../commons/PriceRangeInputs'
import RangeSlider from '../../commons/RangeSlider'
import FilterMaterial from './FilterMaterial'
import { Calendar } from '../../commons/Inpunts'
import FilterSelect from './FilterSelect'
// в ts поменять id и htmlFor с string на string | number

const Container = styled.div<{ desktopOnly: boolean }>`
  position: sticky;
  top: 60px;
  margin-left: 30px;
  margin-right: 45px;
  display: flex;
  flex-direction: column;
  width: 305px;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-top: 10px;


  @media screen and (max-width: 767px) {
    position: static;
    display: ${({ desktopOnly }) => (desktopOnly ? 'none' : 'flex')};;
    width: 100%;
    margin: 0;
    box-shadow: none;
    padding-bottom: 60px;
  }
`
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f4f6;
  font-weight: 600;
`

const FilterHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterHeaderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  padding-right: 17px;
  color: #495560;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
const SliderContainer = styled.div`
  height: 50px;
  margin-left: -20px;
  margin-right: -20px;
`


//@ts-ignore
export const Filters = ({ selectedFiltersValues, filterValues, setSelectedFilterValues, desktopOnly = false }) => {
  // just test
  const { prices } = filterValues
  const localInputValueMin = selectedFiltersValues?.prices?.min
  const localInputValueMax = selectedFiltersValues?.prices?.max
  const materials = filterValues.material
  const selectedMaterials = selectedFiltersValues.material
  const addressList = filterValues.address

  const setMaxFilterPrice = () => {
  }
  const setMinFilterPrice = () => {
  }

  const setSelectedPrices = (min: number, max: number) => {
    setSelectedFilterValues({ ...selectedFiltersValues, prices: { min, max } })
  }

  const setMinSelectedPrice = (min: number) => {
    setSelectedPrices(min, localInputValueMax)
  }

  const setMaxSelectedPrice = (max: number) => {
    setSelectedPrices(localInputValueMin, max)
  }


  const onChangeMaterial = (m: string) => {
    let material = [...selectedFiltersValues.material]
    if (material.includes(m)) {
      material = material.filter((el) => el !== m)
    } else {
      material.push(m)
    }
    setSelectedFilterValues({ ...selectedFiltersValues, material })
  }

  const onChangeDate = (value: Date | null) => {
    setSelectedFilterValues({ ...selectedFiltersValues, date: value ? [value] : [] })
  }

  const onChangeAddress = (value: string | null) => {
    setSelectedFilterValues({ ...selectedFiltersValues, address: value ? [value] : [] })
  }

  return (
    <Container desktopOnly={desktopOnly}>
      <FilterHeader>
        <FilterHeaderLeft>
          <FilterHeaderText>Фильтрация</FilterHeaderText>
        </FilterHeaderLeft>
      </FilterHeader>
      <FilterListItem name='Цена' onReset={() => {
        setSelectedPrices(prices.min, prices.max)
      }}>
        <PriceContainer>
          {/*//@ts-ignore*/}
          <PriceRangeInputs
            prices={prices}
            localInputValueMin={localInputValueMin}
            localInputValueMax={localInputValueMax}
            setMaxFilterPrice={setMaxFilterPrice}
            setMinFilterPrice={setMinFilterPrice}
            //@ts-ignore
            setLocalInputValueMin={setMinSelectedPrice}
            //@ts-ignore
            setLocalInputValueMax={setMaxSelectedPrice}
          />
          {/*//@ts-ignore*/}
          <SliderContainer>
            <RangeSlider
              priceInputValue={prices}
              setPriceInputValue={() => {
              }}
              prices={prices}
              localInputValueMin={localInputValueMin}
              localInputValueMax={localInputValueMax}
              //@ts-ignore
              setMaxFilterPrice={setMaxSelectedPrice}
              //@ts-ignore
              setMinFilterPrice={setMinSelectedPrice}
              //@ts-ignore
              setLocalInputValueMin={setMinFilterPrice}
              //@ts-ignore
              setLocalInputValueMax={setMaxFilterPrice}
            />
          </SliderContainer>
        </PriceContainer>
      </FilterListItem>
      <FilterListItem name={'Материалы'} onReset={() => {
        setSelectedFilterValues({ ...selectedFiltersValues, material: [] })
      }}>
        <FilterMaterial
          materials={materials}
          selectedMaterials={selectedMaterials}
          onChangeMaterial={onChangeMaterial}
        />
      </FilterListItem>
      <FilterListItem name={'Дата доставки'} onReset={() => {
        onChangeDate(null)
      }} onOpen={() => onChangeDate(new Date())}>
        <div style={{ marginTop: '10px' }}>
          <Calendar responsive onChange={onChangeDate} value={selectedFiltersValues.date[0]} disabled={() => false} />
        </div>
      </FilterListItem>
      
      <FilterListItem name='Адрес доставки' onReset={() => {onChangeAddress(null)}}>
        <FilterSelect
          addressList={addressList}
          onChange={onChangeAddress}
          value={selectedFiltersValues?.address[0] || ''}
        />
      </FilterListItem>
    </Container>
  )
}
