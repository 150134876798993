import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { ltDesktop, ltTablet } from '../../../utils'
import { ArrowLong, Direction } from '../../images'

const Container = styled.div`
  padding: 15px 0;
  ${ltTablet} {
    padding: 11px 0;
  }

  background: #ffffff;

  border-top: 1px solid #f0f4f6;
  border-bottom: 1px solid #f0f4f6;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  ${ltDesktop} {
    padding: 0 30px;
  }
  ${ltTablet} {
    padding: 0 15px;
  }
`

const Title = styled.div`
  margin-bottom: 15px;
  ${ltDesktop} {
    font-size: 22px;
  }
  ${ltTablet} {
    margin-bottom: 9px;
    font-size: 16px;
  }

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 25px;
  color: #35414c;
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;

  text-decoration: none;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #8b949b;
`

const Arrow = styled(ArrowLong)`
  height: 8px;
  margin-right: 10px;

  stroke: #8b949b;
`

export const Header = () => {
  return (
    <Container>
      <Wrapper>
        <Title>Оформление заказа </Title>
        <BackLink to="/products">
          <Arrow direct={Direction.Left} />
          Назад в каталог
        </BackLink>
      </Wrapper>
    </Container>
  )
}
