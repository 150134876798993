import React from 'react'
import styled from 'styled-components'


interface ButtonProps {
  isActive: boolean
}

interface OrdersSortsButtons {
  selectedSortType: string
  setSelectedSortType: (type: string) => void
}

const Container = styled.div`
  width: 100%;
  padding: 0 20px;
  box-shadow: 0 6px 14px rgba(2, 9, 75, 0.06);
  @media screen and (max-width: 374px) {
    padding: 0 10px;
  }
`

const SortButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 7px;
`

const TitleContainer = styled.div`
  font-family: FuturaMediumC;
  font-size: 14px;;
  line-height: 16px;
  margin: 6px 0;
  font-weight: bold;
`

const SortButton = styled.div<ButtonProps>`
  padding: 7px 10px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => isActive ? '#F0640C' : '#FFFFFF'};
  color: ${({ isActive }) => isActive ? '#FFFFFF' : '#F0640C'};
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  
  &:last-child {
    margin-right: 0;
  }
  
  @media screen and (max-width: 374px) {
    font-size: 12px;
  }
`

type OrdersButtons = React.FC<OrdersSortsButtons>

interface sortTypes {
  key: string
  value: string
}

const OrdersSortButtons: OrdersButtons = ({ selectedSortType, setSelectedSortType }) => {
  const sortTypes: sortTypes[] = [
    { key: 'status', value: 'Статусу' },
    { key: 'price', value: 'Цене' },
    { key: 'address', value: 'Адресу' },
    { key: 'product', value: 'Товару' },
    { key: 'date', value: 'Дате доставки' }]
  return (
    <Container>
      <TitleContainer>Сортировать по:</TitleContainer>
      <SortButtonsContainer>
        {
          sortTypes?.map(({ key, value }) => (
            <SortButton
              isActive={key === selectedSortType}
              onClick={() => setSelectedSortType(key)}
              key={key}
            >
              {value}
            </SortButton>
          ))
        }
      </SortButtonsContainer>
    </Container>
  )
}


export default OrdersSortButtons