import React from 'react'
import { LocationContext, useLocation } from '@reach/router'

import { ModalDark } from '../../../../commons'

import { StepPhone } from './StepPhone'
import { useCloseLogin } from './utils'
import { SmsConfirmData, StepSmsConfirm } from './SmsConfirm'
import { RequestsAuthorization } from '../../../../../modules/authorization'

enum LoginSteps {
  Phone,
  SmsConfirm,
}

function* loginFlow() {
  while (true) {
    yield LoginSteps.Phone
    const { isBack = false } =
      ((yield LoginSteps.SmsConfirm) as SmsConfirmData) ?? {}
    if (!isBack) {
      break
    }
  }
}

type Login = React.FC<LocationContext>
export const Login: Login = () => {
  const flow = React.useRef(loginFlow()).current
  const [currentStep, setCurrentStep] = React.useState(() => flow.next().value)

  const closeLogin = useCloseLogin()
  const nextStep = (data?: any) => {
    const { value, done } = flow.next(data)
    setCurrentStep(value)
    if (done) {
      closeLogin()
    }
  }
  const location = useLocation()
  React.useEffect(()=>{
    //@ts-ignore
    if(location.state.fromUserExistModal){
      setPhone(phone)
      nextStep()
    }
  },[location])
  // @ts-ignore
  const [phone, setPhone] = React.useState(location?.state?.phone ?? '')
  return (
    <ModalDark visible onRequestClose={closeLogin}>
      {LoginSteps.Phone === currentStep && (
        <StepPhone
          phone={phone}
          nextStep={({ phone }) => {
            setPhone(phone)
            nextStep()
          }}
        />
      )}
      {LoginSteps.SmsConfirm === currentStep && (
        <StepSmsConfirm
          smsConfirm={RequestsAuthorization.smsConfirmLogin}
          onRequestClose={closeLogin}
          phone={phone}
          nextStep={nextStep}
        />
      )}
    </ModalDark>
  )
}
