import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { emptyProduct, addButton, hitProductGreen } from '../../images'

const ProductBlock = styled.div`
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;

  max-width: 1386px;
  min-width: 1386px;
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    max-width: 875px;
    min-width: 875px;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    max-width: 737px;
    min-width: 737px;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    max-width: 586px;
    min-width: 586px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 619px) {
    max-width: 290px;
    min-width: 290px;
    flex-wrap: wrap;
  }
`

const Product = styled(Link)`
  position: relative;
  min-width: 269px;
  max-width: 269px;
  height: 325px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  @media screen and (min-width: 1140px) and (max-width: 1440px) {
    min-width: 211px;
    max-width: 211px;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    min-width: 239px;
    max-width: 239px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    min-width: 288px;
    max-width: 288px;
    height: 215px;
  }
  @media screen and (max-width: 619px) {
    min-width: 140px;
    max-width: 140px;
    height: 215px;
  }
`

const Content = styled.div`
  margin-left: 20px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 12px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`
const TopProductTriangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 76px;
  height: 76px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  background-color: #52cf26;
  transform: rotate(90deg);
  z-index: 1;

  &:after {
    content: 'Хит продаж';
    text-align: center;
    color: white;
    position: absolute;
    bottom: 10px;
    right: 20px;
    transform: rotate(-135deg);
    font-size: 12px;
    text-transform: uppercase;
  }
`

const DiscountSizeContainer = styled.div`
  width: 64px;
  height: 32px;
  background-color: #f0640c;
  position: absolute;
  top: 10px;
  right: 10px;
  display: ${({ isDiscount }) => (isDiscount ? 'inline' : 'none')};
`

const DiscountSize = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding-top: 7px;
`

const ImageContainer = styled.img`
  width: 100%;
  height: 145px;
  border-radius: 4px;
  position: relative;

  object-fit: scale-down;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 92px;
  }
`

const Title = styled.div`
  padding-top: 17px;
  padding-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #495560;

  @media screen and (min-width: 768px) and (max-width: 1139px) {
    padding-top: 13px;
    max-width: 179px;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 14px;
    padding-top: 9px;
  }
`

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  max-width: 200px;
  color: #8b949b;
  margin: 0 0 10px;

  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 768px) and (max-width: 1139px) {
    max-width: 177px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Price = styled.div`
  display: flex;
  align-items: center;
`

const PriceText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isDiscount }) => (isDiscount ? '12px' : '18px')};
  line-height: 25px;
  color: ${({ isDiscount }) => (isDiscount ? '#8B949B' : '#35414C')};
  padding-bottom: 5px;
  text-decoration: ${({ isDiscount }) =>
    isDiscount ? 'line-through' : 'none'};
  @media screen and (max-width: 1139px) {
    font-size: ${({ isDiscount }) => (isDiscount ? '8px' : '16px')};
    line-height: ${({ isDiscount }) => (isDiscount ? '11px' : '22px')};
    padding-bottom: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
  }
`

const ObjectSizeText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
  padding-bottom: 5px;
  text-decoration: ${({ isDiscount }) =>
    isDiscount ? 'line-through' : 'none'};
  @media screen and (max-width: 1139px) {
    font-size: ${({ isDiscount }) => (isDiscount ? '8px' : '16px')};
    line-height: ${({ isDiscount }) => (isDiscount ? '11px' : '22px')};
    padding-bottom: 0;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) {
  }
`

const ObjectTypeSizeText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 11px;
  color: #8b949b;
  padding-bottom: 10px;
  text-decoration: ${({ isDiscount }) =>
    isDiscount ? 'line-through' : 'none'};
  @media screen and (max-width: 1139px) {
    font-size: ${({ isDiscount }) => (isDiscount ? '8px' : '16px')};
    line-height: ${({ isDiscount }) => (isDiscount ? '11px' : '22px')};
    padding-bottom: 0;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) {
  }
`

const RubleSymbol = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isDiscount }) => (isDiscount ? '12px' : '18px')};
  line-height: 25px;
  color: ${({ isDiscount }) => (isDiscount ? '#8B949B' : '#35414C')};
  padding-bottom: 5px;
  text-decoration: ${({ isDiscount }) =>
    isDiscount ? 'line-through' : 'none'};
  @media screen and (max-width: 1139px) {
    font-size: ${({ isDiscount }) => (isDiscount ? '8px' : '16px')};
    line-height: ${({ isDiscount }) => (isDiscount ? '11px' : '22px')};
    padding-bottom: 0;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) {
  }
`

const DiscountPrice = styled.div`
  display: ${({ isDiscount }) => (isDiscount ? 'inline' : 'none')};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f0640c;
  padding-left: 5px;
`

const Measurement = styled.div`
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount }) => (isDiscount ? 'none' : 'inline')};
`

const DiscountMeasurement = styled.div`
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount }) => (isDiscount ? 'inline' : 'none')};
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  height: 38px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;

  :hover {
    box-shadow: 0 6px 14px rgba(10, 21, 128, 0.1);
  }

  @media screen and (min-width: 768px) and (max-width: 767px) {
    margin-left: 18px;
  }

  @media screen and (min-width: 0) and (max-width: 767px) {
    width: 92%;
    display: flex;
    align-items: center;
    height: 38px;
    text-decoration: none;
    border-radius: 6px;
    margin-top: 7px;

    margin-left: 13px;
    max-height: 28px;
  }
`

const OrderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #f0640c;
  padding-left: 10px;
  padding-top: 2px;

  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 18px;
  }
`

const AddImage = styled.img.attrs({
  src: addButton,
})`
  height: 19px;
  width: 19px;
  margin-left: 14px;

  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`

const HitProducts = styled.img.attrs({
  src: hitProductGreen,
})`
  display: ${({ hit }) => (hit ? 'flex' : 'none')};
  position: ${({ hit }) => (hit ? 'absolute' : 'none')};
  height: 76px;
  width: 76px;
  top: 0;
  left: 0;
`

export const ProductsBlock = ({ products, hit = false }) => {
  const addDefaultSrc = (ev) => {
    ev.currentTarget.src = emptyProduct
  }
  return (
    <ProductBlock>
      {products?.map((item) => {
        return (
          <Product key={item.id} to={`/basket/${item.id}`} state={item}>
            <ImageWrapper>
              {item.isHitSale && <TopProductTriangle />}
              <DiscountSizeContainer isDiscount={!!item.discount}>
                <DiscountSize>
                  -{Math.round((1 - item.discount / item.price) * 100)}%
                </DiscountSize>
              </DiscountSizeContainer>
              <ImageContainer
                src={item.files[0]?.uri}
                onError={(e) => addDefaultSrc(e)}
              />
            </ImageWrapper>
            <Content>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
              <Price>
                <PriceText>
                  {parseInt(item.price).toLocaleString('ru-RU')}
                </PriceText>
                <RubleSymbol>₽</RubleSymbol>
                <ObjectSizeText>&nbsp;м</ObjectSizeText>
                <ObjectTypeSizeText>3</ObjectTypeSizeText>
              </Price>
            </Content>
            <ButtonContainer>
              <AddImage />
              <OrderText>Заказать</OrderText>
            </ButtonContainer>
            <HitProducts hit={hit} />
          </Product>
        )
      })}
    </ProductBlock>
  )
}
