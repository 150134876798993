import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import {
  mobileFilters,
  closeMenu,
  tableViewIcon,
  listViewIcon,
} from '../images'
import RangeSlider from '../commons/RangeSlider'
import FilterDropDown from '../commons/FilterDropDown'
import { QuanityOfElementsFilter } from './QuanityOfElementsFilter'
import { PriceRangeInputs } from '../commons/PriceRangeInputs'
import { DropFiltersButton } from '../commons/DropFiltersButton'

import { useOnClickOutside } from './useOnClickOutside'

type Prices = {
  max?: number
  min?: number
}

interface LinkProps {
  isActive: boolean
}

type Sorting = {
  sortKey: string
  sortDirection: string
}

const Container = styled.div`
  background-color: white;
  margin-bottom: 12px;
`

const MobileFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
`

const IfOpenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 48px;
  z-index: 100;
  @media screen and (min-width: 410px) and (max-width: 767px) {
    align-items: center;
  }
`

const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (min-width: 410px) and (max-width: 768px) {
    justify-content: center;
  }
`

const HeadSectionLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media screen and (min-width: 410px) and (max-width: 768px) {
    margin-right: 130px;
  }
`

const HeadSectionRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const HeadSectionBorder = styled.div`
  border-bottom: 1px solid #f0f4f6;
  width: 100%;
`

const FilterHeaderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  padding-right: 17px;
  color: #495560;
`

const ChangeView = styled.div<LinkProps>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ isActive }) =>
    isActive ? '0px 2px 5px rgba(10, 21, 128, 0.1)' : 'none'};
`

const CloseMenuContainer = styled.div`
  width: 38px;
  height: 38px;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
`

const SortByContainer = styled.div`
  border-bottom: 1px solid #f0f4f6;
  padding-bottom: 24px;
  position: relative;
  width: 100%;
  @media screen and (min-width: 410px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const SortByTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  padding-left: 20px;
  padding-top: 15px;
  color: #495560;
  font-size: 14px;
  line-height: 19px;
  @media screen and (min-width: 410px) and (max-width: 767px) {
    padding-right: 305px;
  }
`

const PriceRangeName = styled.div`
  font-family: 'Open Sans', sans-serif;
  margin-top: 15px;
  padding-left: 20px;

  color: #495560;
  font-size: 14px;
  line-height: 19px;
  @media screen and (min-width: 410px) and (max-width: 767px) {
    padding-right: 340px;
  }
`

const PriceRangeContainer = styled.div`
  @media screen and (min-width: 410px) and (max-width: 767px) {
    justify-content: center;
  }
`

const PriceFilterContainer = styled.div``

const MobileFilters = styled.img.attrs({ src: mobileFilters })`
  width: 16px;
  height: 16px;
`

const CloseMenu = styled.img.attrs({ src: closeMenu })`
  height: 14px;
  width: 14px;
`

const TableView = styled.img.attrs({
  src: tableViewIcon,
})``

const ListView = styled.img.attrs({
  src: listViewIcon,
})``

type MobileFiltersDropDownType = React.FC<{
  prices: Prices
  priceInputValue: Prices
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  setMaxFilterPrice: Dispatch<SetStateAction<string>>
  setMinFilterPrice: Dispatch<SetStateAction<string>>
  setRowViewCallback: () => void
  setTableViewCallback: () => void
  isTableView: boolean
  setSelectedSortingTypes: Dispatch<SetStateAction<Sorting>>
  selectedSortingTypes: Sorting
  isMobileFiltersOpen: boolean
  setMobileFilters: Dispatch<SetStateAction<boolean>>
  setLimit: Dispatch<SetStateAction<number>>
  limit: number
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
}>

const MobileFiltersDropDown: MobileFiltersDropDownType = ({
  prices,
  priceInputValue,
  setPriceInputValue,
  setMaxFilterPrice,
  setMinFilterPrice,
  setRowViewCallback,
  setTableViewCallback,
  isTableView,
  setSelectedSortingTypes,
  isMobileFiltersOpen: isOpen,
  setMobileFilters: setIsOpen,
  setLimit,
  limit,
  selectedSortingTypes,
  setSelectedSubcategoriesIds,
}) => {
  const root = useOnClickOutside(() => setIsOpen(false))
  const [localInputValueMin, setLocalInputValueMin] = React.useState(0)
  const [localInputValueMax, setLocalInputValueMax] = React.useState(0)

  React.useEffect(() => {
    setPriceInputValue({
      min: prices?.min ? prices?.min : 0,
      max: prices?.max ? prices?.max : 0,
    })
    setLocalInputValueMin(prices?.min ? prices?.min : 0)
    setLocalInputValueMax(prices?.max ? prices?.max : 0)
  }, [prices, setPriceInputValue])

  return (
    <Container onClick={root}>
      <MobileFiltersContainer onClick={() => setIsOpen(!isOpen)}>
        <MobileFilters />
      </MobileFiltersContainer>
      {isOpen ? (
        <IfOpenContainer>
          <DropDownContainer>
            <HeadSectionBorder>
              <HeadSection>
                <HeadSectionLeft>
                  <FilterHeaderText>Вид</FilterHeaderText>

                  <ChangeView
                    onClick={setRowViewCallback}
                    isActive={!isTableView}
                  >
                    <ListView />
                  </ChangeView>
                  <ChangeView
                    onClick={setTableViewCallback}
                    isActive={isTableView}
                  >
                    <TableView />
                  </ChangeView>
                </HeadSectionLeft>
                <HeadSectionRight>
                  <QuanityOfElementsFilter setLimit={setLimit} limit={limit} />
                  <CloseMenuContainer onClick={() => setIsOpen(false)}>
                    <CloseMenu />
                  </CloseMenuContainer>
                </HeadSectionRight>
              </HeadSection>
            </HeadSectionBorder>
            <SortByContainer>
              <SortByTitle>Сортировка</SortByTitle>
              <FilterDropDown
                setSelectedSortingTypes={setSelectedSortingTypes}
                prices={prices}
                selectedSortingTypes={selectedSortingTypes}
              />
            </SortByContainer>
            <PriceRangeName>Цена</PriceRangeName>
            <PriceFilterContainer>
              <PriceRangeContainer>
                <PriceRangeInputs
                  priceInputValue={priceInputValue}
                  setPriceInputValue={setPriceInputValue}
                  prices={prices}
                  localInputValueMin={localInputValueMin}
                  localInputValueMax={localInputValueMax}
                  setMaxFilterPrice={setMaxFilterPrice}
                  setMinFilterPrice={setMinFilterPrice}
                  setLocalInputValueMin={setLocalInputValueMin}
                  setLocalInputValueMax={setLocalInputValueMax}
                />
              </PriceRangeContainer>
              <RangeSlider
                priceInputValue={priceInputValue}
                setPriceInputValue={setPriceInputValue}
                prices={prices}
                localInputValueMin={localInputValueMin}
                localInputValueMax={localInputValueMax}
                setMaxFilterPrice={setMaxFilterPrice}
                setMinFilterPrice={setMinFilterPrice}
                setLocalInputValueMin={setLocalInputValueMin}
                setLocalInputValueMax={setLocalInputValueMax}
              />
            </PriceFilterContainer>

            <DropFiltersButton
              setLocalInputValueMin={setLocalInputValueMin}
              setLocalInputValueMax={setLocalInputValueMax}
              setSelectedSortingTypes={setSelectedSortingTypes}
              setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
              setLimit={setLimit}
              setPriceInputValue={setPriceInputValue}
              prices={prices}
            />
          </DropDownContainer>
        </IfOpenContainer>
      ) : null}
    </Container>
  )
}

export default MobileFiltersDropDown
