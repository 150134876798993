import React from 'react'

export const useOnClickOutside = (
  f: () => void,
): ((event: React.SyntheticEvent) => void) => {
  const isClickedContainer = React.useRef(false)
  const state = React.useRef(f)
  React.useEffect(() => {
    state.current = f
  })
  React.useEffect(() => {
    const handleOnClick = () => {
      if (!isClickedContainer.current) {
        state.current()
      }
      isClickedContainer.current = false
    }
    window.addEventListener('click', handleOnClick)
    return () => {
      window.removeEventListener('click', handleOnClick)
      isClickedContainer.current = false
    }
  }, [])
  return () => {
    isClickedContainer.current = true
  }
}
