import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import ProfileDropDown from '../../../commons/ProfileDropDown'
import HeaderDrawer from '../../../commons/HeaderDrawer'
import { Link } from '@reach/router'
import { useAtom } from '@reatom/react'
import { items } from '../../../../modules/basket'
import { useOnClickOutside } from '../../../commons'
import { HeaderInput } from '../../../commons/HeaderInput'
import { Basket, searchHeader, karamidaLogo, headerLogo } from '../../../images'
import CitiesDropdown from '../CitiesDropdown'
import ContactsButton from '../../../commons/ContactsButton'

type Quanity = {
  quanity: number
}

type MobileInput = {
  mobileInput: boolean
}

// type CategoriesDisplay = {
//   CategoriesDisplay: boolean
// }

const Container = styled.header`
  background-color: white;
  display: flex;
  flex-direction: column;
`
const Wrapper = styled.div`
  height: 75px;
  width: 100%;
  margin: 0 0;

  @media screen and (max-width: 767px) {
    height: 45px;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1440px) {
    justify-content: center;
  }
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    justify-content: center;
  }
  @media screen and (min-width: 620px) and (max-width: 1139px) {
    justify-content: center;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 1440px) {
    margin-left: 36px;
  }
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    margin-left: 30px;
  }

  @media screen and (min-width: 768px) and (max-width: 919px) {
    margin-left: 30px;

  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 25px;
  }
`

const LogoContainer = styled(Link)<MobileInput>`
  display: ${({ mobileInput }) => (mobileInput ? 'none' : 'flex')};
  align-items: center;
`

const RightSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageNumberContainer = styled.div`
  flex-direction: row;
  display: flex;
  padding-left: 30px;

  @media screen and (min-width: 620px) and (max-width: 767px) {
    padding-left: 15px;
    max-width: 180px;
  }

  @media screen and (max-width: 619px) {
    display: none;
  }
`

const ProstoreTitleHeader = styled.img.attrs({ src: karamidaLogo })`
  width: 140px;
  height: 26px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 85px;
    height: 17.61px;
  }
`

const HeaderLogo = styled.img.attrs({ src: headerLogo })`
  margin-right: 8px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: 17.61px;
  }
`

const BasketContainer = styled(Link)`
  display: flex;
  position: relative;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  margin-left: 19px;
  @media screen and (min-width: 620px) and (max-width: 767px) {
    margin-left: 0;
  }
  @media screen and (max-width: 619px) {
    display: none;
  }
`

const ProfileContainer = styled.div`
  margin-left: 35px;
  @media screen and (min-width: 620px) and (max-width: 767px) {
    margin-left: 15px;
  }
`

const BasketImage = styled(Basket)`
  width: 20px;
  height: 20px;
  stroke: #35414c;
`
const BasketText = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #35414c;
`

const SearchHeader = styled.img.attrs({ src: searchHeader })<MobileInput>`
  display: ${({ mobileInput }) => (mobileInput ? 'none' : 'inline')};

  width: 15px;
  height: 15px;
  margin-right: 15px;
  @media screen and (min-width: 620px) {
    display: none;
  }
`
const BasketQuanity = styled.div<Quanity>`
  position: absolute;
  display: ${({ quanity }) => (quanity ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: -6px;
  right: -1px;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
  background-color: #f0640c;
  width: 16px;
  height: 16px;
  border-radius: 15px;
`

const InputContainer = styled.div`
  @media screen and (min-width: 1440px) {
    min-width: 660px;
    margin-left: 219px;
  }

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    margin-left: 30px;
    min-width: 580px;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    width: 41%;
    margin-left: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 919px) {
    margin-left: 30px;
    max-width: 817px;
    width: 38%;
  }

  @media screen and (min-width: 620px) and (max-width: 767px) {
    width: 38%;

    margin-left: 30px;
  }

  @media screen and (max-width: 619px) {
    display: none;
  }
`

const MobileInputContainer = styled.div<MobileInput>`
  display: ${({ mobileInput }) => (mobileInput ? 'flex' : 'none')};
  width: 100%;
  @media screen and (min-width: 619px) {
    display: none;
  }
`

// @ts-ignore

type ProductsHeader = React.FC<RouteComponentProps & { cities: [string], selectedCity: string, setSelectedCity: Dispatch<SetStateAction<string>> }>

export const ProductsHeader: ProductsHeader = ({ cities, selectedCity, setSelectedCity }) => {
  // const status = useAtom(contract)
  // const popUpStatus = useAtom(popUp)
  const products = useAtom(items)
  // const isAuthorized = useIsAuthorization()
  // const setStatus = useAction(setSignedContract)
  // const [contractStatus, setContractStatus] = React.useState(true)
  // const [isOpen, setIsOpen] = React.useState(false)
  const [mobileInput, setMobileInput] = React.useState(false)

  // @ts-ignore
  // React.useEffect(() => {
  //   if (isAuthorized) {
  //     fetchGetContract().then((data) => setContractStatus(data.signedContract))
  //   }
  // }, [isAuthorized, status, popUpStatus])

  // React.useEffect(() => {
  //   setStatus(
  //     // @ts-ignore
  //     contractStatus,
  //   )
  // }, [contractStatus])

  // React.useEffect(() => {
  //   if (isAuthorized && status === false && popUpStatus === true) {
  //     setIsOpen(true)
  //   }
  // }, [isAuthorized, status, popUpStatus])

  const root = useOnClickOutside(() => setMobileInput(false))

  return (
    <Container onClick={root}>
      <Wrapper>
        <MobileInputContainer mobileInput={mobileInput}>
          <HeaderInput />
        </MobileInputContainer>
        <ColumnContainer>
          <RowContainer>
            <HeaderDrawer mobileInput={mobileInput} />
            <LogoWrapper>
              <LogoContainer to={`/main-page`} mobileInput={mobileInput}>
                <HeaderLogo />
                <ProstoreTitleHeader />
              </LogoContainer>
              <CitiesDropdown cities={cities} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
            </LogoWrapper>
            {/* {isOpen && <ModalPopUp setIsOpen={setIsOpen} isOpen={isOpen} />} */}
            <InputContainer>
              <HeaderInput />
            </InputContainer>
            <RightSectionContainer>

              <ImageNumberContainer>
                <ContactsButton />
              </ImageNumberContainer>
              <BasketContainer to='/baskets'>
                <BasketQuanity quanity={products.length}>
                  {products.length}
                </BasketQuanity>
                <BasketImage />
                <BasketText>Корзина</BasketText>
              </BasketContainer>
              <ProfileContainer>
                <ProfileDropDown />
              </ProfileContainer>

              <SearchHeader
                onClick={() => setMobileInput(true)}
                mobileInput={mobileInput}
              />
            </RightSectionContainer>
          </RowContainer>
        </ColumnContainer>
      </Wrapper>
    </Container>
  )
}
