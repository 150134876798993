import React from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../../commons'
import { ProductsLogicTypes, ProductsLogicTypesCut } from '../../../../modules/product'

// @ts-ignore

import Headroom from 'react-headroom'
import { HeaderCategoriesItem } from './HeaderCategoriesItem'
import { useAction } from '@reatom/react'
import { HeaderContainer, HeaderWrapper } from '../../../commons/HeaderWrapper'


const Title = styled.div`
  font-family: 'Open Sans',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  /* PrimeryGrey */
  color: #35414C;


  font-size: 16px;
  @media screen and (max-width: 720px) {
    font-size: 16px;
  }
`
const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
`
const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    overflow-x: scroll;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1px;
    }
  }
`
export const HeaderCategories: React.FC<ProductsLogicTypesCut> = ({
  setActiveCategoryId,
  setSelectedSubcategoriesIds,
  currentCatalogue
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const root = useOnClickOutside(() => setIsOpen(false))

  const selectCategoryHandler = React.useCallback(
    (id: number) => {
      setActiveCategoryId(id)
      setSelectedSubcategoriesIds([id])
    },
    [setActiveCategoryId, setSelectedSubcategoriesIds],
  )
  const items = [{ title: 'Каталог', id: 0, subIds: [] }, ...currentCatalogue]
  const title = items[items.length - 1].title
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <ColumnContainer>
          <Title>{title}</Title>
          <RowContainer>
            {items
              .map((item) => (
                <HeaderCategoriesItem key={item.id}
                                      item={item}
                                      selectCategoryHandler={selectCategoryHandler}
                />
              ))}
          </RowContainer>
        </ColumnContainer>
      </HeaderContainer>
    </HeaderWrapper>
  )
}
