import { Address } from '../cartography'
import { Product } from '../product'
import { format } from '../../utils'

export type Time = {
  from: string
  to: string
}

export type Schedule = {
  sun: Time | null
  mon: Time | null
  tue: Time | null
  wed: Time | null
  thu: Time | null
  fri: Time | null
  sat: Time | null
}

export const fetchGetSchedule = (
  _: undefined,
  options?: RequestInit,
): Promise<Schedule> =>
  fetch('/api/v1/schedule', options)
    .then((x) => x.json())
    .then(({ workingTime }) => workingTime)

type Order = {
  productId: Product['id']
  comment?: string
  address: Address
  count: number
  recipientPhone: string
  recipientFIO?: string
  date: Date
  // orderType?: string
}

type PostOrder = {
  comment: string
  orders: Order[]
  city: string
}

type fetchPostOrderType = (body: PostOrder) => Promise<any> //@ts-ignore

export const fetchPostOrder: fetchPostOrderType = ({ city, comment, orders }) =>
  fetch('/api/v1/admin/order', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      comment,
      orders: orders?.map((item) => ({
        ...item,
        address: {
          lon: item.address.lng,
          address: item.address.address,
          lat: item.address.lat,
        }, //@ts-ignore
        city, //@ts-ignore
        orderType: item.orderType,
        productId: Number(item.productId),
        rangeParams: {
          //@ts-ignore
          startDate: item.rangeParams?.startDate //@ts-ignore
            ? item.rangeParams?.startDate //@ts-ignore
            : '', //@ts-ignore
          endDate: item.rangeParams?.endDate ? item.rangeParams?.endDate : '', //@ts-ignore
          minPerDay: item.rangeParams?.minPerDay, //@ts-ignore
          maxPerDay: item.rangeParams?.maxPerDay, //@ts-ignore
          commonCount: item.rangeParams?.commonCount,
        },
        date: {
          from: 'до',
          to: item.date ? format('kk:mm')(new Date(item.date)) : '',
          date: item.date,
          text: item.date
            ? format(`до dd MMMM с kk:mm `)(new Date(item.date))
            : '', //@ts-ignore
        },
      })),
    }),
  })

type fetchPrepaymentType = () => Promise<number>
export const fetchPrepayment: fetchPrepaymentType = () =>
  fetch('/api/v1/admin/prepayment', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(({ prePayment }) => prePayment)
