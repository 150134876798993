import React, { useState } from 'react'
import styled from 'styled-components'
import { checkboxIcon } from '../../images'

interface LinkProps {
  isActive: boolean
}

const SearchInput = styled.input.attrs({ type: 'text' })`
  background: rgba(236, 241, 244, 0.7);
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  color: #35414C;
  border: none;
  height: 38px;
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 15px;

  &::placeholder {
    color: #8B949B;
  }
`

const MaterialRow = styled.label<LinkProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${({ isActive }) => isActive ? '#f0640c' : '#495560'};
  margin-bottom: 10px;
`


const StyledCheckbox = styled.div<LinkProps>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-right: 10px;

  border-radius: 3px;
  border: ${({ isActive }) =>
          isActive ? '1.5px solid #f0640c' : '1.5px solid #8B949B'};
  background: white;
  background-image: ${({ isActive }) =>
          isActive ? `url(${checkboxIcon})` : 'none'};
  background-position: center;
  background-position-x: 2px;

  background-repeat: no-repeat;
`


type FilterMaterial = {
  materials: Array<string>
  selectedMaterials: Array<string>
  onChangeMaterial: (material: string) => void
}

const FilterMaterial: React.FC<FilterMaterial> = ({onChangeMaterial, materials, selectedMaterials}) => {
  const [inputValue, setInputValue] = useState('')


  const searchMaterials = (el: string) => {
    if (!inputValue) return true
    return el.toLowerCase().includes(inputValue.toLowerCase())
  }
  const renderMaterialRows = () => {
    return materials.filter(searchMaterials)?.map(el => {
      const isActive = selectedMaterials.includes(el)
      return (
        //@ts-ignore
        <MaterialRow key={el} isActive={isActive} onClick={(e)=>{
          e.stopPropagation()
          onChangeMaterial(el)
        }}>
          <StyledCheckbox isActive={isActive}/>
          {el}
        </MaterialRow>
      )
    })
  }

  return (
    <div>
      <SearchInput placeholder='Материалы' value={inputValue} onChange={e => setInputValue(e.target.value)} />
      {renderMaterialRows()}
    </div>
  )
}


export default FilterMaterial