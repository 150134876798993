//@ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { FieldRenderProps } from 'react-final-form'
import { IMaskMixin, Mask } from 'react-imask'

type InputTextStyleProps = {
  isError?: boolean
}

const InputTextStyle = styled.input<InputTextStyleProps>`
  padding: 9px 14px;
  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }
  &:focus {
    border-color: #f49355;
  }
  transition: 225ms;
  min-width: 152px;
  outline: none;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 16px;
  color: #8b949b;

  border: 1px solid transparent;
  background: #ecf1f4;
  border-radius: 6px;
  ${({ isError }) => (isError ? 'border-color: #F55555;' : '')};
  @media screen and (min-width: 400px) {
    min-width: 225px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SmallInputContainer = styled.div`
  position: relative;
`

const AddCount = styled.div`
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  min-width: 59px;
  @media screen and (min-width: 400px) {
    width: 59px;
    height: 38px;
    margin-right: 10px;
  }
`

const AddCountTwo = styled.div`
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 6px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  min-width: 59px;

  @media screen and (min-width: 400px) {
    width: 59px;
    height: 38px;
    margin-right: 10px;
  }
`

const AddCountValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  /* identical to box height, or 19px */
  color: rgba(240, 100, 12, 0.7);
  text-align: center;
  padding-top: 3px;
`

export const Error = styled.div`
  height: 13px;
  margin-top: 3px;

  text-align: left;
  color: #f06060;
`
const Measurement = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  color: #8b949b;

  transform: translateY(-50%);
`

export type InputTextProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  onChange: (value: string) => void
}
type InputText = React.FC<InputTextProps>
export const InputText: InputText = React.forwardRef(
  ({ onChange, ...props }, ref) => (
    <InputTextStyle
      type="text"
      onChange={(event) => onChange(event.target.value)}
      // @ts-ignore
      ref={ref}
      {...props}
    />
  ),
)

type InputTextFinalProps = FieldRenderProps<string> &
  React.InputHTMLAttributes<HTMLInputElement>
type InputTextFinalQuanity = React.FC<InputTextFinalProps>
export const InputTextFinalQuanity: InputTextFinalQuanity = ({
  input,
  input: { value, onChange },
  product,
  measurement,
  setCommonCount,
  ...props
}) => {
  const [counter, setCounter] = React.useState(1)

  const test = () => {
    setCounter(counter + 1)
  }

  React.useEffect(() => {
    if (Number(value) % Number(product?.multiplicity) !== 0) {
      if (Number(value) > Number(product?.multiplicity)) {
        const mult = parseInt(Number(value) / product?.multiplicity)
        const left = Number(value) % Number(product?.multiplicity)
        if (left < Number(product?.multiplicity) / 2) {
          return onChange(Number(product?.multiplicity) * mult)
        } else if (left >= Number(product?.multiplicity) / 2) {
          return onChange(Number(product?.multiplicity) * (mult + 1))
        }
      }
      if (Number(value) < Number(product?.multiplicity)) {
        return onChange(0 + Number(product?.multiplicity))
      }
    } else if (Number(value) == 0) {
      return onChange(0 + Number(product?.multiplicity))
    }
  }, [counter])

  const addValue = (val) => {
    onChange(Number(val) + Number(product?.multiplicity))
  }

  const minusValue = (val) => {
    if (val > product?.multiplicity) {
      onChange(Number(val) - Number(product?.multiplicity))
    }
  }

  React.useEffect(() => {
    setCommonCount(value)
  }, [value])

  return (
    <>
      <InputContainer>
        <SmallInputContainer>
          <InputTextStyle
            type="text"
            {...props}
            {...input}
            value={value}
            onBlur={() => test()}
          />
          <Measurement>{product?.measurement}</Measurement>
        </SmallInputContainer>
        <AddCount onClick={() => minusValue(value)}>
          <AddCountValue>-{Number(product?.multiplicity)}</AddCountValue>
        </AddCount>
        <AddCountTwo onClick={() => addValue(value)}>
          <AddCountValue>+{Number(product?.multiplicity)}</AddCountValue>
        </AddCountTwo>
      </InputContainer>
    </>
  )
}

export const InputTextFinalWithError: InputTextFinalQuanity = ({
  input,
  meta: { touched, error },
  ...props
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <InputTextStyle type="text" {...props} {...input} />
    <Error>{touched && error}</Error>
  </div>
)

export type InputTextAreaProps = Omit<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> &
  InputTextStyleProps & {
    onChange: (value: string) => void
  }

type InputTextAreaFC = React.FC<InputTextAreaProps>
export const InputTextArea: InputTextAreaFC = React.forwardRef((props, ref) => {
  // @ts-ignore
  return <InputText as="textarea" ref={ref} {...props} />
})

type InputTextAreaFinal = React.FC<
  FieldRenderProps<string> &
    React.InputHTMLAttributes<HTMLTextAreaElement> &
    InputTextStyleProps
>
export const InputTextAreaFinal: InputTextAreaFinal = ({ input, ...props }) => (
  <InputTextArea {...props} {...input} />
)

const IMaskMixinCustom = function <A>(
  Component: React.Component<A> | React.FC<A>,
): React.FC<A & Mask> {
  // @ts-ignore
  return IMaskMixin(({ inputRef, ...props }) => (
    // @ts-ignore
    <Component ref={inputRef} {...props} />
  ))
}

export const InputMask = IMaskMixinCustom(InputTextStyle)

type InputMaskFinal = React.FC<InputTextFinalProps & Mask>
export const InputMaskFinal: InputMaskFinal = React.forwardRef(
  (
    {
      input: { onChange, value, ...input },
      meta: { touched, error },
      product,
      ...props
    },
    ref,
  ) => (
    <>
      <InputMask
        onAccept={(value: string) => onChange(value)}
        value={value}
        // @ts-ignore
        ref={ref}
        {...input}
        {...props}
      />
      <Error>{touched && error}</Error>
    </>
  ),
)
