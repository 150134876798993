import { useEffect, useRef, useState } from 'react'
import { RequestsBasket } from '../../../modules/basket'
import { isAuthorization } from '../../../modules/authorization'

export const usePrepayment = () => {
  const [prePaymentFetching, setFetching] = useState<boolean>(true)
  const [prePayment, setPrePayment] = useState<number | null>(null)
  const unmounted = useRef(false)

  useEffect(() => {
    if (!isAuthorization()) {
      setFetching(false)
      return
    }
    RequestsBasket.fetchPrepayment().then((res) => {
      if (!unmounted.current) {
        setFetching(false)
        setPrePayment(res)
      }
    })
    return () => {
      unmounted.current = true
    }
  }, [])

  return { prePayment, prePaymentFetching }
}
