import React from 'react'
import { Link as LinkBase, LinkGetProps, LinkProps } from '@reach/router'
import styled from 'styled-components'

const Link = styled(LinkBase)`
  text-decoration: none;
  color: initial;
`

const isActive = (className?: string) => ({ isCurrent }: LinkGetProps) => {
  return isCurrent ? { className: `${className ?? ''} active` } : {}
}

export function NavLink<TState>(
  props: React.PropsWithoutRef<LinkProps<TState>> &
    React.RefAttributes<HTMLAnchorElement>,
) {
  return <Link getProps={isActive(props.className)} {...props} />
}
