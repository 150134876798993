import React from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../../commons'
import { Filters } from '../Filters'
import { mobileFilters, filterArrow } from '../../../images'
import { ProductsLogicTypes, ProductsLogicTypesCut } from '../../../../modules/product'
import { LinkProps } from '../../../../modules/validators'
import { CategoriesList } from './CategoriesList'
import { ProductsHeader } from './ProductsHeader'

// @ts-ignore

import Headroom from 'react-headroom'
import { HeaderCatalogue } from '../../../commons/HeaderCatalogue'
import { HeaderContainer, HeaderWrapper } from '../../../commons/HeaderWrapper'
const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid #f0f4f6;
  background-color: white;
  @media screen and (min-width: 620px) and (max-width: 767px) {
    height: 46px;
  }
`

const Container = styled.div`  
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FilterWrapper = styled.div``

const FiltersIconContainer = styled.div`
  margin-left: 35px;
  @media screen and (min-width: 1140px) {
    display: none;
  }
  @media screen and (min-width: 320px) and (max-width: 719px) {
    display: none;
  }
`

const FiltersContainer = styled.div`
  position: absolute;
  top: 108px;
  left: 16px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.35);
  z-index: 1000;
  @media screen and (min-width: 1140px) {
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    top: 139px;
    left: 15px;
  }
`

const FilterArrowContainer = styled.div`
  position: absolute;
  top: 99px;
  left: 30px;
  z-index: 100;
  @media screen and (min-width: 1140px) {
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    top: 128px;
    left: 32px;
  }
`

const FiltersIcon = styled.img.attrs({ src: mobileFilters })<LinkProps>`
  fill: ${({ isActive }) => (isActive ? '#F0640C' : '#35414C')};
  margin-right: 37px;
  width: 19.5px;
  height: 18px;
  margin-bottom: 4px;
`
const FilterArrow = styled.img.attrs({ src: filterArrow })``

export const Categories: React.FC<ProductsLogicTypesCut> = ({
  activeCategoryId,
  setActiveCategoryId,
  categories,
  setSelectedSubcategoriesIds,
  cities,
  selectedCity,
  setSelectedCity,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const root = useOnClickOutside(() => setIsOpen(false))

  const selectCategoryHandler = React.useCallback(
    (id: string) => {
      const buttonElement = document.getElementById(id)
      buttonElement?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center',
      })
      setActiveCategoryId(Number(id))
      setSelectedSubcategoriesIds([])
    },
    [setActiveCategoryId, setSelectedSubcategoriesIds],
  )

  return (
    <div>
      <ProductsHeader cities={cities} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
      <Wrapper onClick={root}>          
        <HeaderWrapper>
        <HeaderContainer>
          <Container>
            {/* <FiltersIconContainer>
              <FiltersIcon
                onClick={() => setIsOpen(!isOpen)}
                isActive={isOpen}
              />
            </FiltersIconContainer> */}
            {/* {isOpen && (
              <FilterWrapper>
                <FilterArrowContainer>
                  <FilterArrow />
                </FilterArrowContainer>
                <FiltersContainer>
                  <Filters
                    setSelectedSortingTypes={setSelectedSortingTypes}
                    selectedSubcategoriesIds={selectedSubcategoriesIds}
                    setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
                    activeCategoryTitle={activeCategoryTitle}
                    subcategories={subcategories}
                    prices={prices}
                    priceInputValue={priceInputValue}
                    setMaxFilterPrice={setMaxFilterPrice}
                    setMinFilterPrice={setMinFilterPrice}
                    setPriceInputValue={setPriceInputValue}
                    setTableViewCallback={setTableViewCallback}
                    setRowViewCallback={setRowViewCallback}
                    isTableView={isTableView}
                    setLimit={setLimit}
                    limit={limit}
                    selectedSortingTypes={selectedSortingTypes}
                    isActive={true}
                  />
                </FiltersContainer>
              </FilterWrapper>
            )} */}
            <HeaderCatalogue selectCategoryHandler={selectCategoryHandler} />
            <CategoriesList
              categories={categories}
              activeCategoryId={activeCategoryId}
              //@ts-ignore
              selectCategoryHandler={selectCategoryHandler}
            />
          </Container>
        </HeaderContainer>
      </HeaderWrapper>
      </Wrapper>
    </div>
  )
}
