
import styled from 'styled-components'
import { Field, FieldRenderProps } from 'react-final-form'
import React, { Dispatch, SetStateAction } from 'react'

import { InputTextFinalQuanity } from '../../../commons/Inpunts/InputQuanity'
import { Product } from '../../../../modules/product'
import { InputMinQuanity } from '../../../commons/Inpunts/InputMinQuanity'
import { InputMaxQuanity } from '../../../commons/Inpunts/InputMaxQuanity'

import { Label } from './Label'
import { ltTablet } from '../../../../utils'

const Container = styled.div`
  ${ltTablet} {
    /* max-width: 158px; */
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1440px) {
    border-bottom: 20px solid #ecf1f4;
  }
`

const CountLabel = styled(Label)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  padding-top: 25px;
  padding-left: 15px;
  color: #35414c;

  @media screen and (min-width: 400px) {
    padding-left: 20px;
  }
`

const SubText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 15px;
  color: #495560;
  opacity: 0.7;
  @media screen and (min-width: 400px) {
    padding-left: 20px;
  }
`

const Wrapper = styled.div`
  position: relative;
  margin-left: 15px;
  @media screen and (min-width: 400px) {
    margin-left: 20px;
  }
`

const InputLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  padding-bottom: 10px;
  color: #495560;
`

type CountT = React.FC<{
  name: string
  product: Product
  activeDeliveryRange: boolean
  commonCount: number
  setCommonCount: Dispatch<SetStateAction<number>>
  minPerDay: number
  setMinPerDay: Dispatch<SetStateAction<number>>
  maxPerDay: number
  setMaxPerDay: Dispatch<SetStateAction<number>>
}>
export const Count: CountT = ({
  name,
  product,
  activeDeliveryRange,
  commonCount,
  setCommonCount,
  minPerDay,
  setMinPerDay,
  maxPerDay,
  setMaxPerDay,
  product: { minCount, measurement },
}) => {
  const prevValue = React.useRef('')

  return (
    <Container>
      <Field
        name={name}
        validate={(value) => {
          if ( Number(value) < Number(product?.multiplicity)) {
            return `Минимум ${Number(product?.multiplicity)} ${measurement}`
          }
          return ''
        }}
        format={(value) => (value === 0 ? '' : String(value))}
        parse={(value: string) => {
          const number = Number(value ?? 0)
          return Number.isNaN(number) ||
            number < 0 ||
            parseInt(String(number)) !== number
            ? prevValue.current
            : number
        }}
      >
        {(props: FieldRenderProps<string>) => {
          const {
            meta: { touched, error },
          } = props
          prevValue.current = props.input.value
          return (
            <>
              <CountLabel isError={error && touched}>Количество:</CountLabel>
              <SubText>
                Укажите количество кратное {Number(product?.multiplicity)}
              </SubText>
              <Wrapper>
                {activeDeliveryRange && (
                  <InputLabel>Общее количество</InputLabel>
                )}
                <InputTextFinalQuanity
                  placeholder="Введите количество"
                  isError={error && touched}
                  measurement={measurement}
                  {...props}
                  product={product}
                  setCommonCount={setCommonCount}
                />
              </Wrapper>
              {/* <Error>{touched && error ? error : <>&nbsp;</>}</Error> */}
            </>
          )
        }}
      </Field>
      {activeDeliveryRange && (
        <>
          <InputMinQuanity
            product={product}
            setMinPerDay={setMinPerDay}
            maxPerDay={maxPerDay}
            commonCount={commonCount}
          />
          <InputMaxQuanity
            product={product}
            setMaxPerDay={setMaxPerDay}
            commonCount={commonCount}
            minPerDay={minPerDay}
          />
        </>
      )}
    </Container>
  )
}
