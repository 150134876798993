import React from 'react'
import { connectReduxDevtools } from '@reatom/debug'
import { context } from '@reatom/react'
import { Store } from '@reatom/core'
import 'moment/locale/ru'
import { Navigators } from './view/navigators'
import { CityProvider } from './context/CityContext'
import { CategoryProvider } from './context/CategoryContext'

type Main = React.FC<{ store: Store }>
export const Main: Main = ({ store }) => {
  React.useEffect(() => connectReduxDevtools(store), [store])
  return (
    <React.StrictMode>
      <context.Provider value={store}>
        <CityProvider>
          <CategoryProvider>
            <Navigators />
          </CategoryProvider>
        </CityProvider>
      </context.Provider>
    </React.StrictMode>
  )
}
