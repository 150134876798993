import React from 'react'
import styled from 'styled-components'
import {
  Address as AddressModel,
  RequestsGeocode,
} from '../../../../../modules/cartography'
import { mapMarker } from '../../../../images'
import { fetchLocationByCoordsType } from '../../../../../modules/cartography/Geocode'

const createMap = (
  element: HTMLDivElement | null,
  coord: AddressModel,
): google.maps.Map<HTMLDivElement> | null =>
  element === null
    ? null
    : new google.maps.Map(element, {
        center: {
          lat: coord.lat ? coord.lat : Number(defaultAddress.lat),
          lng: coord.lng ? coord.lng : Number(defaultAddress.lng),
        },
        zoom: 15,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      })

const MapStyle = styled.div`
  height: 100%;
  width: 100%;
`

const Marker = styled.img`
  width: 36px;
  height: 54px;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%);
`

const Wrapper = styled.div`
  position: relative;
`

const Address = styled.div`
  max-width: 470px;
  padding: 0 15px;

  position: absolute;
  left: 50%;
  bottom: calc(50% + 23px);

  transform: translate(-50%);

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #35414c;
`

export const fetchLocationByCoords: (
  ...args: Parameters<fetchLocationByCoordsType>
) => Promise<AddressModel> = (coord, options) =>
  RequestsGeocode.fetchLocationByCoords(coord, options).then(([head]) => ({
    // @ts-ignore
    address: 'Что-то не так',
    ...coord,
    ...head,
  }))

type MapProps = {
  className?: string
  value: AddressModel
  onChange: (x: AddressModel) => void
}

export const defaultAddress = {
  address: 'Северная улица, 279',
  lat: 45.04139150229465,
  lng: 38.97425558312251,
}
export class Map extends React.Component<MapProps> {
  map: google.maps.Map<HTMLDivElement> | null = null
  removeListenerCenterChanged: google.maps.MapsEventListener | null = null
  componentDidMount() {
    if (!this.props.value) {
      RequestsGeocode.fetchCurrentLocation()
        .then(fetchLocationByCoords)
        .then(this.props.onChange)
    }
  }
  componentDidUpdate(prevProps: MapProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.ownPrevValue
    ) {
      this.map?.setCenter(this.props.value)
    }
  }

  ownPrevValue: AddressModel | null = null
  controllerAbortLocationByCoors: AbortController | null = null
  factoryMap = (div: HTMLDivElement) => {
    this.map = createMap(div, this.props.value ?? defaultAddress)
    if (this.map === null) {
      return
    }
    this.removeListenerCenterChanged = this.map.addListener(
      'center_changed',
      () => {
        if (this.map === null) {
          return
        }

        this.controllerAbortLocationByCoors?.abort()
        this.controllerAbortLocationByCoors = new AbortController()
        fetchLocationByCoords(this.map.getCenter().toJSON(), {
          signal: this.controllerAbortLocationByCoors.signal,
        })
          .then((address) => {
            this.ownPrevValue = address
            this.props.onChange(address)
          })
          .catch(() => {})
      },
    )
  }

  render() {
    const { className, value } = this.props
    return (
      <Wrapper className={className}>
        <MapStyle ref={this.factoryMap} />
        <Address>{value.address ?? defaultAddress.address}</Address>
        <Marker src={mapMarker} />
      </Wrapper>
    )
  }

  componentWillUnmount() {
    this.removeListenerCenterChanged?.remove()
  }
}
