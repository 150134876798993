import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import { profileImage } from '../../images'

const EmptyProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;

  @media screen and (min-width: 330px) and (max-width: 767px) {
    padding-top: 130px;
    margin-right: 15px;
  }
  @media screen and (min-width: 320px) and (max-width: 329px) {
    padding-top: 130px;
    margin-left: -15px;
  }
`
const EmptyProductsImage = styled.img.attrs({ src: profileImage })`
  height: 69px;
  width: 72px;
`
const EmptyProductsText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #8b949b;
  font-size: 20px;
  line-height: 110%;
  padding-top: 22px;
  max-width: 250px;
  text-align: center;
`

const EmptyProductsBottomText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #8b949b;
  font-size: 16px;
  line-height: 100%;
  padding-top: 18px;
`

type ProfileT = React.FC<RouteComponentProps>

export const Profile: ProfileT = () => {
  return (
    <div>
      <EmptyProducts>
        <EmptyProductsImage />
        <EmptyProductsText>
          Раздел «профиль» временно недоступен
        </EmptyProductsText>
        <EmptyProductsBottomText>
          Мы уже спешим это исправить
        </EmptyProductsBottomText>
      </EmptyProducts>
    </div>
  )
}
