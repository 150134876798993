import React from 'react'
import styled from 'styled-components'
import { ArrowLong as ArrowLongBase, cross, Direction } from '../../../images'
import { ltTablet } from '../../../../utils'

const ContainerBase = styled.form`
  margin: auto;
  max-width: 400px;
  width: 100%;
`

const Wrapper = styled.div`
  height: calc(100% - 40px);
  padding: 40px 55px;
  ${ltTablet} {
    padding: 30px 15px;
  }

  position: relative;

  display: flex;
  flex-direction: column;

  background: #ffffff;
  border-radius: 6px;
  @media screen and (min-width: 320px) and (max-width: 719px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const CloseText = styled.div`
  margin: 10px 0;

  cursor: pointer;
  font-family: 'Open Sans';
  font-size: 14px;
  text-align: center;
  color: #ffffff;
`

const Cross = styled.img`
  width: 14px;

  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
  ${ltTablet} {
    top: 15px;
    right: 15px;
  }
`

const ArrowLong = styled(ArrowLongBase)`
  width: 15px;

  stroke: #8b949b;
  cursor: pointer;

  position: absolute;
  top: 20px;
  left: 20px;
  ${ltTablet} {
    top: 15px;
    right: 15px;
  }
`

export const Container: React.FC<{
  children?: any;
  onRequestClose: () => void
  onBack?: () => void
}> = ( {children, onRequestClose, onBack, ...props }) => {
  return (
    <ContainerBase {...props}>
      <Wrapper>
        <Cross src={cross} onClick={onRequestClose} />
        {onBack && <ArrowLong direct={Direction.Left} onClick={onBack} />}
        {children}
      </Wrapper>
      <CloseText onClick={onRequestClose}>Закрыть</CloseText>
    </ContainerBase>
  )
}

export const Title = styled.div`
  margin-bottom: 30px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 20px;
  color: #35414c;

  text-align: center;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;

  font-family: 'Open Sans';
  font-size: 12px;
  text-align: center;
  color: #495560;
`
