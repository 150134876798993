import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { search, emptyProductInput } from '../images'
import { useOnClickOutside } from './useOnClickOutside'

const Container = styled.div`
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 619px) {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  padding-left: 20px;
  border: none;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px 15px;
  background-color: rgba(236, 241, 244, 0.7);
  outline: none;
  border-radius: 6px;
  :focus {
    border: 1.5px solid rgba(240, 100, 12, 0.7);
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    height: 36px;
  }
`

const DropDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 205px;
  top: 42px;
  background-color: white;
  z-index: 1001;
  @media screen and (min-width: 320px) and (max-width: 619px) {
    max-height: 291px;
  }
`

const DropDownItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;
  color: black;
  min-height: 41px;
  cursor: pointer;
  :hover {
    background: #ecf1f4;
    opacity: 0.5;
    border-radius: 4px;
  }
  @media screen and (max-width: 767px) {
    min-height: 51px;
  }
`

const LeftPart = styled.div`
  display: flex;
  align-items: center;
`

const RightPart = styled.div``

const ItemImage = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 9px;
  margin-bottom: 6px;
`
const ItemTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  line-height: 23px;
  color: linear-gradient(168.82deg, #3d4751 0%, #162836 108.75%);
  padding-left: 10px;
  padding-bottom: 6px;

  @media screen and (min-width: 620px) and (max-width: 900px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const ItemPrice = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  line-height: 23px;
  color: #23323f;
  opacity: 0.78;
  padding-right: 13px;
  padding-bottom: 6px;
  @media screen and (min-width: 620px) and (max-width: 900px) {
    padding-right: 5px;
  }
`

export const HeaderInput = () => {
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(false)

  const fetchGetSearchValue = () => {
    if (inputValue === '') {
      return undefined
    } else {
      fetch(`/api/v1/products/?search=${inputValue}`)
        .then((res) => res.json())
        .then(setSearchValue)
    }
  }

  React.useEffect(() => {
    if (inputValue === '') {
      setSearchValue([])
    } else fetchGetSearchValue()
  }, [inputValue])

  const inputSearch = (event) => {
    setInputValue(event.target.value)
  }

  React.useEffect(() => {
    if (inputValue === '' || searchValue.length == 0) {
      setIsOpen(false)
    } else setIsOpen(true)
  }, [inputValue, searchValue])

  const root = useOnClickOutside(() => setIsOpen(false))

  return (
    <Container onClick={root}>
      <Input
        placeholder="Поиск"
        value={inputValue}
        onChange={(event) => inputSearch(event)}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen && (
        <DropDown>
          {searchValue?.map((item, i) => {
            return (
              <DropDownItem
                to={`/basket/${item.id}`}
                onClick={() => setIsOpen(false)}
              >
                <LeftPart>
                  <ItemImage
                    src={
                      item?.files[0]?.uri
                        ? item?.files[0]?.uri
                        : emptyProductInput
                    }
                  />
                  <ItemTitle>{item.title}</ItemTitle>
                </LeftPart>
                <RightPart>
                  <ItemPrice>{Number(item.price)}₽</ItemPrice>
                </RightPart>
              </DropDownItem>
            )
          })}
        </DropDown>
      )}
    </Container>
  )
}
