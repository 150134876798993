import React, { FC, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  border-top: 1px solid #F0F4F6;
  box-sizing: border-box;
  padding: 8px 20px 14px;
  width: 100%;
`

const HeaderContainer = styled.div`
  cursor: pointer;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #495560;
  display: flex;
  justify-content: space-between;
  
`

const FilterHeaderRight = styled.div`
  color: #f0640c;
  font-weight: 900;
`


//@ts-ignore
const FilterListItem: FC<{
  name: string,
  onReset?: ()=>void,
  onOpen?: ()=>void | undefined,
  children?: any
}> = ({ name, onReset, onOpen, children}) => {
  const [isOpen,setIsOpen] = useState(false)

  const toggleContainer = ()=>{
    if(isOpen){
      onReset && onReset()
      return setIsOpen(false)
    }
    onOpen&&onOpen()
    return setIsOpen(true)
  }

  return (
    <Container>
      <HeaderContainer onClick={toggleContainer}>
        <div>{name}</div>
        <FilterHeaderRight>{isOpen? '-' : '+'}</FilterHeaderRight>
      </HeaderContainer>
      {isOpen && children}
    </Container>
  )
}


export default FilterListItem