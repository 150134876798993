import React from 'react'
import styled from 'styled-components'

import {
  deleteDeliveries as deleteDeliveriesBase,
  deliveries,
  Delivery,
  getDeliveriesByIds,
} from '../../../modules/basket'
import { Button as ButtonInit, ButtonBase, ModalDark } from '../../commons'
import { Trashcan } from '../../images'
import { useAction, useAtom } from '@reatom/react'
import { ltTablet } from '../../../utils'

const IconDelete = styled(Trashcan)`
  height: 14px;
  margin-right: 10px;

  fill: #f06060;
  stroke: #f06060;
`

const ButtonDelete = styled(ButtonBase)`
  display: inline-flex;
  align-items: center;

  font-family: 'Open Sans';
  font-size: 14px;
  color: #f06060;
  ${ltTablet} {
    font-size: 12px;
  }
`

const Modal = styled(ModalDark)`
  padding: 0 10px;
`

const Container = styled.div`
  margin: auto;
  padding: 20px 30px;
  ${ltTablet} {
    padding: 20px 15px;
  }

  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  height: 227px;

  @media screen and (min-width: 600px) {
    width: 400px;
  }
`

const Title = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #35414c;
  padding-top: 20px;
`

const Item = styled.div`
  margin-top: 10px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #495560;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonCancel = styled(ButtonInit)`
  width: 90%;
  ${ltTablet} {
    width: 128px;
  }
  margin-top: 15px;
  height: 38px;
  background: #8b949b;
  box-shadow: initial;
`

const ButtonConfirmDelete = styled(ButtonCancel)`
  margin-top: 20px;

  background: #f06060;
`

type RemoveItems = React.FC<{
  ids: Array<Delivery['id']>
  className?: string
  children?: any
}>

export const RemoveItems: RemoveItems = React.memo(
  ({ ids, className, children }) => {
    const [isShowModal, setIsShowModal] = React.useState(false)
    const deleteDeliveries = useAction(() => deleteDeliveriesBase(ids), [ids])
    const productTitles = useAtom(
      deliveries,
      (items) =>
        getDeliveriesByIds(ids)(items)?.map(({ product }) => product.title),
      [ids],
    )

    return (
      <>
        <ButtonDelete
          disabled={isShowModal}
          onClick={() => setIsShowModal(true)}
          className={className}
        >
          <IconDelete />
          {children}
        </ButtonDelete>
        <Modal
          visible={isShowModal}
          onRequestClose={() => setIsShowModal(false)}
        >
          <Container>
            <Title>Удалить отправление?</Title>
            {/* {productTitles?.map((title) => (
              <Item key={title}>{title}</Item>
            ))} */}
            <ButtonWrapper>
              <ButtonConfirmDelete onClick={deleteDeliveries}>
                Удалить
              </ButtonConfirmDelete>
              <ButtonCancel onClick={() => setIsShowModal(false)}>
                Отмена
              </ButtonCancel>
            </ButtonWrapper>
          </Container>
        </Modal>
      </>
    )
  },
)
