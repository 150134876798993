import React from 'react'
import { OrderInterface } from '../Orders/Orders'
import { RouteComponentProps, Link } from '@reach/router'
import { statuses } from '../../../modules/orders/constants'
import { OrderCheck } from './OrderCheck'
import { selectReorder } from '../../../modules/basket'

import { emptyProduct, Basket, arrowBack } from '../../images'
import moment from 'moment'
import { useAction } from '@reatom/react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const OrdersTopSection = styled.div`
  background-color: white;
  width: 100%;
  height: 93px;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (min-width: 1440px) {
    align-items: center;
  }
`
const OrdersTopSectionText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #35414c;
  padding-left: 45px;
  @media screen and (min-width: 1440px) {
    padding-right: 1162px;
    padding-left: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding-left: 30px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 16px;
    line-height: 19px;
  }
`

const OrdersTopSectionBacktoOrders = styled(Link)`
  display: flex;

  padding-top: 15px;
  padding-left: 45px;
  text-decoration: none;
  cursor: pointer;
  @media screen and (min-width: 1440px) {
    padding-left: 0;
    padding-right: 1218px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding-left: 30px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`
const OrdersTopSectionBacktoOrdersText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
  padding-left: 10px;
`

const OrderBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const OrderElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 890px;
  height: 100%;
  background-color: white;
  margin-top: 30px;
  border-radius: 6px;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    width: 706px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 320px;
  }
`

const OrderHeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 18px;
  border-bottom: 1px solid #f0f4f6;
`

const OrderDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #35414c;
  padding-left: 34px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 14px;
  }
`

const StatusContainer = styled.div`
  display: flex;
  padding-right: 30px;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const OrderStatus = styled.div<CircleProps>`
  padding-right: 10px;
  color: ${({ color }) => color};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
`

type CircleProps = {
  color: string
}

const Circle = styled.div<CircleProps>`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`

const OrderMiddleSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  border-bottom: 1px solid #f0f4f6;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`

const OrderMiddleLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const OrderMiddleRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderMiddleLeftTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  padding-left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 12px;
  }
`

const OrderDeliveryDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #20303d;
  padding-left: 30px;
  padding-top: 5px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }
`

const OrderMiddleRightTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  padding-right: 306px;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding-right: 281px;
  }
`

const OrderDeliveryAddress = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #20303d;
  padding-top: 5px;
  padding-bottom: 20px;
`

const OrderAddressMobileSection = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    border-top: 1px solid #f0f4f6;
    padding-top: 15px;
  }
`

const OrderAddressMobileTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 130%;
  color: #8b949b;
  padding-left: 15px;
`

const OrderMobileAddress = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #20303d;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-left: 15px;
`

const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #f0f4f6;
  bottom: 0px;
  left: 50%;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    left: 41.5%;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderProductContainer = styled.div`
  height: 59px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const OrderProductTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #495560;
  padding-left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 14px;
  }
`

const ProductLine = styled.div`
  position: absolute;
  height: 1px;
  width: 93%;
  background-color: #f0f4f6;
  bottom: 0;
  left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    left: 15px;
    width: 256px;
  }
`

const OrderProduct = styled.div`
  display: flex;
  padding-left: 30px;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f4f6;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`
const OrderImage = styled.img`
  width: 54.48px;
  height: 57.12px;
  border-radius: 6px;
  margin-top: 8px;
`

const ProductLeft = styled.div`
  display: flex;
`

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`
const ProductTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #495560;
  padding-top: 15px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
`

const ProductQuanity = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  padding-top: 5px;
`

const ProductRight = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;
  padding-right: 30px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #495560;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const OrderBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f4f6;
  height: 52px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: 84px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: normal;
  }
`

const OrderButton = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  padding-left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderMobileButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const OrderButtonText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #f0640c;
  padding-left: 10px;
`

const OrderBottomRight = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    justify-content: space-between;
    padding-top: 17px;
  }
`

const OrderPriceText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
  color: #8e8e8e;
  padding-right: 70px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 0;
    padding-left: 15px;
  }
`
const OrderPrice = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #35414c;
  padding-right: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const BasketImage = styled(Basket)`
  width: 16.6px;
  height: 17px;
  stroke: #f0640c;
`

const ArrowBack = styled.img.attrs({ src: arrowBack })``

const OrderCheckContainer = styled.div`
  position: relative;
  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1480px;
  }
`

type Order = React.FC<RouteComponentProps<OrderInterface>>

export const Order: Order = (order) => {
  // @ts-ignore
  const { orderNumber, status, createdAt, date, address } = order?.location?.state
  // @ts-ignore
  const { product, count, productId, price } = order?.location?.state
  const [isOpen, setIsOpen] = React.useState(false)
  const passingOrder = order.location?.state

  const dateObject = JSON.parse(date ? date : '')
  const addressObject = JSON.parse(address ? address : '')
  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
    ev.currentTarget.src = emptyProduct
  }
  // @ts-ignore
  const { color, translatedStatus } = statuses[status]

  let now = moment(createdAt).format('DD MMMM YYYY')

  React.useEffect(() => {
    if (translatedStatus == 'Подтверждение') {
      setIsOpen(true)
    }
  }, [translatedStatus, order, product])

  const reorder = useAction(selectReorder)

  const selectProductToReorder = React.useCallback(
    // @ts-ignore
    () => reorder(order?.location?.state),
    [order?.location?.state],
  )

  const imageEbannoe = React.useMemo(() => {
    const uri = product?.files[0]?.uri ? product?.files[0]?.uri : ''   
    const host = (process.env.NODE_ENV === 'production')? 'https://prostore.su' : 'https://dev.prostore.su';
    if (uri.slice(0, 1) === 'f') {
      return `${host}/${uri}`
    }

    return uri
  }, [order])

  return (
    <Container>
      {/* {isOpen && (
        <OrderCheckContainer>
          <OrderCheck
            order={passingOrder}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </OrderCheckContainer>
      )} */}
      <OrdersTopSection>
        <OrdersTopSectionText> Заказ №{orderNumber}</OrdersTopSectionText>
        <OrdersTopSectionBacktoOrders to={`/orders`}>
          <ArrowBack />
          <OrdersTopSectionBacktoOrdersText>
            Назад к заказам
          </OrdersTopSectionBacktoOrdersText>
        </OrdersTopSectionBacktoOrders>
      </OrdersTopSection>
      <OrderBody>
        <OrderElement>
          <OrderHeadSection>
            <OrderDate>Заказ от {now}</OrderDate>
            <StatusContainer>
              <OrderStatus color={color}>{translatedStatus}</OrderStatus>
              <Circle color={color}></Circle>
            </StatusContainer>
          </OrderHeadSection>
          <OrderMiddleSection>
            <OrderMiddleLeft>
              <OrderMiddleLeftTitle>Дата доставки:</OrderMiddleLeftTitle>
              <OrderDeliveryDate>{dateObject.text}</OrderDeliveryDate>
            </OrderMiddleLeft>
            <Line></Line>
            <OrderMiddleRight>
              <OrderMiddleRightTitle>Адрес доставки:</OrderMiddleRightTitle>
              <OrderDeliveryAddress>
                {addressObject.address}
              </OrderDeliveryAddress>
            </OrderMiddleRight>
            <OrderAddressMobileSection>
              <OrderAddressMobileTitle>Адрес доставки:</OrderAddressMobileTitle>
              <OrderMobileAddress>{addressObject.address}</OrderMobileAddress>
            </OrderAddressMobileSection>
          </OrderMiddleSection>
          <OrderProductContainer>
            <OrderProductTitle>Товары</OrderProductTitle>
            <ProductLine></ProductLine>
          </OrderProductContainer>
          <OrderProduct>
            <ProductLeft>
              <OrderImage
                // @ts-ignore
                src={imageEbannoe ? imageEbannoe : emptyProduct}
                onError={(e) => addDefaultSrc(e)}
              />
              <ProductInfo>
                <ProductTitle>{product?.title}</ProductTitle>
                <ProductQuanity>
                  {count} {product?.measurement}
                </ProductQuanity>
              </ProductInfo>
            </ProductLeft>
            <ProductRight>{Number(price)} ₽</ProductRight>
          </OrderProduct>
          <OrderBottom>
            <OrderButton
              onClick={selectProductToReorder}
              to={`/basket/${productId}`}
            >
              <BasketImage />
              <OrderButtonText>Заказать еще раз</OrderButtonText>
            </OrderButton>
            <OrderBottomRight>
              <OrderPriceText>Итого:</OrderPriceText>
              <OrderPrice>{Number(price)} ₽</OrderPrice>
            </OrderBottomRight>
            <OrderMobileButton>
              <BasketImage />
              <OrderButtonText>Заказать еще раз</OrderButtonText>
            </OrderMobileButton>
          </OrderBottom>
        </OrderElement>
      </OrderBody>
    </Container>
  )
}
