import React from 'react'
import { ProductRequestes } from '../modules/product'

type contextValue = {
  cities: string[],
  selectedCity: string,
  setSelectedCity : React.Dispatch<React.SetStateAction<string>>
}

const defaultValue: contextValue = {
  cities: ['Краснодар'],
  selectedCity: 'Краснодар',
  setSelectedCity: ()=>{}
}

const CityContext = React.createContext(defaultValue)

export const useCityContext  = () => React.useContext(CityContext)

export const CityProvider = ({children}: {children:React.ReactChild}) => {
  const [cities, setCities] = React.useState(['Краснодар'])
  const [selectedCity, setSelectedCity] = React.useState('Краснодар')

  React.useEffect(() => {
    ProductRequestes.fetchGetCities().then(setCities)
  }, [])

  const value = {
    cities,
    setCities,
    selectedCity,
    setSelectedCity
  }

  return <CityContext.Provider value={value}>{children}</CityContext.Provider>

}
