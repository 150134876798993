import React from 'react'
import styled from 'styled-components'
import { useAction } from '@reatom/react'
import format from 'date-fns/fp/format'
import isValid from 'date-fns/fp/isValid'
import addHours from 'date-fns/fp/addHours'

import {
  Deliveries,
  patchDelivery as patchDeliveryBase,
} from '../../../modules/basket'
import { Checkbox, NavLink, Image } from '../../commons'
import { Pen } from '../../images'
import {
  btDesktop,
  btTablet,
  formatCount,
  ltDesktop,
  ltTablet,
} from '../../../utils'
import { RemoveItems } from './RemoveItems'

const Container = styled.div`
  padding: 16px 30px 12px 16px;
  ${ltDesktop} {
    padding-right: 20px;
  }
  ${ltTablet} {
    padding-right: 15px;
  }

  background: #ffffff;
  ${btTablet} {
    border-radius: 6px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 16px 15px 12px 16px;
  }
`

const IconEdit = styled(Pen)`
  height: 14px;
  margin-right: 10px;

  fill: #8b949b;
  stroke: #8b949b;
`

const LinkEdit = styled(NavLink)`
  display: flex;
  align-items: center;

  font-family: 'Open Sans';
  font-size: 14px;
  color: #8b949b;
`

const Price = styled.div`
  padding-left: 90px;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 20px;
  color: #35414c;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding-left: 20px;
  }
`

const Title = styled.span`
  margin-left: 15px;
  margin-right: auto;
  padding-top: 10px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  color: #35414c;
`

const PriceContainer = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #f0640c;
  padding-left: 15px;
  display: flex;
  align-items: center;
`

const SpanMeasurment = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding-left: 5px;
  color: #8b949b;
`

const ButtonDelete = styled(RemoveItems)`
  margin-right: 28px;
  ${ltTablet} {
    margin-right: 20px;
  }
`

const WrapperInfo = styled.div`
  margin-left: 26px;
  height: 100%;
  display: flex;
  padding-bottom: 10px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 15px;
  }
`

const Info = styled.div`
  margin-left: 16px;

  display: flex;
  flex-direction: column;
  flex: 1;
`

const Address = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  color: #8b949b;
  padding: 5px 0 0 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`

const Hr = styled.div`
  height: 1px;
  margin: 16px -30px 11px -16px;
  ${ltDesktop} {
    margin-right: -20px;
  }
  ${ltTablet} {
    margin-right: -15px;
  }

  background: #ecf1f4;
`

const Row = styled.div`
  margin-left: auto;
  padding-right: 30px;
  flex-shrink: 0;
  align-items: flex-end;
  ${ltDesktop} {
    margin-top: auto;
  }
  ${btDesktop} {
    display: grid;
    grid-template-columns: 90px minmax(90px, auto);
    align-items: center;
  }
`

const RowBody = styled.div`
  margin-left: auto;
  padding-right: 70px;
  flex-shrink: 0;
  align-items: flex-end;
  ${ltDesktop} {
    margin-top: auto;
  }
  ${btDesktop} {
    display: grid;
    grid-template-columns: 90px minmax(90px, auto);
    align-items: center;
  }
`

const Label = styled.div`
  ${ltDesktop} {
    display: none;
  }
  font-family: 'Open Sans';
  font-size: 12px;
  color: #8e8e8e;
`

const Count = styled.div`
  padding-left: 90px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #8b949b;
`

const Delivery = styled.div`
  margin-top: auto;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #ff9f63;
`

const DeliveryLabel = styled.div`
  ${ltTablet} {
    display: none;
  }
`

const Footer = styled.div`
  ${btTablet} {
    margin-left: 26px;
  }
  display: flex;
  @media screen and (min-width: 768px) {
    margin-left: 30px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 15px;
  }
`

const BodyConatiner = styled.div`
  background: white;
  display: flex;
  max-width: 890px;
  height: 165px;
  border-radius: 6px;
  flex-direction: column;
`

const Heading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: #35414c;
  padding: 17px 0 0 30px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`

const BodyBottomRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`

const formatDate = (date: Date) => format('dd.MM.yyyy до kk:mm ')(date)

type Item = React.FC<Deliveries>
export const Item: Item = ({
  id,
  isSelect,
  count,
  product,
  address: { address },
  date: dateBase,
  // @ts-ignore
  index,
  ...item
}) => {
  const patchDelivery = useAction(patchDeliveryBase)
  const date = React.useMemo(() => new Date(dateBase), [dateBase])

  return (
    <>
      <Container>
        {/* <Header>
        <Checkbox
          checked={Boolean(isSelect)}
          onChange={(checked) => patchDelivery({ id, isSelect: checked })}
        />
      </Header> */}
        <NavLink to={`/basket/${product.id}`}>
          <WrapperInfo>
            <Image src={product.image} />
            <Info>
              <Title>{product.title}</Title>
              <PriceContainer>
                {formatCount(product.price)} ₽{' '}
                <SpanMeasurment>{product.measurement}</SpanMeasurment>
              </PriceContainer>
            </Info>
          </WrapperInfo>
        </NavLink>
      </Container>
      <BodyConatiner>
        <Heading>Отправление №{index ? index + 1 : 1}</Heading>
        <Address>{address}</Address>
        <BodyBottomRow>
          {isValid(date) && <Delivery>{formatDate(date)}</Delivery>}
          {!isValid(date) && (
            <Delivery>
              c &nbsp;
              {
                // @ts-ignore
                item.rangeParams.startDate
              }
              &nbsp; по &nbsp;
              {
                // @ts-ignore
                item.rangeParams.endDate
              }
            </Delivery>
          )}
          <RowBody>
            <Label>Кол-во:</Label>
            <Count>
              {formatCount(count)} {product.measurement}
            </Count>
          </RowBody>
        </BodyBottomRow>
        <Hr />
        <Footer>
          <ButtonDelete ids={[id]}>Удалить</ButtonDelete>
          <LinkEdit to={`/basket/${product.id}`}>
            <IconEdit />
            Изменить
          </LinkEdit>
          <Row>
            <Label>Цена:</Label>
            <Price>{formatCount(product.price * count)} ₽</Price>
          </Row>
        </Footer>
      </BodyConatiner>
    </>
  )
}
