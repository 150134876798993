import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import InputMask from 'react-input-mask'
import isBefore from 'date-fns/fp/isBefore'

const StyledTextInput = styled.input`
  padding: 10px 15px 6px 15px;
  background: #ffffff;
  border-width: 4px;
  border-color: transparent;
  border-radius: 2px;
  border: 1px solid #dbdfe5;
  color: #8b949b;
  width: 100%;
  height: 38px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(2, 9, 75, 0.12);
  outline: none;
`

const Container = styled.div`
  width: 175px;
  max-height: 38px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(2, 9, 75, 0.12);
  border: 1px solid #dbdfe5;
`

const InputStartDay = ({
  startRangeDay,
  setStartRangeDay,
  checkedRange,
  weekend,
}) => {
  const [showInit, setShowInit] = React.useState(true)
  const [inputValue, setInputValue] = React.useState('')

  const changeInputValue = (value) => {
    setInputValue(value)
  }

  React.useEffect(() => {
    setInputValue(moment(startRangeDay).format('DD-MM-YYYY'))
    if (new Date(startRangeDay) < new Date()) {
      setStartRangeDay('')
    }
  }, [startRangeDay])

  const onBlurValue = (inputValue) => {
    setStartRangeDay(moment(inputValue, 'DD-MM-YYYY').format('YYYY-MM-DD'))
  }

  // React.useEffect(() => {
  //   if (weekend.includes(moment(startRangeDay).format('MM/DD/YYYY'))) {
  //     setStartRangeDay('')
  //   }
  // }, [startRangeDay, weekend])

  return (
    <>
      <Container>
        <InputMask
          value={inputValue}
          onChange={(event) => changeInputValue(event.target.value)}
          mask="99.99.9999"
          maskPlaceholder="__.__.____"
          onBlur={() => onBlurValue(inputValue)}
        >
          <StyledTextInput />
        </InputMask>
      </Container>
    </>
  )
}

export default InputStartDay
