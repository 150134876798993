import { useLocation, useNavigate } from '@reach/router'

export const useCloseLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return () => {
    if (location) {
      navigate?.(location.pathname.replace('/login', ''), { replace: true })
    }
  }
}
