import React from 'react'
import styled from 'styled-components'
import { DropFiltersButtonTypes } from '../../modules/product'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88%;
  max-width: 382px;
  height: 38px;
  margin: 20px 0 20px 20px;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  color: #495560;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  cursor: pointer;

  @media screen and (min-width: 1440px) {
    margin-left: 30px;
    width: 81%;
  }

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 81%;
  }
`

export const DropFiltersButton: React.FC<DropFiltersButtonTypes> = ({
  setSelectedSubcategoriesIds,
  setSelectedSortingTypes,
  setPriceInputValue,
  setLocalInputValueMin,
  setLocalInputValueMax,
  setLimit,
  prices,
}) => {
  const dropFilters = React.useCallback(() => {
    setSelectedSubcategoriesIds([])

    setSelectedSortingTypes({ sortKey: '', sortDirection: '' })
    setPriceInputValue({
      min: prices?.min ? prices?.min : 0,
      max: prices?.max ? prices?.max : 0,
    })
    setLocalInputValueMin(prices?.min ? prices?.min : 0)
    setLocalInputValueMax(prices?.max ? prices?.max : 0)
    setLimit(32)
  }, [])

  return <Container onClick={dropFilters}>Сбросить фильтрацию</Container>
}
