import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import { HitProducts } from './HitProducts/HitProducts'
import { PromoProducts } from './PromoProducts/PromoProducts'
import { TopProducts } from './TopProducts/TopProducts'

type MainPage = React.FC<RouteComponentProps>

const View = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  
  max-width: 1386px;
  min-width: 1386px;
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    max-width: 875px;
    min-width: 875px;
  }
  @media screen and (min-width: 768px) and (max-width: 1139px) {
    max-width: 737px;
    min-width: 737px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    max-width: 586px;
    min-width: 586px;
  }
  @media screen and (max-width: 619px) {
    max-width: 290px;
    min-width: 290px;
  }
`

export const MainPage: MainPage = () => {
  return (
    <View>
      <PromoProducts />
      <Container>
        <TopProducts />
        <HitProducts />
      </Container>
    </View>
  )
}
