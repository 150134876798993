import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'

import { Handle, Track, TooltipRail } from './components' // example render components - source below

// Надо поменять типы в ts файле слайдера, везде убрать ReadOnly

type Prices = {
  max?: number
  min?: number
}

const sliderStyle: React.CSSProperties = {
  position: 'relative',
  width: '80%',
  margin: '10% 10%',
}

type Slider = {
  priceInputValue: Prices
  setPriceInputValue: Dispatch<SetStateAction<Prices>>
  prices: Prices
  localInputValueMin: number
  localInputValueMax: number
  setMaxFilterPrice: Dispatch<SetStateAction<string>>
  setMinFilterPrice: Dispatch<SetStateAction<string>>
  setLocalInputValueMin: Dispatch<SetStateAction<number>>
  setLocalInputValueMax: Dispatch<SetStateAction<number>>
}

class RangeSlider extends React.Component<Slider, {}> {
  constructor(props: Slider) {
    super(props)

    this.state = {}
  }

  onChange = (values: Array<number>) => {
    const [min, max] = values
    this.props.setLocalInputValueMin(min)
    this.props.setLocalInputValueMax(max)
    // this.props.setPriceInputValue({ min, max })
    this.props.setMinFilterPrice(min.toString())
    this.props.setMaxFilterPrice(max.toString())
  }

  onUpdate = (values: Array<number>) => {
    const [min, max] = values
    this.props.setLocalInputValueMin(min)
    this.props.setLocalInputValueMax(max)
  }

  render() {
    const { max: domainMax, min: domainMin } = this.props.prices
    const { max, min } = this.props.priceInputValue

    return (
      <div style={{ height: 1, width: '100%' }}>
        <Slider
          mode={1}
          step={1}
          domain={[Number(domainMin), Number(domainMax)]}
          rootStyle={sliderStyle}
          // @ts-ignore
          onChange={(value) => this.onChange(value)}
          onUpdate={(
            value, // @ts-ignore
          ) => this.onUpdate(value)}
          values={[Number(min), Number(max)]}
        >
          <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => (
              <div className="slider-handles">
                {handles?.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[Number(domainMin), Number(domainMax)]}
                    isActive={handle.id === activeHandleID}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks?.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    )
  }
}

export default RangeSlider
