import React from 'react'
import styled from 'styled-components'
import { withFullHeight } from './withFullHeight'

const Container = withFullHeight()(styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;

  position: fixed;
  left: 0;
  top: 0;

  overflow: hidden;

  z-index: 10000;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    overflow-y: scroll;
  }
`)

const ServiceScrollBody = {
  count: 0,
  openModal() {
    this.count++
    document.body.style.overflow = 'hidden'
  },
  closeModal() {
    if (!--this.count) {
      document.body.style.overflow = ''
    }
  },
}

type ModalFC = React.FC<
  {
    visible: boolean
    onRequestClose?: (close: false) => void
  } & React.HTMLAttributes<HTMLDivElement>
>

export const Modal: ModalFC = ({
  visible,
  children,
  onRequestClose: onRequestCloseBase = () => {},
  ...props
}) => {
  const refMouseDownElement = React.useRef<EventTarget | null>(null)

  const onRequestClose = React.useRef(onRequestCloseBase)
  onRequestClose.current = onRequestCloseBase

  React.useEffect(() => {
    if (visible) {
      ServiceScrollBody.openModal()
      return () => ServiceScrollBody.closeModal()
    }
  }, [visible])

  React.useEffect(() => {
    const handlerKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onRequestClose.current(false)
      }
    }
    document.addEventListener('keyup', handlerKeyDown)
    return () => document.removeEventListener('keyup', handlerKeyDown)
  }, [])

  return visible ? (
    // @ts-ignore
    <Container
      // @ts-ignore
      onMouseUp={(event) => {
        if (
          event.target === event.currentTarget &&
          event.target === refMouseDownElement.current
        ) {
          onRequestClose.current(false)
        }
        refMouseDownElement.current = null
      }}
      // @ts-ignore
      onMouseDown={(event) => {
        refMouseDownElement.current = event.target
      }}
      // @ts-ignore
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      {children}
    </Container>
  ) : null
}

export const ModalDark = styled(Modal)`
  background-color: rgba(53, 65, 76, 0.6);
`
