import React from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from './useOnClickOutside'
import { Profile, dropDownData, dropDownExit, dropDownOrders } from '../images'
import { logOut, useIsAuthorization } from '../../modules/authorization'
import { Link, useLocation } from '@reach/router'
import { deleteAllDeliveries } from '../../modules/basket/store'
import { useAction } from '@reatom/react'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1440px) {
    margin-right: 45px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-right: 30px;
  }
  @media screen and (min-width: 620px) and (max-width: 767px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 619px) {
    display: none;
  }
`

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 619px) {
    display: none;
  }
`
const ProfileContainerAuth = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 619px) {
    display: none;
  }
`

const ProfileText = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  color: #35414c;
`

const ProfileImage = styled(Profile)`
  width: 17px;
  height: 20px;
  stroke: #35414c;
`

const DropDownContainer = styled.div`
  position: absolute;
  width: 214px;
  padding-bottom: 25px;
  background-color: white;
  box-shadow: 0 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 2px;
  z-index: 10000;
  @media screen and (min-width: 720px) and (max-width: 1696px) {
    right: 5px;
  }
  @media screen and (min-width: 720px) and (max-width: 767px) {
    top: 45px;
  }
  @media screen and (max-width: 719px) {
    display: none;
  }
`

const DropDownDirection = styled.div`
  display: flex;
  flex-direction: column;
`

const DropDownItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;
  text-decoration: none;
`

const DropDownItemImages = styled.span`
  margin-left: 22px;
  :first-child {
    width: 16.12px;
    height: 14.33px;
  }
`

const DropDownItemText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #35414c;
  :last-child {
    padding-left: 21px;
  }
`

const ProfileDropDown = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const isAuth = useIsAuthorization()
  const deleteDeliveries = useAction(deleteAllDeliveries)

  const items = [
    {
      image: dropDownData,
      text: 'Данные профиля',
      link: '/profile',
    },
  ]
  if (isAuth) {
    items.push({
      image: dropDownExit,
      text: 'Заказы',
      link: '/orders',
    })
  }

  const root = useOnClickOutside(() => setIsOpen(false))
  const logoutUser = () => {
    logOut()
    deleteDeliveries()
  }
  return (
    <Container onClick={root}>
      {!isAuth && (
        <ProfileContainerAuth to={`${location.pathname}/login`}>
          <ProfileImage />
          <ProfileText>Войти</ProfileText>
        </ProfileContainerAuth>
      )}
      {isAuth && (
        <div>
          <ProfileContainer onClick={() => setIsOpen(!isOpen)}>
            <ProfileImage />
            <ProfileText>Профиль</ProfileText>
          </ProfileContainer>
          {isOpen && (
            <DropDownContainer onClick={() => setIsOpen(false)}>
              {items?.map((item, i) => (
                <DropDownDirection key={i}>
                  <DropDownItem to={item.link}>
                    <DropDownItemImages>
                      <img src={item.image} alt={item.text} />
                    </DropDownItemImages>
                    <DropDownItemText>{item.text}</DropDownItemText>
                  </DropDownItem>
                </DropDownDirection>
              ))}
              <DropDownDirection>
                <DropDownItem
                  to="/"
                  // @ts-ignore
                  onClick={logoutUser}
                >
                  <DropDownItemImages>
                    <img src={dropDownOrders} alt="войти" />
                  </DropDownItemImages>
                  <DropDownItemText>Выйти</DropDownItemText>
                </DropDownItem>
              </DropDownDirection>
            </DropDownContainer>
          )}
        </div>
      )}
    </Container>
  )
}

export default ProfileDropDown
