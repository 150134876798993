import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { formatCount } from '../../../utils'
import { Product as ProductModel } from '../../../modules/product'
import { addButton, emptyProduct } from '../../images'
import { useAction } from '@reatom/react'
import { removeReorder } from '../../../modules/basket'

type ImageProps = {
  image?: string
}

interface isDiscount {
  isDiscount: boolean
  discountSize: number
}

interface DiscountSize {
  discountSize: number
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 125px;
  width: 1043px;
  background: #ffffff;
  margin-bottom: 10px;

  box-shadow: 0px 6px 14px rgba(10, 21, 128, 0.08);
  border-radius: 6px;

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    min-width: 785px;
    width: 98.5%;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    height: 125px;

    min-width: 870px;
    width: 97.5%;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    height: 125px;
    width: 97.2%;
    min-width: 670px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    width: 97.3%;
    height: 100px;
    min-width: 434px;
  }

  @media screen and (min-width: 381px) and (max-width: 531px) {
    height: 129px;
    width: 95.7%;
  }
  @media screen and (min-width: 320px) and (max-width: 380px) {
    height: 125px;
    width: 95%;
    min-width: 290px;
    margin-bottom: 14px;
  }
`

const ItemRowContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
`

const ItemRowContainerWithoutButton = styled.div`
  display: flex;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 125px;
  position: relative;
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    height: 125px;

    width: 141px;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    height: 125px;

    width: 141px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    height: 100px;
    width: 110px;
  }
  @media screen and (min-width: 381px) and (max-width: 531px) {
    height: 129px;
    width: 140px;
  }
  @media screen and (min-width: 320px) and (max-width: 380px) {
    height: 125px;
    width: 140px;
  }
`

const DiscountSizeContainer = styled.div<DiscountSize>`
  width: 64px;
  height: 32px;
  background-color: #f0640c;
  position: absolute;
  top: 10px;
  right: 10px;
  display: ${({ discountSize }) =>
    discountSize === 0 || discountSize == 100 ? 'none' : 'inline'};
`

const DiscountSize = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding-top: 7px;
`

const ImageContainer = styled.img<ImageProps>`
  width: 220px;
  height: 125px;
  border-radius: 4px;

  object-fit: cover;
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    height: 125px;

    width: 141px;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    height: 125px;

    width: 141px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    height: 100px;
    width: 110px;
  }
  @media screen and (min-width: 381px) and (max-width: 531px) {
    height: 129px;
    width: 140px;
  }

  @media screen and (min-width: 320px) and (max-width: 380px) {
    height: 125px;
    width: 140px;
  }
`

const TextContainer = styled.div`
  padding-left: 25px;
  @media screen and (min-width: 532px) and (max-width: 719px) {
    padding-left: 16px;
  }
`

const Title = styled.div`
  padding-bottom: 14px;
  padding-top: 23px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #495560;

  @media screen and (min-width: 532px) and (max-width: 719px) {
    font-size: 15px;
    line-height: 18px;
    padding-top: 18px;
    padding-bottom: 2px;
    width: 80%;
  }

  @media screen and (min-width: 320px) and (max-width: 531px) {
    padding-bottom: 2px;
    padding-top: 11px;
    font-size: 12px;
    line-height: 14px;
    color: #35414c;
  }
`

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 560px;

  line-clamp: 2;
  color: #8b949b;
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    width: 320px;
    line-height: 1.5em;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    width: 300px;
    line-height: 1.5em;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    width: 290px;
    line-height: 1.5em;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  @media screen and (min-width: 530px) and (max-width: 719px) {
    width: 90%;
    font-size: 12px;

    line-height: 1.5em;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  @media screen and (min-width: 320px) and (max-width: 531px) {
    display: none;
  }
`

const PriceForMobile = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 400px) {
    padding-top: 32px;
  }
  @media screen and (min-width: 532px) {
    display: none;
  }
`

const MobileButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 14px;
  text-decoration: none;
  @media screen and (min-width: 532px) {
    display: none;
  }
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid #dbdfe5;
  width: 192px;
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    width: 170px;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    width: 170px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    min-width: 126px;
  }
  @media screen and (max-width: 531px) {
    display: none;
  }
`

const Price = styled.div<isDiscount>`
  display: flex;
  align-items: center;
  padding-top: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? '0' : '17px'};
  padding-left: 35px;
  @media screen and (min-width: 532px) and (max-width: 719px) {
    padding-left: 17px;
  }
`

const DicsountContainer = styled.div`
  display: flex;
`

const DiscountPrice = styled.div<isDiscount>`
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'inline' : 'none'};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f0640c;
  padding-top: 17px;
  padding-left: 35px;
  @media screen and (min-width: 532px) and (max-width: 719px) {
    padding-left: 17px;
    padding-top: 10px;
  }
  @media screen and (min-width: 320px) and (max-width: 531px) {
    padding-left: 3px;
    padding-top: 0;
  }
`

const PriceText = styled.div<isDiscount>`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? '15px' : '18px'};
  line-height: 25px;
  height: 26px;
  color: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? '#8B949B' : '#35414C'};
  text-decoration: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'line-through' : 'none'};

  @media screen and (max-width: 531px) {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #35414c;
  }
`

const Measurement = styled.div<isDiscount>`
  padding-left: 5px;
  padding-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'none' : 'inline'};
`

const DiscountMeasurement = styled.div<isDiscount>`
  padding-left: 3px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 11px;
  color: #8b949b;
  display: ${({ isDiscount, discountSize }) =>
    isDiscount && discountSize !== 0 ? 'inline' : 'none'};
  @media screen and (min-width: 532px) and (max-width: 719px) {
    padding-top: 17px;
  }
  @media screen and (min-width: 720px) {
    padding-top: 20px;
  }
`

const TopProductTriangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 76px;
  height: 76px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  background-color:  #52CF26;
  border-bottom-left-radius: 5px;
  transform: rotate(90deg);


  &:after {
    content: 'Хит продаж';
    text-align: center;
    color: white;
    position: absolute;
    bottom: 10px;
    right: 20px;
    transform: rotate(-135deg);
    font-size: 12px;
    text-transform: uppercase;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  width: 152px;
  margin-bottom: 15px;
  border-radius: 6px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(10, 21, 128, 0.1);
  }

  @media screen and (min-width: 1440px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    margin-left: 20px;
    width: 132px;
  }
  @media screen and (min-width: 720px) and (max-width: 919px) {
    margin-left: 20px;
    width: 132px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    width: 126px;
  }
`

const OrderText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-left: 16px;
  line-height: 18px;
  color: #f0640c;

  @media screen and (min-width: 532px) and (max-width: 719px) {
    order: 2;
    padding-right: 21px;
    padding-left: 0;
  }
`

const AddImage = styled.img.attrs({
  src: addButton,
})`
  width: 19px;
  height: 19px;
  @media screen and (min-width: 1439px) {
    margin-right: 17px;
  }
  @media screen and (min-width: 720px) and (max-width: 1439px) {
    margin-right: 17px;
  }
  @media screen and (min-width: 532px) and (max-width: 719px) {
    order: 1;
    margin-left: 16px;
  }
`

type ProductList = React.FC<Partial<ProductModel>>
export const ProductList: ProductList = ({
  title,
  measurement,
  description,
  price,
  image,
  id,
  discount,
  categoryTop,
  subcategoryTop
}) => {
  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
    ev.currentTarget.src = emptyProduct
  }
  const deleteReorder = useAction(removeReorder)
  const removeProductFromReorder = React.useCallback(() => deleteReorder(), [])

  // @ts-ignore

  const discountSize = Math.round((1 - discount / price) * 100)

  const priceString = formatCount(price ? price : 1)

  return (
    <Container>
      <ItemRowContainer to={`/basket/${id}`} onClick={removeProductFromReorder}>
        <ItemRowContainerWithoutButton>
          <ImageWrapper>
            {(categoryTop || subcategoryTop) && <TopProductTriangle/>}
            <DiscountSizeContainer discountSize={discountSize}>
              <DiscountSize>-{discountSize}%</DiscountSize>
            </DiscountSizeContainer>
            <ImageContainer src={image} onError={(e) => addDefaultSrc(e)} />
          </ImageWrapper>

          <TextContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>

            <PriceForMobile>
              <PriceText isDiscount={!!discount} discountSize={discountSize}>
                {priceString}₽
              </PriceText>
              <Measurement isDiscount={!!discount} discountSize={discountSize}>
                {measurement}
              </Measurement>
              <DiscountPrice
                isDiscount={!!discount}
                discountSize={discountSize}
              >
                {discount}₽
              </DiscountPrice>
              <DiscountMeasurement
                isDiscount={!!discount}
                discountSize={discountSize}
              >
                {measurement}
              </DiscountMeasurement>
            </PriceForMobile>
            <MobileButton>
              <AddImage />
              <OrderText>Заказать</OrderText>
            </MobileButton>
          </TextContainer>
        </ItemRowContainerWithoutButton>
        <RightSection>
          <DicsountContainer>
            <DiscountPrice isDiscount={!!discount} discountSize={discountSize}>
              {discount}₽
            </DiscountPrice>
            <DiscountMeasurement
              isDiscount={!!discount}
              discountSize={discountSize}
            >
              {measurement}
            </DiscountMeasurement>
          </DicsountContainer>
          <Price isDiscount={!!discount} discountSize={discountSize}>
            <PriceText isDiscount={!!discount} discountSize={discountSize}>
              {price} ₽
            </PriceText>
            <Measurement isDiscount={!!discount} discountSize={discountSize}>
              {measurement}
            </Measurement>
          </Price>
          <ButtonContainer>
            <OrderText>Заказать</OrderText>
            <AddImage />
          </ButtonContainer>
        </RightSection>
      </ItemRowContainer>
    </Container>
  )
}

export default ProductList
