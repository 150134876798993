import React from 'react'
import { Link } from '@reach/router'
import { statuses } from '../../../modules/orders/constants'
import styled from 'styled-components'
import { Basket, emptyProduct } from '../../images'
import { OrderInterface } from './Orders'
import moment from 'moment'
import { useAction } from '@reatom/react'
import { selectReorder } from '../../../modules/basket'

type OrderElements = React.FC<Partial<OrderInterface>>

const OrderElement = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 45px);
  box-sizing: border-box;
  margin-right: 45px;
  height: 110%;
  min-width: 465px;
  background-color: white;
  margin-top: 10px;
  border-radius: 6px;
  &:first-child {
    margin-top: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-right: 0;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    min-width: 300px;
    width: 100%;
    margin-right: 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
`

const OrderNumber = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  text-decoration: none;
  cursor: pointer;
  padding-left: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 12px;
  }
`

const StatusContainer = styled.div`
  display: flex;
  padding-right: 30px;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const OrderStatus = styled.div<CircleProps>`
  padding-right: 10px;
  color: ${({ color }) => color};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
`

type CircleProps = {
  color: string
}

const Circle = styled.div<CircleProps>`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`

const OrderTitle = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #35414c;
  padding-top: 4px;
  padding-left: 30px;
  text-decoration: none;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`
const OrderMiddle = styled(Link)`
  display: flex;
  padding-top: 13px;
  padding-left: 30px;
  justify-content: space-between;
  padding-bottom: 16px;
  text-decoration: none;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`
const OrderMiddleLeft = styled.div`
  display: flex;
`

const OrderImage = styled.img`
  width: 54.48px;
  height: 57.12px;
  border-radius: 6px;
`

const OrderText = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderAddress = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  padding-left: 16px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px;
  }
`

const OrderDate = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #ff9f63;
  padding-left: 16px;
  padding-top: 25px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderDateMobile = styled.div`
  font-family: 'Open Sans', sans-serif;

  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #ff9f63;
  padding-left: 16px;
  padding-top: 25px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const OrderMiddleRight = styled.div`
  display: flex;
  align-items: flex-end;
`

const OrderQuanityText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #8e8e8e;
  padding-right: 90px;
  padding-top: 7px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`

const OrderQuanity = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #8b949b;
  padding-right: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const OrderBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f4f6;
  height: 38px;
`

const OrderButton = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 30px;
  text-decoration: none;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
  }
`

const DisabledOrderButton = styled.div`
  display: flex;
  cursor: not-allowed;
  align-items: center;
  padding-left: 30px;
  text-decoration: none;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
`

const OrderButtonText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #f0640c;
  padding-left: 10px;
`

const OrderBottomRight = styled.div`
  display: flex;
  align-items: center;
`

const OrderPriceText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #8e8e8e;
  padding-right: 80px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
`
const OrderPrice = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #35414c;
  padding-right: 30px;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
  }
`

const BasketImage = styled(Basket)`
  width: 16.6px;
  height: 17px;
  stroke: #f0640c;
`

export const OrderElements: OrderElements = (order) => {
  const {
    id,
    product,
    status,
    address,
    date,
    count,
    productId,
    price,
    orderNumber,
    orderDisabled
  } = order
  const addressObject = JSON.parse(address ? address : '')

  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
    ev.currentTarget.src = emptyProduct
  }

  const deliveryDate = React.useMemo(() => {
    const { date: day } = JSON.parse(date ? date : '')
    return moment(day).format('DD.MM.YYYY')
  }, [date])

  const productImage = React.useMemo(() => {
    //@ts-ignore
    const uri = product?.files[0]?.uri ? product?.files[0]?.uri : ''
    if (uri?.slice(0, 1) === 'f') {
      return `http://prostore.su/${uri}`
    }

    return uri
  }, [order])

  const reorder = useAction(selectReorder)
  // @ts-ignore

  const selectProductToReorder = React.useCallback(() => reorder(order), [
    order,
  ])

  // @ts-ignore
  const color = statuses[status]?.color || '#FF9F63'
  // @ts-ignore
  const translatedStatus = statuses[status]?.translatedStatus || 'Обрабатывается'

  const renderOrderButton = () => {
    if(orderDisabled) {
      return (
        <DisabledOrderButton>
          <BasketImage style={{stroke:'#8e8e8e'}} />
          <OrderButtonText style={{color:'#8e8e8e'}}>Товар отсуствует</OrderButtonText>
        </DisabledOrderButton>
      )
    }
    return (
      <OrderButton
        onClick={selectProductToReorder}
        to={`/basket/${productId}`}
      >
        <BasketImage />
        <OrderButtonText>Заказать еще раз</OrderButtonText>
      </OrderButton>
    )
  }

  return (
      <OrderElement>
        <Header>
          <OrderNumber to={`/order/${id}`} state={order}>
            Заказ № {orderNumber}
          </OrderNumber>
          <StatusContainer>
            <OrderStatus color={color}>{translatedStatus}</OrderStatus>
            <Circle color={color}/>
          </StatusContainer>
        </Header>
        <OrderTitle to={`/order/${id}`} state={order}>
          {product?.title}
        </OrderTitle>
        <OrderMiddle to={`/order/${id}`} state={order}>
          <OrderMiddleLeft>
            <OrderImage
              // @ts-ignore
              src={productImage ? productImage : emptyProduct}
              onError={(e) => addDefaultSrc(e)}
            />

            <OrderText>
              <OrderAddress>{addressObject.address}</OrderAddress>
              <OrderDateMobile>25.05.2020</OrderDateMobile>
              <OrderDate>Будет доставлен {deliveryDate}</OrderDate>
            </OrderText>
          </OrderMiddleLeft>
          <OrderMiddleRight>
            <OrderQuanityText>Кол-во:</OrderQuanityText>
            <OrderQuanity>
              {count} {product?.measurement}
            </OrderQuanity>
          </OrderMiddleRight>
        </OrderMiddle>
        <OrderBottom>
          {renderOrderButton()}
          <OrderBottomRight>
            <OrderPriceText>Цена:</OrderPriceText>
            <OrderPrice>{Number(price)} ₽</OrderPrice>
          </OrderBottomRight>
        </OrderBottom>
      </OrderElement>
  )
}
