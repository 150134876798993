import React from 'react'
import { useLocation, useNavigate } from '@reach/router'
import styled from 'styled-components'
import { Field, Form as FormFinal } from 'react-final-form'

import { Container, Label, Title } from '../commons'
import { useCloseRegistration } from './utils'
import {
  Button,
  Error,
  InputMaskFinal as InputMaskFinalBase,
  InputTextFinalWithError,
} from '../../../../commons'
import { RequestsAuthorization } from '../../../../../modules/authorization'
import { refForm } from './Registration'
import { FORM_ERROR } from 'final-form'
import { normalizePhone } from '../../../../../utils'
import { checkPhone } from '../../../../../modules/authorization/Requests'

const ButtonSubmit = styled(Button)`
  margin-top: 30px;
`

const InputTextFinal = styled(InputTextFinalWithError)`
  margin-top: 8px;
  text-align: center;
`

const InputMaskFinal = styled(InputMaskFinalBase)`
  margin-top: 8px;
  text-align: center;
`

const LabelOne = styled(Label)`
  margin-bottom: 7px;
`
const LabelTwo = styled(Label)`
  margin-bottom: 17px;
`

type StepFormFC = React.FC<{
  nextStep: (x: { phone: string }) => void
}>
export const StepForm: StepFormFC = ({ nextStep }) => {
  const closeRegistration = useCloseRegistration()
  const location = useLocation()
  const navigate = useNavigate()
  // @ts-ignore
  const phone = location?.state?.phone ?? ''
  return (
    <FormFinal
      onSubmit={(body) => {
        const form = {
          ...body,
          phone: normalizePhone(body.phone),
        }
        checkPhone(form.phone)
          //@ts-ignore
          .then(phoneIsRegistered=> {
            if(phoneIsRegistered){
              return  navigate?.(location.pathname.replace('/registration', '/user-exist'), {
                state: {
                  phone: form.phone,
                },
              })
            }
            refForm.form = form
            // @ts-ignore
            return RequestsAuthorization.registration(form).then(({ status }) => {
              if (status === 400) {
                return { [FORM_ERROR]: 'Такой пользователь уже существует' }
              }
              nextStep({ phone: normalizePhone(body.phone) })
            })
          })
      }}
      initialValues={{ phone, ...refForm.form }}
      render={({ handleSubmit, submitting, submitError }) => (
        // @ts-ignore
        <Container
          // @ts-ignore
          onSubmit={handleSubmit}
          onRequestClose={closeRegistration}
          onBack={() =>
            navigate?.(location.pathname.replace('/registration', '/login'), {
              // @ts-ignore
              state: { phone },
            })
          }
        >
          <Title>Регистрация</Title>
          <LabelOne>
            ФИО
            <Field
              validate={(fio: string = '') => {
                if (fio.length === 0) {
                  return 'Обязательное поле'
                }
                if (fio.split(' ').filter((x) => x !== '').length !== 3) {
                  return 'Иванов Иван Иванович'
                }
                return ''
              }}
              name="fio"
              placeholder="Иванов Иван Иванович"
              component={InputTextFinal}
            />
          </LabelOne>
          <LabelTwo>
            Номер телефона
            <Field
              validate={(phone: string) =>
                normalizePhone(phone).length < 11 ? 'Обязательное поле' : ''
              }
              name="phone"
              mask="+{7} (000) 000-00-00"
              lazy={false}
              component={InputMaskFinal}
            />
          </LabelTwo>
          <Title>Данные об организации</Title>
          <LabelOne>
            Тип организации
            <Field
              validate={(fio: string = '') =>
                fio.length === 0 ? 'Обязательное поле' : ''
              }
              name="organizationForm"
              placeholder="ООО"
              component={InputTextFinal}
            />
          </LabelOne>
          <LabelOne>
            Название организации
            <Field
              validate={(fio: string = '') =>
                fio.length === 0 ? 'Обязательное поле' : ''
              }
              name="organizationTitle"
              placeholder="Организация"
              component={InputTextFinal}
            />
          </LabelOne>
          <Error>{submitError}</Error>
          <ButtonSubmit type="submit" disabled={submitting}>
            Зарегистрироваться
          </ButtonSubmit>
        </Container>
      )}
    />
  )
}
