import React from 'react'
import styled from 'styled-components'
import ProductTable from './ProductTable'
import ProductList from './ProductList'
import { PreLoader } from '../../commons/PreLoader/PreLoader'

import { Filters } from './Filters'
import { Pagination } from '../../commons'
import MobileFiltersDropDown from '../../commons/MobileFiltersDropDown'
import { MobileSubCategoriesFilter } from '../../commons/MobileSubCategoriesFilter'
import { ProductsLogicTypes } from '../../../modules/product'
import { emptyProducts } from '../../images'
import { TreeTab } from './TreeCatalogue/TreeTab'
import { CategoryTableItem } from './TreeCatalogue/CategoryTableItem'

interface LinkProps {
  isActive: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  @media screen and (min-width: 1440px) {
    justify-content: center;
  }
`

const HeadContainer = styled.div`
  display: flex;
  @media screen and (min-width: 720px) {
    display: none;
  }
  @media screen and (max-width: 379px) {
    margin-left: 10px;
  }
  @media screen and (min-width: 375px) and (max-width: 719px) {
    justify-content: left;
    margin-right: 17px;
  }
`

const FiltersContainer = styled.div`
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 1139px) {
    display: none;
  }
`

const MobileFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;
  @media screen and (min-width: 720px) {
    display: none;
  }
`

const MobileSubCategoriesContainer = styled.div`
  min-width: 242px;
  width: 75%;
  @media screen and (min-width: 375px) and (max-width: 619px) {
    min-width: 290px;
  }
`

const DarkTheme = styled.div<LinkProps>`
  background-color: #35414c;
  z-index: 5;
  position: fixed;
  size: fill;
  height: 100%;
  width: ${({ isActive }) => (isActive ? '100%' : '0')};
  opacity: 0.7;

  @media screen and (min-width: 720px) {
    display: none;
  }
`

const Products = styled.div`
  margin: 30px 0 0 30px;
  width: 100%;

  @media screen and (min-width: 1440px) {
    min-width: 1053px;
    max-width: 1053px;
    margin: 30px 25px 0 25px;
  }
  @media screen and (min-width: 1220px) and (max-width: 1400px) {
    margin: 30px 0 0 50px;
  }

  @media screen and (min-width: 681px) and (max-width: 1139px) {
    margin: 30px 0 0 0;
  }

  @media screen and (min-width: 620px) and (max-width: 767px) {
    margin: 12px 0 0 15px;
    width: 100%;
  }

  @media screen and (min-width: 320px) and (max-width: 619px) {
    margin: 12px 0 0 10px;
    width: 100%;
  }
`

const ProductsTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 149px;
  @media screen and (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 149px;
  }
  @media screen and (min-width: 920px) and (max-width: 1139px) {
    width: 100%;
    padding-bottom: 149px;
    display: grid;
    grid-template-columns: repeat(4, 212px);
    grid-gap: 0 10px;
    justify-content: center;
  }

  @media screen and (min-width: 681px) and (max-width: 919px) {
    width: 100%;
    padding-bottom: 149px;
    display: grid;
    grid-template-columns: repeat(3, 212px);
    grid-gap: 0 10px;
    justify-content: center;
  }
  @media screen and (min-width: 430px) and (max-width: 680px) {
    width: 100%;
    padding-bottom: 149px;
    justify-content: center;
    :after {
      content: '';
      flex: 0 0 50%;
      max-width: 210px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 429px) {
    width: 100%;
    padding-bottom: 149px;

    justify-content: center;
    :after {
      content: '';
      flex: 0 0 50%;
      max-width: 150px;
    }
  }
`

const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 149px;
`

const PaginationContainer = styled.div`
  display: flex;
`

const EmptyProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;

  @media screen and (min-width: 330px) and (max-width: 767px) {
    padding-top: 130px;
    margin-right: 15px;
  }
  @media screen and (min-width: 320px) and (max-width: 329px) {
    padding-top: 130px;
    margin-left: -15px;
  }
`
const EmptyProductsImage = styled.img.attrs({ src: emptyProducts })`
  height: 78px;
  width: 80px;
`
const EmptyProductsText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #495560;
  font-size: 20px;
  line-height: 110%;
  padding-top: 22px;
`

const EmptyProductsBottomText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #495560;
  font-size: 16px;
  line-height: 100%;
  padding-top: 18px;
`

const PreLoaderContainer = styled.div`
  min-width: 1053px;
  max-width: 1053px;
  display: flex;
  justify-content: center;
  padding-top: 250px;
`

const CategoriesTableContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 1140px) { 
    width: auto;
  }
`
const CategoriesTableWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const CategoriesTable = styled.div`
  display: flex;

  flex-wrap: wrap;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  
  
  @media screen and (min-width: 1140px) { 
    width: 1017px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) { 
    width: 710px; 
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (min-width: 520px) and (max-width: 719px) {    
    width: 496px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  @media screen and (max-width: 519px) {
    width: 296px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
`

export const Catalogue: React.FC<ProductsLogicTypes> = ({
  products,
  activeCategoryTitle,
  subcategories,
  offset,
  limit,
  setOffset,
  total,
  setSelectedSubcategoriesIds,
  selectedSubcategoriesIds,
  prices,
  priceInputValue,
  setMaxFilterPrice,
  setMinFilterPrice,
  setPriceInputValue,
  setSelectedSortingTypes,
  isTableView,
  setRowViewCallback,
  setTableViewCallback,
  setLimit,
  selectedSortingTypes,
  subcategoriesIds,
  isLoading,
  activeCategoryId,
  setActiveCategoryId,
  currentCatalogue,
  setCurrentCatalogue
}) => {
  const [isMobileFiltersOpen, setMobileFilters] = React.useState(false)
  // const [isMobileSubCategoriesOpen, setMobileSubCategories] = React.useState(
  //   false,
  // )
  const renderProducts = () => {
    if(isLoading) return (
      <PreLoaderContainer>
        <PreLoader />
      </PreLoaderContainer>
    )
    if(!products.length) return (
      <EmptyProducts>
        <EmptyProductsImage />
        <EmptyProductsText>
          Товары временно отсутствуют
        </EmptyProductsText>
        <EmptyProductsBottomText>
          Мы уже спешим это исправить
        </EmptyProductsBottomText>
      </EmptyProducts>
    )
    const sortedProducts = [...products].sort((a,b)=> (a.categoryTop === b.categoryTop)? 0 : a.categoryTop? -1 : 1)
    if(isTableView) return (
      <ProductsTable>
        {sortedProducts?.map((product) => (
          <ProductTable key={product.id} {...product} />
        ))}
      </ProductsTable>
    )
      return (
        <ProductsList>
          {sortedProducts?.map((product) => (
            <ProductList key={product.id} {...product} />
          ))}
        </ProductsList>
      )
  }
  const hasSub = React.useMemo(() => {
    return (
      subcategories.length > 0
    )
  }, [subcategories])

  return (
    <Container>
      <DarkTheme isActive={isMobileFiltersOpen}> </DarkTheme>
      <FiltersContainer>
        { hasSub
        ? 
        <TreeTab
          setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
          subcategories={subcategories}
          setActiveCategoryId={setActiveCategoryId}          
          setCurrentCatalogue={setCurrentCatalogue}
          currentCatalogue={currentCatalogue}
        />
        : 
        <Filters
          setSelectedSortingTypes={setSelectedSortingTypes}
          selectedSubcategoriesIds={selectedSubcategoriesIds}
          setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
          activeCategoryTitle={activeCategoryTitle}
          subcategories={subcategories}
          prices={prices}
          priceInputValue={priceInputValue}
          setMaxFilterPrice={setMaxFilterPrice}
          setMinFilterPrice={setMinFilterPrice}
          setPriceInputValue={setPriceInputValue}
          setTableViewCallback={setTableViewCallback}
          setRowViewCallback={setRowViewCallback}
          isTableView={isTableView}
          setLimit={setLimit}
          limit={limit}
          selectedSortingTypes={selectedSortingTypes}
          isActive={products.length > 0}
        />}
      </FiltersContainer>  
      { hasSub ?
      <CategoriesTableContainer>
        <CategoriesTableWrapper>
          <CategoriesTable>
            {subcategories?.map((v) => (
                <CategoryTableItem key={v.id} {...v }
                  setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
                  setActiveCategoryId={setActiveCategoryId} />
              ))}
          </CategoriesTable>
        </CategoriesTableWrapper>
      </CategoriesTableContainer>
      : 
      <Products>        
        <HeadContainer>
          {/* <MobileSubCategoriesContainer>
            <MobileSubCategoriesFilter
              setMobileSubCategories={setMobileSubCategories}
              isMobileSubCategoriesOpen={isMobileSubCategoriesOpen}
              selectedSubcategoriesIds={selectedSubcategoriesIds}
              setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
              subcategories={subcategories}
              subcategoriesIds={subcategoriesIds}
            />
          </MobileSubCategoriesContainer> */}
          <MobileFiltersContainer>
            <MobileFiltersDropDown
              prices={prices}
              priceInputValue={priceInputValue}
              setPriceInputValue={setPriceInputValue}
              setMaxFilterPrice={setMaxFilterPrice}
              setMinFilterPrice={setMinFilterPrice}
              setTableViewCallback={setTableViewCallback}
              setRowViewCallback={setRowViewCallback}
              isTableView={isTableView}
              setSelectedSortingTypes={setSelectedSortingTypes}
              isMobileFiltersOpen={isMobileFiltersOpen}
              setMobileFilters={setMobileFilters}
              setLimit={setLimit}
              limit={limit}
              selectedSortingTypes={selectedSortingTypes}
              setSelectedSubcategoriesIds={setSelectedSubcategoriesIds}
            />
          </MobileFiltersContainer>
        </HeadContainer>
        {renderProducts()}
        <PaginationContainer>
          <Pagination
            onChange={({ offset }) => setOffset(offset)}
            offset={offset}
            limit={limit}
            maxCountPage={3}
            total={total}
          />
        </PaginationContainer>
      </Products>
}
    </Container>
  )
}
