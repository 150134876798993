import React from 'react'
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router'

import { ModalDark } from '../../../../commons'
import { useCloseRegistration } from './utils'
import { StepYetNotRegistration } from './StepYetNotRegistration'
import { StepForm } from './StepForm'
import { SmsConfirmData, StepSmsConfirm } from '../Login/SmsConfirm'
import { RequestsAuthorization } from '../../../../../modules/authorization'

enum RegistrationSteps {
  YetNotRegistration,
  Form,
  SmsConfirm,
}

function* registrationStepsFlow({
  isYetNotRegistration,
}: {
  isYetNotRegistration: boolean | undefined
}) {
  if (isYetNotRegistration) {
    yield RegistrationSteps.YetNotRegistration
  }

  while (true) {
    yield RegistrationSteps.Form
    const { isBack = false } =
      ((yield RegistrationSteps.SmsConfirm) as SmsConfirmData) ?? {}
    if (isBack !== true) {
      break
    }
  }
}

export let refForm = { form: {} }

type Registration = React.FC<RouteComponentProps>
export const Registration: Registration = () => {
  const location = useLocation()
  // @ts-ignore
  const isYetNotRegistration = location?.state?.isYetNotRegistration
  const flow = React.useRef(registrationStepsFlow({ isYetNotRegistration }))
    .current
  const [currentStep, setCurrentStep] = React.useState(() => flow.next().value)

  const navigate = useNavigate()
  React.useEffect(() => {
    // @ts-ignore
    const phone = location?.state?.phone
    if (phone === undefined) {
      navigate?.(location.pathname.replace('/registration', '/login'))
    }
  }, [navigate, location])

  const closeRegistration = useCloseRegistration()
  const nextStep = (data?: any) => {
    const { value, done } = flow.next(data)
    setCurrentStep(value)
    if (done) {
      closeRegistration()
    }
  }
  const [phone, setPhone] = React.useState('')

  React.useEffect(
    () => () => {
      refForm.form = {}
    },
    [],
  )
  return (
    <ModalDark visible onRequestClose={closeRegistration}>
      {RegistrationSteps.YetNotRegistration === currentStep && (
        <StepYetNotRegistration nextStep={nextStep} />
      )}
      {RegistrationSteps.Form === currentStep && (
        <StepForm
          nextStep={({ phone }) => {
            setPhone(phone)
            nextStep()
          }}
        />
      )}
      {RegistrationSteps.SmsConfirm === currentStep && (
        <StepSmsConfirm
          smsConfirm={RequestsAuthorization.smsConfirmRegistration}
          onRequestClose={closeRegistration}
          phone={phone}
          nextStep={nextStep}
        />
      )}
    </ModalDark>
  )
}
