import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from '@reach/router'
import { headerDrawer } from '../images'


const  CatalogueContainer = styled.div`
  padding-left: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  @media screen and (max-width: 619px) {
    display: none;
  }
`

const  CatalogueLinkToCatalogue = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 46px;
`

const CatalogueTitle = styled.div`
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8px;
  padding-right: 15px;
  color: #35414c;
  text-decoration: none;
  @media screen and (max-width: 1139px) {
    display: none;
  }
`
const CatalogueImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-left: 8px;
  justify-content: center;
  
  @media screen and (max-width: 1139px) {
    padding-left: 35px;
    padding-right: 35px;
  }
`
const CatalogueImage = styled.img.attrs({ src: headerDrawer })`
  width: 24px;
  height: 24px;
`

// @ts-ignore

type HeaderCatalogueT = React.FC<RouteComponentProps & {onClick:()=>void}>

export const HeaderCatalogue: HeaderCatalogueT = (selectCategoryHandler: Dispatch<SetStateAction<number>>) => {
  const navigate = useNavigate()

  const selectCategory = () => {
    selectCategoryHandler(0);
  };
  
  return (
        < CatalogueContainer>
          < CatalogueLinkToCatalogue
            onClick={selectCategory}
            to={`/products`}
          >
            <CatalogueImageWrapper><CatalogueImage/></CatalogueImageWrapper>            
            <CatalogueTitle>Каталог</CatalogueTitle>
          </ CatalogueLinkToCatalogue>          
          </ CatalogueContainer>
  )
}
