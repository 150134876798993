import React from 'react'
import styled from 'styled-components'
import { checkboxIcon } from '../images'
import { useAtom, useAction } from '@reatom/react'
import {
  mainPageSubcategory as mainPageSubcategoryAtom,
} from '../../modules/basket/store'
import { SubcategoriesListTypes } from '../../modules/category'

interface LinkProps {
  isActive: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  position: relative;

  @media screen and (min-width: 1440px) {
    padding-left: 30px;
  }
`

const SubCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;

  &:first-child {
    padding-top: 20px;
  }
`

const StyledCheckbox = styled.div<LinkProps>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  border-radius: 3px;
  border: ${({ isActive }) =>
    isActive ? '1.5px solid #f0640c' : '1.5px solid #8B949B'};
  background: white;
  background-image: ${({ isActive }) =>
    isActive ? `url(${checkboxIcon})` : 'none'};
  background-position: center;
  background-position-x: 2px;

  background-repeat: no-repeat;
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  cursor: pointer;
`

const Title = styled.div<LinkProps>`
  font-family: 'Open Sans', sans-serif;
  padding-left: 10px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ isActive }) => (isActive ? 'orange' : '#495560;')};
  cursor: pointer;
`

export const SubcategoriesList: React.FC<SubcategoriesListTypes> = ({
  selectedSubcategoriesIds,
  setSelectedSubcategoriesIds,
  subcategories,
}) => {
  const mainPageSubcategory = useAtom(mainPageSubcategoryAtom)

  const setIsActive = React.useCallback(
    (id) => selectedSubcategoriesIds.includes(id) || mainPageSubcategory == id,
    [selectedSubcategoriesIds],
  )

  React.useEffect(() => {
    Number(mainPageSubcategory) &&
      setSelectedSubcategoriesIds([Number(mainPageSubcategory)])
  }, [mainPageSubcategory])

  const toggleSubcategory = React.useCallback(
    (id: number) => {
      if (Number(mainPageSubcategory) == id) {
        // @ts-ignore
        removeSubcategory()
      }
      if (selectedSubcategoriesIds?.includes(id)) {
        const subcategories: number[] = selectedSubcategoriesIds.filter(
          (x) => x !== id,
        )

        setSelectedSubcategoriesIds(subcategories)
      } else {
        setSelectedSubcategoriesIds([...selectedSubcategoriesIds, id])
      }
    },
    [selectedSubcategoriesIds, setSelectedSubcategoriesIds],
  )
  return (
    <Container>
      {subcategories?.map(({ id, title }) => (
        <SubCategoryContainer key={id}>
          <StyledCheckbox
            onClick={() => toggleSubcategory(id)}
            id={String(id)}
            isActive={setIsActive(id)}
          >
            <CheckBox id={String(id)} />
          </StyledCheckbox>
          <label htmlFor={String(id)}>
            <Title
              key={id}
              onClick={() => toggleSubcategory(id)}
              isActive={setIsActive(id)}
            >
              {title}
            </Title>
          </label>
        </SubCategoryContainer>
      ))}
    </Container>
  )
}
