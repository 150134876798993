import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useAtom, useAction } from '@reatom/react'
import styled from 'styled-components'
import {
  Product as ProductModel,
  ProductRequestes,
} from '../../../modules/product'
import {
  items,
  getBasketByProductId,
  Basket as BasketModel,
  removeReorder,
} from '../../../modules/basket'
import { ltDesktop, ltTablet } from '../../../utils'

import { Form } from './Form'
import { Header } from './Header'
import { useCityContext } from '../../../context/CityContext'

const Container = styled.div`
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 59px;

  ${ltDesktop} {
    padding: 20px 30px 55px;
  }
  ${ltTablet} {
    padding: 10px 0 0 0;
  }
`

type Basket = React.FC<RouteComponentProps<{ productId: ProductModel['id'] }>>
// @ts-ignore

export const Basket: Basket = ({ productId, location }) => {
  const basketStore = useAtom(
    items,
    productId === undefined ? () => undefined : getBasketByProductId(productId),
    [productId],
  )

  const deleteReorder = useAction(removeReorder)
  const {selectedCity} = useCityContext()

  const [product, setProduct] = React.useState<ProductModel | undefined>(
    undefined,
  )
  React.useEffect(() => {
    if (productId) {
      ProductRequestes.fetchGetProduct(productId, selectedCity).then(setProduct)
    }
    return () => {
      deleteReorder()
    }
  }, [productId, selectedCity])

  // @ts-ignore
  const basket: Partial<BasketModel> = React.useMemo(
    () =>
      basketStore
        ? {
            product: product ?? basketStore.product,
            deliveries: [...basketStore.deliveries],
          }
        : // TODO: try symbol detect
          {
            product:
              product ??
              // @ts-ignore
              ((location?.state?.id ? location?.state : undefined) as
                | ProductModel
                | undefined),
          },
    [basketStore, location, product],
  )

  // @ts-ignore

  return (
    <>
      <Header />
      <Container>
        <Form key={productId} basket={basket} isEdit={Boolean(basketStore)} />
      </Container>
    </>
  )
}
