
import styled from 'styled-components'
export const HeaderWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #f0f4f6;
  background-color: white;
  
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 1386px;
  @media screen and (max-width: 1386px) {
    width: 100%;
  }
`