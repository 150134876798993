import React from 'react'
import styled from 'styled-components'
import { FieldRenderProps } from 'react-final-form'
import { IMaskMixin, Mask } from 'react-imask'

type InputTextStyleProps = {
  isError?: boolean
}

const InputTextStyle = styled.input<InputTextStyleProps>`
  padding: 9px 14px;
  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }
  &:focus {
    border-color: #f49355;
  }
  transition: 225ms;

  outline: none;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 16px;
  color: #8b949b;

  border: 1px solid transparent;
  background: #ecf1f4;
  border-radius: 6px;
  ${({ isError }) => (isError ? 'border-color: #F55555;' : '')};
`

export const Error = styled.div`
  height: 13px;
  margin-top: 3px;

  text-align: left;
  color: #f06060;
`

export type InputTextProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  onChange: (value: string) => void
}
type InputText = React.FC<InputTextProps>
export const InputText: InputText = React.forwardRef(
  ({ onChange, ...props }, ref) => (
    <InputTextStyle
      type="text"
      onChange={(event) => onChange(event.target.value)}
      // @ts-ignore
      ref={ref}
      {...props}
    />
  ),
)

type InputTextFinalProps = FieldRenderProps<string> &
  React.InputHTMLAttributes<HTMLInputElement>
type InputTextFinal = React.FC<InputTextFinalProps>
export const InputTextFinal: InputTextFinal = ({ input, ...props }) => (
  <InputTextStyle type="text" {...props} {...input} />
)

export const InputTextFinalWithError: InputTextFinal = ({
  input,
  meta: { touched, error },
  ...props
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <InputTextStyle type="text" {...props} {...input} />
    <Error>{touched && error}</Error>
  </div>
)

export type InputTextAreaProps = Omit<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> &
  InputTextStyleProps & {
    onChange: (value: string) => void
  }

type InputTextAreaFC = React.FC<InputTextAreaProps>
export const InputTextArea: InputTextAreaFC = React.forwardRef((props, ref) => {
  // @ts-ignore
  return <InputText as="textarea" ref={ref} {...props} />
})

type InputTextAreaFinal = React.FC<
  FieldRenderProps<string> &
    React.InputHTMLAttributes<HTMLTextAreaElement> &
    InputTextStyleProps
>
export const InputTextAreaFinal: InputTextAreaFinal = ({ input, ...props }) => (
  <InputTextArea {...props} {...input} />
)

const IMaskMixinCustom = function <A>(
  Component: React.Component<A> | React.FC<A>,
): React.FC<A & Mask> {
  // @ts-ignore
  return IMaskMixin(({ inputRef, ...props }) => (
    // @ts-ignore
    <Component ref={inputRef} {...props} />
  ))
}

export const InputMask = IMaskMixinCustom(InputTextStyle)

type InputMaskFinal = React.FC<InputTextFinalProps & Mask>
export const InputMaskFinal: InputMaskFinal = React.forwardRef(
  (
    {
      input: { onChange, value, ...input },
      meta: { touched, error },
      ...props
    },
    ref,
  ) => (
    <>
      <InputMask
        onAccept={(value: string) => onChange(value)}
        value={value}
        // @ts-ignore
        ref={ref}
        {...input}
        {...props}
      />
      <Error>{touched && error}</Error>
    </>
  ),
)
