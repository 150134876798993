import React from 'react'
import { Container, Title } from '../commons'
import { Button } from '../../../../commons'
import { useLocation, useNavigate } from '@reach/router'
import { ModalDark } from '../../../../commons'
import styled from 'styled-components'
import { RequestsAuthorization } from '../../../../../modules/authorization'
import { normalizePhone } from '../../../../../utils'


const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-size: 13px;
  text-align: center;
  color: #495560;
`

const LoginButton = styled(Button)`
  margin-top: 30px;
  margin-bottom: 15px;
`

export const UserExistModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const onLogin = () => {
    RequestsAuthorization.login({
      phone: normalizePhone(phone),
    })
      .then(() => {
        navigate?.(location.pathname.replace('/user-exist', '/login'), {
          state: {
            phone: phone,
            fromUserExistModal: true,
          },
        })
      })
  }
  const phone = (location as any)?.state?.phone ?? ''
  return (
    <ModalDark visible onRequestClose={() => navigate(-1)}>
      <Container onRequestClose={() => navigate(-1)}>
        <Title>Ошибка</Title>
        <Text>Номер {phone} уже зарегистрирован.</Text>
        <LoginButton onClick={onLogin}>Войти</LoginButton>
      </Container>
    </ModalDark>
  )
}
