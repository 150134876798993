type LoginModel = { phone: string }
export const login = (body: LoginModel) =>
  fetch('/api/v1/auth/login', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

type SmsConfirmLoginModel = LoginModel & { code: string }
export const smsConfirmLogin = (body: SmsConfirmLoginModel) =>
  fetch('/api/v1/auth/accept_login', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

type RegistrationModel = {
  fio: string
  organizationForm: string
  organizationTitle: string
  phone: string
}

export const registration = ({
                               fio,
                               organizationForm,
                               organizationTitle,
                               phone,
                             }: RegistrationModel) => {
  const [lastName = '', firstName = '', patronymic = ''] = fio.split(' ')
  const registrationBody = {
    lastName,
    firstName,
    patronymic,
    phone,
    organizationForm,
    organizationTitle,
  }
  return fetch('/api/v1/auth/registration', {
    method: 'POST',
    body: JSON.stringify(registrationBody),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

type SmsConfirmRegistrationModel = SmsConfirmLoginModel
export const smsConfirmRegistration = (body: SmsConfirmRegistrationModel) =>
  fetch('/api/v1/auth/accept', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const sendPin = (body: LoginModel) =>
  fetch('/api/v1/auth/getCode', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const fetchGetContract = () =>
  fetch('/api/v1/users/contract').then((res) => res.json())

// @ts-ignore
export const sendContract = (body) =>
  fetch('/api/v1/users/contract', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const checkPhone = (phone: string) =>
  fetch(`/api/v1/users/checkphone?phone=${phone}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())