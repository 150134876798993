import React from 'react'
import styled from 'styled-components'
import { RangeInputTypes } from '../../modules/product'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const PriceRangeItem = styled.input`
  width: 70px;
  height: 38px;
  margin-top: 8px;
  padding-left: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
  border: 1px solid #8b949b;
  border-radius: 6px;
  outline: none;
  

  @media screen and (min-width: 410px) and (max-width: 767px) {
    :first-child {
    }
  }

  /* :focus {
    border: 1px solid #f0640c;
  } */
`

export const PriceRangeInputs: React.FC<RangeInputTypes> = ({
  prices,
  localInputValueMin,
  localInputValueMax,
  setMaxFilterPrice,
  setMinFilterPrice,
  setLocalInputValueMax,
  setLocalInputValueMin,
}) => {
  const setLocalInputMinValue = React.useCallback(
    (event) => {
      setLocalInputValueMin(Number(event.target.value))
    },
    [localInputValueMin],
  )

  const setLocalInputMaxValue = React.useCallback(
    (event) => {
      setLocalInputValueMax(Number(event.target.value))
    },
    [localInputValueMax],
  )

  const onKeyPressMin = React.useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (localInputValueMin < (prices?.min ? prices?.min : 0)) {
          setLocalInputValueMin(prices?.min ? prices?.min : 0)
        } else setMinFilterPrice(localInputValueMin.toString())
      }
    },
    [localInputValueMin],
  )

  const onKeyPressMax = React.useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (localInputValueMax > (prices?.max ? prices?.max : 0)) {
          setLocalInputValueMax(prices?.max ? prices?.max : 0)
        } else if (localInputValueMax < (prices?.min ? prices?.min : 0)) {
          setLocalInputValueMax(prices?.max ? prices?.max : 0)
        } else setMaxFilterPrice(localInputValueMax.toString())
      }
    },
    [localInputValueMax],
  )

  return (
    <Container>
      <PriceRangeItem
        type="text"
        placeholder={prices.min?.toString()}
        value={localInputValueMin}
        onChange={setLocalInputMinValue}
        onKeyPress={onKeyPressMin}
      />
      <PriceRangeItem
        type="text"
        placeholder={prices.max?.toString()}
        value={localInputValueMax}
        onChange={setLocalInputMaxValue}
        onKeyPress={onKeyPressMax}
        required
      />
    </Container>
  )
}
