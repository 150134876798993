import React from 'react'
import styled from 'styled-components'
import { ModalDark } from '../commons'

const Container = styled.div`
  width: 400px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 6px;

  @media screen and (max-width: 460px) {
    width: 280px;
  }
`

const Heading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #35414c;
  text-align: center;
`

const Text = styled.div`
  margin-top: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #495560;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: #f0640c;
  width: 290px;
  height: 38px;
  border-radius: 6px;
  margin-top: 30px;
  @media screen and (max-width: 460px) {
    width: 250px;
  }
`

export const BasketsPopUp = ({ setShowPopUp, showPopUp }) => {
  return (
    <ModalDark visible={showPopUp}>
      <Container>
        <Heading>Заказ успешно оформлен</Heading>
        <Text>
          С вами свяжется наш сотрудник для <br /> подтверждения заказа
        </Text>
        <Button onClick={() => setShowPopUp(false)}>Хорошо</Button>
      </Container>
    </ModalDark>
  )
}
