import React from 'react'
import styled from 'styled-components'

import { InputFC } from './index'

const InputStyle = styled.input`
  width: 100%;
  height: 100%;

  outline: none;
  border: none;

  text-align: center;
  color: black;
`

export const Input: InputFC = ({
  onChange: onChangeBase,
  limit,
  countPage,
}) => {
  const [value, setValue] = React.useState<number | ''>('')

  const onChange = () =>
    onChangeBase({
      offset: limit * (Math.min(countPage, Math.max(Number(value), 1)) - 1),
    })
  return (
    <InputStyle
      placeholder="..."
      value={value}
      onChange={(event) => {
        const normalizeValue = Number(event.target.value)
        if (!Number.isNaN(normalizeValue)) {
          setValue(normalizeValue)
        }
      }}
      onBlur={() => {
        setValue('')
        if (value === '') {
          return
        }
        onChange()
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          setValue('')
          if (value === '') {
            return
          }
          onChange()
        }
      }}
    />
  )
}
