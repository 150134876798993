import React from 'react'
import styled from 'styled-components'

import { emptyProduct } from '../images'

const Container = styled.div`
  height: 54px;
  width: 54px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  border-radius: 7px;

  position: relative;
`

const ImageStyle = styled.img`
  height: 100%;
  min-width: 100%;

  position: absolute;
`

const EmptyImage = styled.img`
  height: 100%;
`

const onLoad = (event: React.ChangeEvent<HTMLImageElement>) => {
  const image = event.target
  const aspectRatio = image.naturalWidth / image.naturalHeight

  // If image is landscape
  if (aspectRatio > 1) {
    image.style.width = 'auto'
    image.style.height = '100%'
  }
  // If image is portrait
  else if (aspectRatio <= 1) {
    image.style.width = 'auto'
    image.style.height = '100%'
  }
}

type Image = React.FC<{ src?: string; alt?: string; className?: string }>
export const Image: Image = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  return (
    <Container className={className}>
      {isLoading && <EmptyImage src={emptyProduct} />}
      <ImageStyle
        style={isLoading ? { display: 'none' } : undefined}
        // @ts-ignore
        onLoad={(event) => (setIsLoading(false), onLoad(event))}
        src={src}
        alt={alt}
      />
    </Container>
  )
}
