import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { emptyProduct } from '../../../images'
import { Category } from '../../../../modules/category'

type ImageProps = {
  image?: string
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 330px;

  align-items: center;
  justify-content: flex-start;

  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
  max-width: 330px;
  min-width: 330px;
  max-height: 330px;
  min-height: 330px;
  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    padding: 15px;
    max-width: 330px;
    min-width: 330px;
    max-height: 330px;
    min-height: 330px;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) {
    padding: 12px;
    max-width: 230px;
    min-width: 230px;
    max-height: 260px;
    min-height: 260px;
  }
  @media screen and (min-width: 520px) and (max-width: 719px) {
    padding: 5px;
    max-width: 158px;
    min-width: 158px;
    max-height: 190px;
    min-height: 190px;
  }
  @media screen and (max-width: 519px) {
    padding: 5px;
    max-width: 140px;
    min-width: 140px;
    max-height: 190px;
    min-height: 190px;
  }
`

const ImageContainer = styled.img<ImageProps>`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: scale-down;
  width: 298px;
  height: 219px;
  padding: 16px;
    
  @media screen and (min-width: 1140px) and (max-width: 1440px) {
    width: 298px;
    height: 219px;
    padding: 16px;
  }
  @media screen and (min-width: 720px) and (max-width: 1139px) { 
    width: 206px;
    height: 154.63px;
    padding: 12px;
  }
  @media screen and (min-width: 320px) and (max-width: 719px) {    
    width: 136px;
    height: 131px;
    padding: 12px;
  }
  @media screen and (max-width: 319px) {
    width: 136px;
    height: 131px;
    padding: 2px;
  }
`

const Title = styled.div`
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 19px;
/* or 100% */
text-align: center;
letter-spacing: 0.01em;

color: #F0640C;


@media screen and (min-width: 1140px) and (max-width: 1440px) {
  font-family: Open Sans;
  font-size: 19px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  
}
@media screen and (min-width: 720px) and (max-width: 1139px) { 
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;

}
@media screen and (min-width: 320px) and (max-width: 719px) {    
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: center;
}
@media screen and (max-width: 319px) {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: center;
}
 
`
type CategoryTableItemT = React.FC<{
  id: number
  title: string
  image: string
  setSelectedSubcategoriesIds: Dispatch<SetStateAction<number[]>>
  setActiveCategoryId: Dispatch<SetStateAction<number>>
}>

export const CategoryTableItem: CategoryTableItemT = ({
  id,
  title,
  image, 
  setSelectedSubcategoriesIds,  
  setActiveCategoryId,
}) => {
  const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement>): void => {
    ev.currentTarget.src = emptyProduct
  }
 
  const onClick = () => {
    setActiveCategoryId(id);
    setSelectedSubcategoriesIds([id]);
  }
  return (
    <Container onClick={onClick}>        
      <Title>{title}</Title>  
      <ImageContainer src={image} onError={(e) => addDefaultSrc(e)} />
    </Container>
  )
}

export default CategoryTableItemT
