import * as React from 'react'
import { SliderItem } from 'react-compound-slider'

const { Component, Fragment } = React

// *******************************************************
// TOOLTIP RAIL
// *******************************************************
const railStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: 0,
  top: -13,
  borderRadius: 7,
  cursor: 'pointer',
  opacity: 10,
  zIndex: 4,
}

const railCenterStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: 1,
  opacity: 0.15,

  borderRadius: 7,
  cursor: 'pointer',
  pointerEvents: 'none',
  backgroundColor: 'rgb(155,155,155)',
}

interface TooltipRailProps {
  activeHandleID: string
  getRailProps: (props: object) => object
  getEventData: (e: Event) => object
}

export class TooltipRail extends Component<TooltipRailProps> {
  state = {
    value: null,
    percent: null,
  }

  static defaultProps = {
    disabled: false,
  }

  onMouseEnter = () => {
    document.addEventListener('mousemove', this.onMouseMove)
  }

  onMouseLeave = () => {
    this.setState({ value: null, percent: null })
    document.removeEventListener('mousemove', this.onMouseMove)
  }

  onMouseMove = (e: Event) => {
    const { activeHandleID, getEventData } = this.props

    if (activeHandleID) {
      this.setState({ value: null, percent: null })
    } else {
      this.setState(getEventData(e))
    }
  }

  render() {
    return (
      <Fragment>
        <div
          style={railStyle}
          {...this.props.getRailProps({
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
          })}
        />
        <div style={railCenterStyle} />
      </Fragment>
    )
  }
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface HandleProps {
  key: string
  handle: SliderItem
  isActive: Boolean
  disabled?: Boolean
  domain: number[]
  getHandleProps: (id: string, config: object) => object
}

export class Handle extends Component<HandleProps> {
  static defaultProps = {
    disabled: false,
  }

  state = {
    mouseOver: false,
  }

  onMouseEnter = () => {
    this.setState({ mouseOver: true })
  }

  onMouseLeave = () => {
    this.setState({ mouseOver: false })
  }

  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      disabled,
      getHandleProps,
    } = this.props

    return (
      <Fragment>
        {/* {(mouseOver || isActive) && !disabled ? (
          <div
            style={{
              left: `${percent}%`,
              position: 'absolute',
              marginLeft: '-11px',
              marginTop: '-35px',
            }}
          >
            <div className="tooltip">
              <span className="tooltiptext">Value: {value}</span>
            </div>
          </div>
        ) : null} */}
        <div
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '-11px',
            marginTop: '-8px',
            zIndex: 5,
            width: 16,
            height: 16,
            cursor: 'pointer',
            border: 0,
            borderRadius: '50%',
            backgroundColor: disabled ? '#666' : '#F0640C',
          }}
          {...getHandleProps(id, {
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
          })}
        />
      </Fragment>
    )
  }
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface TrackProps {
  source: SliderItem
  target: SliderItem
  getTrackProps: () => object
}

export function Track({ source, target, getTrackProps }: TrackProps) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 2,
        zIndex: 1,
        backgroundColor: '#FF9F63',
        borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  )
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface TickProps {
  tick: SliderItem
  count: number
  format: (val: number) => string
}

const defaultFormat = (d: number) => `d`

export function Tick({ tick, count, format = defaultFormat }: TickProps) {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 17,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 25,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  )
}
