import React from 'react'
import styled from 'styled-components'
import { ltDesktop } from '../../../utils'
import { useAtom } from '@reatom/react'
import { Link, useLocation } from '@reach/router'
import {
  playmarketLogo,
  appleLogo,
  Basket,
  loginTabIcon,
  CatalogueMobile,
  karamidaIcon,
  karamidaLogo,
  CarIcon
} from '../../images'
import { LinkProps } from '../../../modules/validators'
import { items } from '../../../modules/basket'
import { useIsAuthorization } from '../../../modules/authorization'
import { CopyEmailToClipBoard } from '../../../utils/copyNumber'

type Quanity = {
  quanity: number
}

const Container = styled.header`
  background-color: #35414c;
  min-width: 100%;
  position: absolute;
  z-index: 10;
  @media screen and (max-width: 767px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Wrapper = styled.div`
  height: 114px;
  width: 100%;

  ${ltDesktop} {
    height: 135px;
  }

  @media screen and (max-width: 767px) {
    height: 60px;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 767px) {
    display: inline;
  }
`

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    padding-top: 14px;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-right: 40px;
  gap: 18px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const LeftSectionContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and  (max-width: 767px) {
    display: none;
  }
`

const ContactsContainer = styled.div`
  margin-left: 40px;
`

const ContactsTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 2px;
`


const MobileNumber = styled.a`
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`

const Email = styled.a`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
`

const DownloadMobileApp = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  padding-right: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`

const AppLogoContainer = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

const CatalogueContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const CatalogueText = styled.div<LinkProps>`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: ${({ isActive }) => (!isActive ? '#8b949b' : '#35414C')};
  text-decoration: none;
`

const BasketContainer = styled(Link)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const BasketQuanity = styled.div<Quanity>`
  position: absolute;
  display: ${({ quanity }) => (quanity ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: -6px;
  right: -1px;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
  background-color: #f0640c;
  width: 16px;
  height: 16px;
  border-radius: 15px;
`

const BasketText = styled.div<LinkProps>`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: ${({ isActive }) => (!isActive ? '#8b949b' : '#35414C')};
  text-decoration: none;
`

const ProfileContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const ProfileText = styled.div<LinkProps>`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: ${({ isActive }) => (!isActive ? '#8b949b' : '#35414C')};
  text-decoration: none;
`

const LogInContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const LoginText = styled.div`
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #8b949b;
  text-decoration: none;
`

const FooterLogo = styled.img.attrs({ src: karamidaIcon })`
  width: 62px;
  height: 32px;

  @media (max-width: 767px) {
    display: none;
  }
`

const ProstoreTitleImage = styled.img.attrs({ src: karamidaLogo })`
  width: 98px;
  height: 20px;
  @media (max-width: 767px) {
    display: none;
  }
`

const PlaymarketLogo = styled.img.attrs({ src: playmarketLogo })`
  width: 20px;
  height: 24px;
  margin-right: 23px;
  @media (max-width: 767px) {
    display: none;
  }
`
const AppleLogoLink = styled.a``

const PlaymarketLogoLink = styled.a``

const AppleLogo = styled.img.attrs({ src: appleLogo })`
  width: 22px;
  height: 28px;
  margin-right: 10px;
`

const CatalogueImage = styled(CatalogueMobile)<LinkProps>`
  width: 16.75px;
  height: 21px;
  stroke: ${({ isActive }) => (isActive ? '#35414C' : '#8B949B')};
  fill: ${({ isActive }) => (isActive ? '#35414C' : '#8B949B')};
`

const BasketImage = styled(Basket)<LinkProps>`
  width: 20.5px;
  height: 20.68px;
  stroke: ${({ isActive }) => (isActive ? '#35414C' : '#8B949B')};
`

const LoginImage = styled.img.attrs({ src: loginTabIcon })``

const OrdersImage = styled(CarIcon)<LinkProps>`
  path {
    fill: ${({ isActive }) => (isActive ? '#35414C' : '#8B949B')};
  }
`

export const Footer = () => {
  const products = useAtom(items)
  const isAuth = useIsAuthorization()
  const location = useLocation()

  return (
    <Container>
      <Wrapper>
        <ColumnContainer>
          <RowContainer>
            <CatalogueContainer to='/products'>
              <CatalogueImage isActive={location.pathname === '/products'} />
              <CatalogueText isActive={location.pathname === '/products'}>
                Каталог
              </CatalogueText>
            </CatalogueContainer>

            <BasketContainer to='/baskets'>
              <BasketQuanity quanity={products.length}>
                {products.length}
              </BasketQuanity>
              <BasketImage isActive={location.pathname === '/baskets'} />
              <BasketText isActive={location.pathname === '/baskets'}>
                Корзина
              </BasketText>
            </BasketContainer>

            {!isAuth && (
              <LogInContainer to={`${location.pathname}/login`}>
                <LoginImage />
                <LoginText>Вход</LoginText>
              </LogInContainer>
            )}

            {isAuth && (
              <ProfileContainer to='/orders'>
                <OrdersImage  isActive={location.pathname === '/orders'}/>
                <ProfileText isActive={location.pathname === '/orders'}>
                  Заказы
                </ProfileText>
              </ProfileContainer>
            )}

            <LeftSectionContainer>
              <LogoContainer to={`/`}>
                <FooterLogo />
                <ProstoreTitleImage />
              </LogoContainer>
              <ContactsContainer>
                <ContactsTitle>Горячая линия</ContactsTitle>
                <MobileNumber href='tel:8-918-070-38-36'>
                  +7 (918) 070-38-36
                </MobileNumber>
                <Email onClick={() => CopyEmailToClipBoard('ProStore123@yandex.ru')}>ProStore123@yandex.ru</Email>
              </ContactsContainer>
              <ContactsContainer>
                <ContactsTitle>Отдел ЖБИ</ContactsTitle>
                <MobileNumber href='tel:8-962-877-77-20'>
                  +7 (962) 877-77-20
                </MobileNumber>
                <Email onClick={() => CopyEmailToClipBoard('ProStoreGBK@yandex.ru')}>ProStoreGBK@yandex.ru</Email>
              </ContactsContainer>
            </LeftSectionContainer>

            <RightSectionContainer>
              <DownloadMobileApp>
                Скачать мобильное <br /> приложение
              </DownloadMobileApp>
              <AppLogoContainer>
                <PlaymarketLogoLink href='https://play.google.com/store/apps/details?id=com.codemonx.prostore&hl=ru'>
                  <PlaymarketLogo />
                </PlaymarketLogoLink>
                <AppleLogoLink href='https://apps.apple.com/ru/app/prostore/id1465747466'>
                  <AppleLogo />
                </AppleLogoLink>
              </AppLogoContainer>
            </RightSectionContainer>
          </RowContainer>
        </ColumnContainer>
      </Wrapper>
    </Container>
  )
}
