import React, { useState } from 'react'
import { Link, RouteComponentProps, useNavigate } from '@reach/router'
import styled from 'styled-components'
import { Filters } from './Filters'
import { arrowBack, mobileFilters } from '../../images'
import { OrderElements } from './OrderElement'
import { fetchGetOrders, fetchGetOrdersFilters } from '../../../modules/orders/Requestes'
import { useIsAuthorization } from '../../../modules/authorization'
import OrdersSortButtons from './OrdersSortButtons'
import { Pagination } from '../../commons'
import FiltersMobileButtonGroup from './FiltersMobileButtonGroup'

type Orders = React.FC<RouteComponentProps>

const Container = styled.div`
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
`


export interface OrderInterface {
  id: number
  product: ProductOrder
  address: string
  count: string
  comment: string
  amoCrmId: string
  status: string
  date: string
  createdAt: string
  productId: number
  price: string
  orderNumber: string,
  orderDisabled: boolean
}

export interface OrdersInterface {
  results: OrderInterface[]
  total: number
}

type ProductOrder = {
  title: string
  measurement: string
  price: number
  files?: ProductFiles[]
}

type ProductFiles = {
  id: number
  productId: number
  uri: string
}

const OrdersTopSection = styled.div`
  background-color: white;
  width: 100%;
  height: 63px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1440px) {
    justify-content: center;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    height: 67px;
  }
`
const OrdersTopSectionText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #35414c;
  padding-left: 45px;
  @media screen and (min-width: 1440px) {
    padding-right: 1325px;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding-left: 30px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px;
    font-size: 16px;
    line-height: 19px;
    padding-top: 11px;
  }
`
const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 46px;
`

const BackToProfileContainer = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  display: flex;
  padding-left: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
  padding-top: 7px;
  text-decoration: none;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const BackToProfileContainerText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;

  padding-left: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8b949b;
`

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const MobileBlockContainer = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
  }
`

const FiltersIcon = styled.img.attrs({ src: mobileFilters })`
  fill: #35414C;
  width: 19.5px;
`

const FiltersIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 38px;
  height: 38px;
  box-shadow: 0 2px 5px rgb(10 21 128 / 10%);
  border-radius: 6px;
`

const FilterMobileContainer = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 30;
    display: block;
    width: 100%;
  }
`


const ArrowBack = styled.img.attrs({ src: arrowBack })``

export const Orders: Orders = () => {
  const [orders, setOrders] = React.useState<OrdersInterface>({
    total: 0,
    results: [],
  })

  const [filterValues, setFilterValues] = React.useState({})
  const [selectedFiltersValues, setSelectedFilterValues] = React.useState({
    prices: { min: 0, max: 10000 },
    material: [],
    address: [],
    date: [],
  })

  const [mobileFiltersModalIsOpen, setMobileFiltersModalIsOpen] = useState(false)


  const [offset, setOffset] = React.useState({ value: 0 })

  const [sortType, setSortType] = React.useState('status')


  const onCancelFiltersModal = ()=>{
    setSelectedFilterValues({
      material: [],
      address: [],
      date: [],
      //@ts-ignore
      prices:filterValues?.prices
    })
    setMobileFiltersModalIsOpen(false)
  }

  const onAcceptFiltersModal = ()=> {
    setMobileFiltersModalIsOpen(false )
  }


  const renderOrders = () => {
    if (mobileFiltersModalIsOpen) return false
    return (
      <>
        {
          // @ts-ignore
          orders?.results?.map((order, i) => (
            <OrderElements key={i} {...order} />
          ))
        }
      </>
    )
  }


  const isAuthorization = useIsAuthorization()
  const navigate = useNavigate()

  React.useEffect(() => {
      if (!isAuthorization) {
        navigate?.('/products')
      }
    }
    , [isAuthorization, navigate])

  React.useEffect(() => {
      fetchGetOrdersFilters().then(res => {
        setFilterValues(res)
        setSelectedFilterValues({ ...selectedFiltersValues, prices: res.prices })
      })
    }
    , [])

  React.useEffect(() => {
      setOffset({ value: 0 })
    }
    , [sortType])

  React.useEffect(() => {
      // @ts-ignore
      if (isAuthorization) {
        fetchGetOrders(sortType, offset.value / 10 + 1, selectedFiltersValues)
          .then((data) => {
            setOrders(data)
            return data
          })
      }
    }
    , [isAuthorization, offset, selectedFiltersValues])

  return (
    <div>
      <OrdersTopSection>
        <OrdersTopSectionText>Заказы</OrdersTopSectionText>
        <BackToProfileContainer to='/profile'>
          <ArrowBack />
          <BackToProfileContainerText>
            Назад в профиль
          </BackToProfileContainerText>
        </BackToProfileContainer>
      </OrdersTopSection>
      <OrderContainer>
        <OrdersSortButtons selectedSortType={sortType} setSelectedSortType={setSortType} />
        <Container>
          <Filters
            desktopOnly={true}
            filterValues={filterValues}
            setSelectedFilterValues={setSelectedFilterValues}
            selectedFiltersValues={selectedFiltersValues}
          />
          <OrdersContainer>
            <MobileBlockContainer>
              <FiltersIconContainer onClick={()=>setMobileFiltersModalIsOpen(true)}>
                <FiltersIcon />
              </FiltersIconContainer>
            </MobileBlockContainer>
            {mobileFiltersModalIsOpen &&
            <FilterMobileContainer>
              <Filters
                filterValues={filterValues}
                setSelectedFilterValues={setSelectedFilterValues}
                selectedFiltersValues={selectedFiltersValues}
              />
              <FiltersMobileButtonGroup onAccept={onAcceptFiltersModal} onCancel={onCancelFiltersModal}/>
            </FilterMobileContainer>
            }
            {renderOrders()}
          </OrdersContainer>
        </Container>
        <Pagination
          onChange={(value) => {
            setOffset({ value: value.offset })
          }}
          limit={10}
          maxCountPage={10}
          total={orders.total}
          offset={offset.value}
          className='orders-pagination'
        />
      </OrderContainer>
    </div>
  )
}
