//@ts-nocheck
import * as React from 'react'
import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Arrow as ArrowBase, Direction } from '../../../images'
import { ButtonBase } from '../../../commons/Buttons'
import { fetchGetSchedule } from '../../../../modules/schedule/Requestes'
import isBefore from 'date-fns/fp/isBefore'
import setDate from 'date-fns/fp/setDate'
import moment from 'moment'
import isSameYear from 'date-fns/fp/isSameYear'
import isSameMonth from 'date-fns/fp/isSameMonth'
import isSameDay from 'date-fns/fp/isSameDay'
import addMonths from 'date-fns/fp/addMonths'
import subMonths from 'date-fns/fp/subMonths'
import { format } from '../../../../utils'

type isActive = {
  isActive: boolean
}

const Frame = styled.div`
  width: 295px;
  border-radius: 6px;
  @media screen and (min-width: 600px) {
    width: 370px;
    height: 270px;
    padding-right: 45px;
    padding-left: 25px;
  }
`

const Header = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 10px;
  /* identical to box height */

  text-align: center;

  /* Gray */

  color: #495560;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 272px;
  margin-left: 10px;
  margin-top: 25px;
  @media screen and (min-width: 600px) {
    padding: 10px 10px 5px 10px;

    padding-left: 15px;
  }
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 600px) {
    padding-left: 15px;
  }
`

const Day = styled.div`
  /* margin: 2px 2px 2px 2px; */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  /* PrimeryGrey */

  color: #35414c;
  ${(props) =>
    //@ts-ignore
    props.datesBetween &&
    css`
      background: #fef0e7;
      /* border-radius: 6px; */
      color: black;
    `}
  ${(props) =>
    //@ts-ignore
    props.isSelectedFirst &&
    css`
      background: #f0640c;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      color: white;
    `}
    ${(props) =>
      //@ts-ignore
      props.isSelectedLast &&
      css`
        background: #f0640c;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        color: white;
      `}
  ${(props) =>
    //@ts-ignore
    props.disabled &&
    css`
      color: #8b949b;
      cursor: initial;
      box-shadow: initial;
    `}

    @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayOne = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayTwo = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayThree = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayFour = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayFive = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DaySix = styled.div<isActive>`
  width: 40px;
  height: 40px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`

const Arrow = styled(ArrowBase)`
  height: 10px;
`
const Button = styled(ButtonBase)`
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 4px;
`
const Warning = styled.div`
  font-family: FuturaMediumC;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #f55555;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  padding-left: 20px;
`
//@ts-ignore

export type CalendarForm = {
  onChange: (value: Date) => void
  value: Date
}

type CalendarFC = React.FC<
  {
    disabled?: (date: Date) => boolean
    className?: string
  } & CalendarForm
>

const isEqual = (a: Date, b: Date) =>
  [isSameYear, isSameMonth, isSameDay].every((f) => f(a)(b))

export const NewCalendarRange: CalendarFC = ({
  //@ts-ignore
  weekend,
  onChange,
  value,
  disabled = isBefore(new Date()), //@ts-ignore
  startRangeDay,
  setStartRangeDay,
  endRangeDay,
  setEndRangeDay,
  counter,
  setCounter,
  setRangePeriodLength,
}) => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  const today = new Date()
  const [date, setDatee] = useState(today)
  const [month, setMonth] = useState(date.getMonth())
  const [cursor, setCursor] = React.useState(value)
  const [year, setYear] = useState(cursor.getFullYear())
  const [daysForMap, setDaysForMap] = React.useState([])
  const [isWeekendChosen, setIsWeekendChosen] = React.useState(false)
  const [firstDay, setFirstDay] = React.useState(0)

  React.useEffect(() => {
    const firstday = new Date(year, month, 1)
    setFirstDay(firstday?.getDay())
  }, [month, year])

  // Range dates
  const getDates = (startDate, stopDate) => {
    let dateArray = []
    let currentDate = new Date(startDate)
    var stopDate = new Date(stopDate)
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days')
    }
    return dateArray
  }

  const rangeForWeekends = getDates(startRangeDay, endRangeDay)

  // Check is weekend in range
  React.useEffect(() => {
    if (
      rangeForWeekends
        ?.map((testDay) =>
          weekend.includes(moment(testDay).format('MM/DD/YYYY')),
        )
        .includes(true)
    ) {
      setIsWeekendChosen(true)
    } else {
      setIsWeekendChosen(false)
    }
  }, [rangeForWeekends, endRangeDay, startRangeDay])

  React.useEffect(() => {
    setMonth(cursor.getMonth())
    setYear(cursor.getFullYear())
  }, [cursor])

  React.useEffect(() => {
    setCursor(value)
  }, [value])

  React.useEffect(() => {
    if (new Date(endRangeDay) < new Date(startRangeDay)) {
      setStartRangeDay(endRangeDay)

      setEndRangeDay('')
      setCounter(counter - 1)
    }
    if (
      startRangeDay == endRangeDay &&
      startRangeDay !== '' &&
      endRangeDay !== ''
    ) {
      setEndRangeDay('')
      setCounter(counter - 1)
    }
  }, [startRangeDay, endRangeDay])

  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }

  //@ts-ignore
  const days = isLeapYear ? DAYS_LEAP : DAYS

  const addMonth = addMonths(1)
  const subMonth = subMonths(1)
  const dateFormat = format('yyyy MMMM')

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    const date = []
    startDate = moment(startDate).add(1, 'days').format('YYYY/MM/DD')
    while (moment(startDate) < moment(endDate)) {
      date.push(startDate)
      startDate = moment(startDate).add(1, 'days').format('YYYY/MM/DD')
    }
    return date
  }

  const enumerateDaysBetweenDatesWithoutWeekends = (startDate, endDate) => {
    const date = []
    if (startDate !== '') {
      date.push(moment(startDate).format('YYYY-MM-DD'))
    }
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
    while (moment(startDate) < moment(endDate)) {
      date.push(startDate)
      startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
    }
    if (endDate) {
      date.push(moment(endDate).format('YYYY-MM-DD'))
    }
    weekend?.map((day, i) => {
      if (date.includes(moment(day).format('YYYY-MM-DD'))) {
        date.splice(date.indexOf(moment(day).format('YYYY-MM-DD')), 1)
      }
    })

    return date
  }

  const datesBetweenRange = enumerateDaysBetweenDates(
    startRangeDay,
    endRangeDay,
  )

  const datesInRangeWithoutWeekends = enumerateDaysBetweenDatesWithoutWeekends(
    startRangeDay,
    endRangeDay,
  )

  // Длина выбранного периода
  React.useEffect(() => {
    if (datesInRangeWithoutWeekends.length > 1) {
      //@ts-ignore
      setRangePeriodLength(datesInRangeWithoutWeekends.length)
    }
  }, [datesInRangeWithoutWeekends])

  // Валидация дат
  React.useEffect(() => {
    if (moment(endRangeDay, 'YYYY-MM-DD').isValid() !== true) {
      setEndRangeDay('')
    }
    if (moment(startRangeDay, 'YYYY-MM-DD').isValid() !== true) {
      setStartRangeDay('')
    }
  }, [endRangeDay, startRangeDay])

  React.useEffect(() => {
    if (month == 1 && isLeapYear(year) !== true) {
      setDaysForMap(Array(days[month] - 1))
    } else setDaysForMap(Array(days[month]))
  }, [cursor, value, month])

  return (
    <>
      <Frame>
        <Header>
          <Button onClick={() => setCursor(subMonth)}>
            <Arrow direct={Direction.Left} />
          </Button>
          <div>{dateFormat(cursor)}</div>
          <Button onClick={() => setCursor(addMonth)}>
            <Arrow direct={Direction.Right} />
          </Button>
        </Header>
        <Body>
          <DayOne isActive={firstDay == 0 || firstDay >= 2}></DayOne>
          <DayTwo isActive={firstDay == 0 || firstDay >= 3}></DayTwo>
          <DayThree isActive={firstDay == 0 || firstDay >= 4}></DayThree>
          <DayFour isActive={firstDay == 0 || firstDay >= 5}></DayFour>
          <DayFive isActive={firstDay == 0 || firstDay >= 6}></DayFive>
          <DaySix isActive={firstDay == 0}></DaySix>
          {daysForMap.fill(null)?.map((_, index) => {
            const showDay = index + 1
            const dater = setDate(showDay)(cursor)
            return (
              <>
                <Day
                  key={index} //@ts-ignore
                  datesBetween={datesBetweenRange.includes(
                    moment(dater).format('YYYY/MM/DD'),
                  )}
                  isSelectedFirst={
                    moment(dater).format('MM/DD/YYYY') ==
                      moment(startRangeDay).format('MM/DD/YYYY') &&
                    !disabled(dater)
                  } //@ts-ignore
                  isSelectedLast={
                    moment(dater).format('MM/DD/YYYY') ==
                      moment(endRangeDay).format('MM/DD/YYYY') &&
                    !disabled(dater)
                  }
                  onClick={() =>
                    !disabled(dater) &&
                    !weekend.includes(moment(dater).format('MM/DD/YYYY'))
                      ? onChange(dater)
                      : console.log('error')
                  } //@ts-ignore
                  disabled={
                    //@ts-ignore
                    disabled(dater) || //@ts-ignore
                    weekend.includes(moment(dater).format('MM/DD/YYYY'))
                  }
                >
                  {showDay}
                </Day>
              </>
            )
          })}
        </Body>
      </Frame>
      <Warning isActive={isWeekendChosen}>
        {' '}
        Обратите внимание!
        <br />В выбранном диапазоне есть выходные дни,
        <br /> в эти дни доставка осуществляться не будет.
      </Warning>
    </>
  )
}
