import React from 'react'
import styled from 'styled-components'

import { Item } from './Item'
import { Input } from './Input'
import { PaginationFC } from './index'
import { goToTop } from 'react-scrollable-anchor'
import { arrowLeft } from '../../images'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10vh; */
  padding-top: 60px;
  padding-bottom: 64px;
  bottom: 0;
  left: 180px;
  right: 0;
  position: absolute;

  @media screen and (min-width: 1140px) and (max-width: 1439px) {
    left: 160px;
  }

  @media screen and (min-width: 720px) and (max-width: 1139px) {
    left: 20px;
  }
  @media screen and (min-width: 320px) and (max-width: 719px) {
    left: 0;
    bottom: 0;
  }
`

type ArrowType = {
  isRight?: boolean
}

const Arrow = styled.img<ArrowType>`
  transform: ${({ isRight }) => isRight && 'rotate(180deg)'};
`

export const Pagination: PaginationFC = ({
  onChange,
  total,
  limit,
  offset,
  maxCountPage,
  className,
}) => {
  const countPage = Math.ceil(total / limit)
  const currentPage = offset / limit

  const beginCenterPage =
    currentPage - Math.floor(maxCountPage / 2) + (maxCountPage % 2 ? 0 : 1)
  const lastCenterPage = currentPage + Math.floor(maxCountPage / 2)

  const isShowMore = countPage > maxCountPage + 2
  const isShowBegin = beginCenterPage > 1 && isShowMore
  const isShowEnd = lastCenterPage < countPage - 1 - 1 && isShowMore
  const width = 10 * String(total).length

  return (
    <Container className={className}>
      {countPage > 1 ? (
        <Container>
          <Item
            key="arrowLeft"
            width={32}
            onClick={() => {
              offset >= limit && onChange({ offset: offset - limit })
              goToTop()
            }}
          >
            <Arrow src={arrowLeft} isRight />
          </Item>
          {isShowBegin && (
            <>
              <Item
                key="one"
                width={width}
                onClick={() => {
                  onChange({ offset: 0 })
                  goToTop()
                }}
              >
                1
              </Item>
              <Item key="inputLeft" width={width}>
                <Input
                  limit={limit}
                  countPage={countPage}
                  onChange={onChange}
                />
              </Item>
            </>
          )}
          {Array(
            Math.min(
              countPage,
              maxCountPage + (isShowBegin ? 0 : 2) + (isShowEnd ? 0 : 2),
            ),
          )
            .fill(undefined)
            ?.map((_, index, { length }) => {
              let page = beginCenterPage + index
              if (!isShowBegin) {
                page = index
              } else if (!isShowEnd) {
                page = countPage - length + index
              }
              const showPage = page + 1
              return (
                <Item
                  key={showPage}
                  width={width}
                  active={currentPage === page}
                  onClick={() => {
                    onChange({ offset: page * limit })
                    goToTop()
                  }}
                >
                  {showPage}
                </Item>
              )
            })}
          {isShowEnd && (
            <>
              <Item key="inputRight" width={width}>
                <Input
                  limit={limit}
                  countPage={countPage}
                  onChange={onChange}
                />
              </Item>
              <Item
                key={countPage}
                width={width}
                onClick={() => {
                  onChange({ offset: (countPage - 1) * limit })
                  goToTop()
                }}
              >
                {countPage}
              </Item>
            </>
          )}
          <Item
            key="arrowRight"
            width={32}
            onClick={() => {
              currentPage < countPage - 1 &&
                onChange({ offset: offset + limit })
              goToTop()
            }}
          >
            <Arrow src={arrowLeft} />
          </Item>{' '}
        </Container>
      ) : null}
    </Container>
  )
}
