import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #F0F4F6;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: #FF9F63;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  width: 140px;
  height: 40px;
  &:first-child{
    margin-right: 10px;
  }
`

const AcceptButton = styled(Button)`
background-color: #F0640C;
`

const FiltersMobileButtonGroup: FC<{ onAccept: () => void, onCancel: () => void }> = ({onCancel, onAccept}) => {
  return (
    <Container>
      {/*//@ts-ignore*/}
      <Button onClick={()=>onCancel()}>Сбросить</Button>
      <AcceptButton onClick={onAccept}>Применить</AcceptButton>
    </Container>
  )
}

export default FiltersMobileButtonGroup