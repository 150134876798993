import * as React from 'react'
import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Arrow as ArrowBase, Direction } from '../../../images'
import { ButtonBase } from '../../../commons/Buttons'
import { fetchGetSchedule } from '../../../../modules/schedule/Requestes'
import isBefore from 'date-fns/fp/isBefore'
import setDate from 'date-fns/fp/setDate'
import moment from 'moment'
import isSameYear from 'date-fns/fp/isSameYear'
import isSameMonth from 'date-fns/fp/isSameMonth'
import isSameDay from 'date-fns/fp/isSameDay'
import addMonths from 'date-fns/fp/addMonths'
import subMonths from 'date-fns/fp/subMonths'
import { format } from '../../../../utils'

type isActive = {
  isActive: boolean
}

const Frame = styled.div`
  width: 270px;
  box-shadow: 2px 2px 2px #eee;
  border: 1px solid #f0640c;
  border-radius: 6px;
  padding-left: 25px;
  padding-right: 20px;
  @media screen and (min-width: 600px) {
    width: 385px;
    height: 270px;
    padding-left: 45px;
    padding-right: 50px;
  }
`

const Header = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  /* Gray */

  color: #495560;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  min-height: 160px;
`

const Day = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  /* PrimeryGrey */

  color: #35414c;
  ${(props) =>
    //@ts-ignore
    props.isSelected &&
    css`
      background: #f0640c;
      border-radius: 6px;
      color: white;
    `}
  ${(props) =>
    //@ts-ignore
    props.disabled &&
    css`
      color: #8b949b;
      cursor: initial;
      box-shadow: initial;
    `}
    @media screen and (min-width: 600px) {
    margin: 0;
    width: 37px;
    height: 37px;
  }
`
const DayOne = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayTwo = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayThree = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayFour = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DayFive = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`
const DaySix = styled.div<isActive>`
  width: 28px;
  height: 28px;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};

  @media screen and (min-width: 600px) {
    width: 37px;
    height: 37px;
  }
`

const Arrow = styled(ArrowBase)`
  height: 10px;
`
const Button = styled(ButtonBase)`
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(10, 21, 128, 0.1);
  border-radius: 4px;
`
//@ts-ignore

export type CalendarForm = {
  onChange: (value: Date) => void
  value: Date
}

type CalendarFC = React.FC<
  {
    disabled?: (date: Date) => boolean
    className?: string
  } & CalendarForm
>

const isEqual = (a: Date, b: Date) =>
  [isSameYear, isSameMonth, isSameDay].every((f) => f(a)(b))

export const NewCalendar: CalendarFC = ({
  //@ts-ignore
  weekend,
  onChange,
  value,
  disabled = isBefore(new Date()), //@ts-ignore

  calendarStartValue, //@ts-ignore

  setCalendarStartValue,
}) => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  const today = new Date()
  const [date, setDatee] = useState(today)
  const [day, setDay] = useState(date.getDate())
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date))
  const [cursor, setCursor] = React.useState(value)
  const [daysForMap, setDaysForMap] = React.useState([])
  const [firstDay, setFirstDay] = React.useState(0)

  React.useEffect(() => {
    const firstday = new Date(year, month, 1)
    setFirstDay(firstday?.getDay())
  }, [month, year])

  console.log(month, year)

  React.useEffect(() => {
    setMonth(cursor.getMonth())
  }, [cursor])

  React.useEffect(() => {
    setCursor(value)
  }, [value])

  function getStartDayOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay()
  }

  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }
  //@ts-ignore
  const days = isLeapYear ? DAYS_LEAP : DAYS

  React.useEffect(() => {
    if (month == 1 && year % 4 == 0) {
      //@ts-ignore
      setDaysForMap(Array(days[month] - 1))
      //@ts-ignore
    } else setDaysForMap(Array(days[month]))
  }, [cursor, value, month])

  const addMonth = addMonths(1)
  const subMonth = subMonths(1)
  const dateFormat = format('yyyy MMMM')

  return (
    <Frame>
      <Header>
        <Button onClick={() => setCursor(subMonth)}>
          <Arrow direct={Direction.Left} />
        </Button>
        <div>{dateFormat(cursor)}</div>
        <Button onClick={() => setCursor(addMonth)}>
          <Arrow direct={Direction.Right} />
        </Button>
      </Header>
      <Body>
        <DayOne isActive={firstDay == 0 || firstDay >= 2}></DayOne>
        <DayTwo isActive={firstDay == 0 || firstDay >= 3}></DayTwo>
        <DayThree isActive={firstDay == 0 || firstDay >= 4}></DayThree>
        <DayFour isActive={firstDay == 0 || firstDay >= 5}></DayFour>
        <DayFive isActive={firstDay == 0 || firstDay >= 6}></DayFive>
        <DaySix isActive={firstDay == 0}></DaySix>
        {
          //@ts-ignore
          daysForMap.fill(null)?.map((_, index) => {
            const showDay = index + 1
            const dater = setDate(showDay)(cursor)
            return (
              <Day
                key={index} //@ts-ignore
                isSelected={isEqual(dater, value) && !disabled(dater)} //@ts-ignore
                onClick={() =>
                  !disabled(dater) &&
                  !weekend.includes(moment(dater).format('MM/DD/YYYY'))
                    ? onChange(dater)
                    : console.log('error')
                } //@ts-ignore
                disabled={
                  //@ts-ignore
                  disabled(dater) || //@ts-ignore
                  weekend.includes(moment(dater).format('MM/DD/YYYY'))
                }
              >
                {showDay}
              </Day>
            )
          })
        }
      </Body>
    </Frame>
  )
}
