import React from 'react'
import { useLocation, useNavigate } from '@reach/router'

import { Container, Title as TitleBase } from '../commons'
import { useCloseRegistration } from './utils'
import { Button } from '../../../../commons'
import styled from 'styled-components'

const Proposal = styled.div`
  margin-bottom: 29px;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #35414c;
`

const Description = styled.div`
  margin-bottom: 20px;

  font-family: 'Open Sans';
  line-height: 130%;
  font-size: 13px;
  text-align: center;
  color: #495560;
`

const Title = styled(TitleBase)`
  margin-bottom: 10px;
`

type StepYetNotRegistrationFC = React.FC<{ nextStep: () => void }>
export const StepYetNotRegistration: StepYetNotRegistrationFC = ({
  nextStep,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const closeRegistration = useCloseRegistration()
  // @ts-ignore
  const phone = location?.state?.phone
  return (
    // @ts-ignore
    <Container
      onRequestClose={closeRegistration}
      onBack={() =>
        navigate?.(location.pathname.replace('/registration', '/login'), {
          // @ts-ignore
          state: { phone },
        })
      }
    >
      <Title>Регистрация</Title>
      <Description>
        Номер +7 ({phone.slice(1, 4)}) {phone.slice(4, 7)}-{phone.slice(7, 9)}-
        {phone.slice(9, 11)} <br />не зарегистрирован
      </Description>
      <Proposal>Создайте аккаунт прямо сейчас</Proposal>
      <Button onClick={nextStep}>Продолжить</Button>
    </Container>
  )
}
