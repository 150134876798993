import ReactDOM from 'react-dom'
import React from 'react'

import { Main } from './Main'
import { createStore } from './modules/store'
import { theEndFirstRender } from './modules/ssd'

const store = createStore({})
export const client = () =>
  ReactDOM.render(
    <Main store={store} />,
    document.getElementById('root'),
    theEndFirstRender,
  )
