import React, { useCallback } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const Scroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Children = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-left: 15px;
  }
`

type Item = {
  value: string
  label?: string
}

type HorizontalScrollableRadioGroupT = React.FC<{
  name?: string
  value: string
  items: Item[]
  onChange: (value: any) => void
  children: (
    id: string,
    label: string,
    value: string,
    selected: boolean,
    onChange: () => void,
  ) => React.ReactNode
  className?: string
}>
export const HorizontalScrollableRadioGroup: HorizontalScrollableRadioGroupT = ({
  name = '',
  value,
  items,
  onChange,
  children,
  className,
}) => {
  const handleChange = useCallback((value: string) => () => onChange(value), [
    onChange,
  ])

  const generateId = useCallback((value) => `${name}-${value}`, [name])

  return (
    <Wrapper className={className}>
      <Scroll>
        {items?.map((item) => (
          <Children key={item.value}>
            {children(
              generateId(item.value),
              item.label || item.value,
              item.value,
              item.value === value,
              handleChange(item.value),
            )}
          </Children>
        ))}
      </Scroll>
    </Wrapper>
  )
}
