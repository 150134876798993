import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import { useAtom } from '@reatom/react'
import formatString from 'format-string-by-pattern'
import Styles from './Styles'
import moment from 'moment'
import {
  ButtonBase,
  InputTextAreaFinal as InputTextAreaBase,
} from '../../../commons'
import { Dispatch, SetStateAction } from 'react'

import { Arrow as ArrowBase, Trashcan } from '../../../images'
import {
  btDesktop,
  btTablet,
  formatCount,
  ltDesktop,
  ltTablet,
  normalizePhone,
} from '../../../../utils'

import { reorder } from '../../../../modules/basket'
import { Product } from '../../../../modules/product'
import InputStartDay from '../../../commons/Inpunts/InputStartDay'
import InputEndDay from '../../../commons/Inpunts/InputEndDay'
import { Calendar as CalendarBase, formatDate } from './Calendar'
import { Count } from './Count'
import { Label } from './Label'
import { Address as AddressBase } from './Address'

type isActive = {
  isActive: boolean
}

const Container = styled.div`
  background: #ffffff;
  ${btTablet} {
    border-radius: 6px;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  grid-template-rows: minmax(178px, max-content) auto;

  ${ltDesktop} {
    grid-template-columns: minmax(100px, 1fr);
    grid-template-rows: initial;
  }
`

const Header = styled.div`
  width: 100%;
  padding: 0 30px;
  ${ltDesktop} {
    padding-left: 20px;
    padding-right: 20px;
  }
  ${ltTablet} {
    padding-left: 15px;
    padding-right: 15px;
  }

  display: flex;
  align-items: center;

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  color: #495560;
  cursor: pointer;
`

const Body = styled.div`
  transition: max-height 500ms;
  overflow: hidden;
`

const IconTrashcan = styled(Trashcan)`
  height: 18px;

  stroke: #8b949b;
  fill: #8b949b;
`

const ButtonRemove = styled(ButtonBase)`
  margin-left: 27px;
  ${ltDesktop} {
    margin-left: 22px;
  }
  &:disabled {
    visibility: hidden;
  }
`

const ButtonArrow = styled(ButtonBase)`
  margin-left: 36px;
  ${ltDesktop} {
    margin-left: 31px;
  }
  ${ltTablet} {
    margin-left: 26px;
  }
`

const Arrow = styled(ArrowBase)`
  height: 8px;

  transition: transform 225ms;
`

const TotalPrice = styled.div`
  margin-left: 20px;
  ${ltDesktop} {
    margin-left: auto;
  }
  ${ltTablet} {
    margin-left: 10px;
  }

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 15px;
  color: #35414c;
`

const DateLabel = styled(Label)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;

  color: ${({ isError }) => (isError ? '#F06060' : '#35414c')};
`

const SubText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  padding-top: 10px;
  color: #495560;
  opacity: 0.7;
`

const AddressLabel = styled(DateLabel)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  color: #35414c;
  padding-bottom: 5px;
`

const InputLabel = styled(Label)<isActive>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: ${({ isActive }) => (isActive ? '#F06060' : '#495560')};
`

const DateShow = styled.div`
  ${ltDesktop} {
    margin-bottom: 10px;
  }

  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: #495560;
  opacity: 0.7;
`

const AddressShow = styled(DateShow)``

const DateWrapper = styled.div<isActive>`
  padding: 25px 30px 30px;

  ${ltDesktop} {
  }
  ${ltTablet} {
  }

  display: flex;
  flex-direction: column;
  border-bottom: 20px solid #ecf1f4;

  @media screen and (min-width: 1440px) {
    border-right: 20px solid #ecf1f4;
  }

  @media screen and (min-width: 379px) and (max-width: 600px) {
    align-items: ${({ isActive }) => (isActive ? 'center' : '')};
  }
`

const InputRangeContainer = styled.div`
  display: flex;
`

const AddressWrapper = styled.div`
  padding: 25px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: none;
  border-right: none;

  ${ltDesktop} {
    border-top: 20px solid #ecf1f4;
    padding: 15px 20px 20px;
  }
  ${ltTablet} {
    padding-left: 15px;
    padding-right: 15px;
    height: 110%;
  }
  ${btDesktop} {
  }
`

const CommentWrapper = styled.div`
  padding: 25px 0 30px 20px;
  ${ltDesktop} {
    padding: 15px 20px 10px;
  }
  ${ltTablet} {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const InputsWrapper = styled(CommentWrapper)`
  padding: 0;
  padding-top: 30px;
  display: flex;
  @media screen and (max-width: 1440px) {
    padding: 0;
    padding-top: 20px;
  }
`

const RecipientWrapper = styled.div``

const Calendar = styled(CalendarBase)`
  margin-top: auto;

  ${ltDesktop} {
    max-width: 240px;
  }
`

const Address = styled(AddressBase)`
  margin-top: 10px;
`

const DeliveryText = styled.span`
  margin-right: 5px;
  ${ltTablet} {
    display: none;
  }
`
type InputTextStyleProps = {
  isError?: boolean
}

const Error = styled.div`
  text-align: left;
  color: #f06060;
  flex-flow: row nowrap;
  justify-content: center;
  font-size: 17px;
  padding-bottom: 10px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const PhoneInput = styled.input`
  width: 300px;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
  @media screen and (min-width: 400px) {
    width: 375px;
  }
`

const CommentLabel = styled.div`
  margin-bottom: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #495560;
`

const InputTextArea = styled(InputTextAreaBase)`
  width: 97%;
  min-height: 52px;
  max-height: 128px;
  height: 128px;
  resize: vertical;
`

type Delivery = React.FC<{
  name: string
  removeSelf: () => void
  disabledRemove: boolean
  index: number
  product?: Product
  initialShow: boolean
  phoneNumberValue: string
  startRangeDay: string
  setStartRangeDay: Dispatch<SetStateAction<string>>
  endRangeDay: string
  setEndRangeDay: Dispatch<SetStateAction<string>>
  activeDeliveryOnce: boolean
  activeDeliveryRange: boolean
  commonCount: number
  setCommonCount: Dispatch<SetStateAction<number>>
  minPerDay: number
  setMinPerDay: Dispatch<SetStateAction<number>>
  maxPerDay: number
  setMaxPerDay: Dispatch<SetStateAction<number>>
  setRangePeriodLength: Dispatch<SetStateAction<number>>
  setIsOrderCountUnacceptable: Dispatch<SetStateAction<boolean>>
  setAddressError: Dispatch<SetStateAction<boolean>>
  isShowError: boolean
  schedule: Array<object>
  timeSchedule: Object
  weekend: Array<object>
  recipientFIOValue : string
  setRecipientFIOValue :  Dispatch<SetStateAction<string>>
}>
export const Delivery: Delivery = ({
  name,
  product,
  // @ts-ignore
  calendarStartValue,
  // @ts-ignore
  setCalendarStartValue,
  phoneNumberValue,
  // @ts-ignore
  setPhoneNumberValue,
  activeDeliveryOnce,
  activeDeliveryRange,
  startRangeDay,
  setStartRangeDay,
  endRangeDay,
  setEndRangeDay,
  commonCount,
  setCommonCount,
  minPerDay,
  setMinPerDay,
  maxPerDay,
  setMaxPerDay,
  setRangePeriodLength,
  setIsOrderCountUnacceptable,
  isShowError,
  schedule,
  timeSchedule,
  weekend,
  recipientFIOValue,
  setRecipientFIOValue
}) => {
  const reorderState = useAtom(reorder)

  return (
    <Container>
      <Body>
        <Wrapper>
          {activeDeliveryOnce && (
            <DateWrapper isActive={activeDeliveryRange}>
              <Field
                name={`${name}.date`}
                validate={(calendarStartValue) =>
                  calendarStartValue
                    ? undefined
                    : 'Когда вам удобно принять доставку?'
                }
                render={({ meta: { error, touched }, input: { value } }) => (
                  <>
                    <DateLabel isError={Boolean(error && touched)}>
                      Дата доставки:
                    </DateLabel>
                    <SubText>
                      {value ? moment(value).format('DD.MM.YYYY') : ''}
                    </SubText>
                    <DateShow>
                      {error || !value ? error : formatDate(new Date(value))}
                    </DateShow>
                  </>
                )}
              />
              <Field
                name={`${name}.date`}
                component={Calendar}
                calendarStartValue={calendarStartValue}
                setCalendarStartValue={setCalendarStartValue}
                activeDeliveryRange={activeDeliveryRange}
                startRangeDay={startRangeDay}
                setStartRangeDay={setStartRangeDay}
                endRangeDay={endRangeDay}
                setEndRangeDay={setEndRangeDay}
                weekend={weekend}
                setRangePeriodLength={setRangePeriodLength}
                setIsOrderCountUnacceptable={setIsOrderCountUnacceptable}
                timeSchedule={timeSchedule}
              />
            </DateWrapper>
          )}
          {activeDeliveryRange && (
            <DateWrapper isActive={activeDeliveryRange}>
              {(!startRangeDay || !endRangeDay) && isShowError && (
                <Error>Выберите дату</Error>
              )}
              <InputRangeContainer>
                <InputStartDay
                  startRangeDay={startRangeDay}
                  setStartRangeDay={setStartRangeDay}
                  checkedRange={activeDeliveryRange}
                  weekend={weekend}
                />
                <InputEndDay
                  endRangeDay={endRangeDay}
                  setEndRangeDay={setEndRangeDay}
                  checkedRange={activeDeliveryRange}
                  weekend={weekend}
                />
              </InputRangeContainer>
              <Field
                name={`${name}.rangeDate`}
                component={Calendar}
                calendarStartValue={calendarStartValue}
                setCalendarStartValue={setCalendarStartValue}
                activeDeliveryRange={activeDeliveryRange}
                startRangeDay={startRangeDay}
                setStartRangeDay={setStartRangeDay}
                endRangeDay={endRangeDay}
                setEndRangeDay={setEndRangeDay}
                weekend={weekend}
                setRangePeriodLength={setRangePeriodLength}
                setIsOrderCountUnacceptable={setIsOrderCountUnacceptable}
                timeSchedule={timeSchedule}
              />
            </DateWrapper>
          )}

          {product && (
            <Count
              name={`${name}.count`}
              product={product}
              activeDeliveryRange={activeDeliveryRange}
              commonCount={commonCount}
              setCommonCount={setCommonCount}
              minPerDay={minPerDay}
              setMinPerDay={setMinPerDay}
              maxPerDay={maxPerDay}
              setMaxPerDay={setMaxPerDay}
            />
          )}

          <AddressWrapper>
            <Field
              rows="2"
              name={`${name}.address`}
              validate={(value) => (value ? undefined : 'Выберите адрес')}
              render={({ input: { value }, meta: { error, touched } }) => (
                <>
                  <AddressLabel isError={Boolean(error && touched)}>
                    Адрес доставки:
                  </AddressLabel>
                  {error && touched && <AddressShow>{error}</AddressShow>}
                </>
              )}
            />
            <Field name={`${name}.address`} component={Address} />
            <InputsWrapper>
              <RecipientWrapper>
                <Field
                  validate={(phone: string) =>
                    (phone && normalizePhone(phone).length < 11) || !phone
                      ? 'Обязательное поле'
                      : ''
                  }
                  name={`${name}.recipientPhone`}
                  parse={formatString('+(7) (000) 000-00-00')}
                  placeholder="+ (7) (___) __-__-__"
                  render={({
                    meta: { error, touched },
                    input: { value: phoneNumberValue, onChange },
                    ...props
                  }) => {
                    //@ts-ignore
                    const changeWithHren = (event) => {
                      const value = event.target.value
                      const formatedString =
                        value?.substring(0, 1) !== '7' && value.length === 1
                          ? '7' + value?.slice(0, 1)
                          : value
                      setPhoneNumberValue(formatedString)

                      onChange(formatedString)
                    }
                    return (
                      <>
                        <InputLabel isActive={Boolean(error && touched)}>
                          Номер телефона получателя
                        </InputLabel>

                        <Styles>
                          <PhoneInput
                            {...props}
                            value={phoneNumberValue}
                            //@ts-ignore
                            onChange={changeWithHren}
                          />
                          {/* <Error isActive={error || touched}>
                          Обязательное поле
                        </Error> */}
                        </Styles>
                      </>
                    )
                  }}
                />
              </RecipientWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <RecipientWrapper>
                <Field
                  name={`${name}.recipientFIO`}
                  placeholder="ФИО получателя"
                  render={({
                    meta: { error, touched },
                    input: { value: recipientFIOValue, onChange },
                    ...props
                  }) => {
//@ts-ignore
                    const changeFIOInput = (event) => {
                      const value = event.target.value
                      setRecipientFIOValue(value)

                      onChange(value)
                    }

                    //@ts-ignore
                    return (
                      <>
                        <InputLabel isActive={Boolean(error && touched)}>
                          ФИО получателя
                        </InputLabel>
                        <Styles>
                          <Styles>
                            <PhoneInput
                              {...props}
                              value={recipientFIOValue}
                              onChange={changeFIOInput}
                              //@ts-ignore
                            />
                            {/* <Error isActive={error || touched}>
                          Обязательное поле
                        </Error> */}
                          </Styles>
                        </Styles>
                      </>
                    )
                  }}
                />
              </RecipientWrapper>
            </InputsWrapper>
          </AddressWrapper>
          <CommentWrapper>
            <CommentLabel>Комментарий:</CommentLabel>
            <Field
              name={`${name}.comment`}
              placeholder={
                // @ts-ignore
                reorderState.comment ? reorderState.comment : 'Детали заказа'
              }
              component={InputTextArea}
              rows="2"
            />
          </CommentWrapper>
        </Wrapper>
      </Body>
    </Container>
  )
}
